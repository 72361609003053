import { Box, Container, Typography } from "@mui/material"
import { useApiPut } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { VehicleAlarmChip } from "./components/VehicleAlarmChip"
import { VehicleAlarmComment } from "./components/VehicleAlarmComment"
import { VehicleAlarmDashboard } from "./components/VehicleAlarmDashboard"
import { VehicleBar } from "./components/VehicleBar/VehicleBar"

export const statusString = (value) => {
  switch (value) {
    case 1:
      return "Pågående"
    case 2:
      return "Avslutad"
    default:
      return "Aktiv"
  }
}

export const status = (value) => {
  switch (value) {
    case 1:
      return <VehicleAlarmChip status={statusString(1)} />
    case 2:
      return <VehicleAlarmChip status={statusString(2)} />
    default:
      return <VehicleAlarmChip status={statusString()} />
  }
}

export const alarmType = (value) => {
  switch (value) {
    case 1:
      return "Alkolås"
    default:
      return "Förarkort"
  }
}
const VehicleAlarm = () => {
  const globalClasses = globalUseStyles()
  const loadingContext = useContext(GlobalLoaderContext)

  const [showSidebar, setShowSidebar] = useState(false)
  const [formData, setFormData] = useState()
  const [alarmData, setAlarmData] = useState({
    total: 0,
    open: 0,
    ongoing: 0,
    done: 0
  })

  const [vehicleAlarmsResult, vehiclesAlarmsFuncs] = useApi([])
  useEffect(() => {
    vehiclesAlarmsFuncs.get(apiEndpoints.vehiclealarm.getvehiclealarm)
    return () => {
      vehiclesAlarmsFuncs.leave()
    }
  }, [vehiclesAlarmsFuncs])

  useEffect(() => {
    loadingContext.setLoading(() => vehicleAlarmsResult.status === 1)
  })

  useEffect(() => {
    calculateData(vehicleAlarmsResult)
  }, [vehicleAlarmsResult])

  const getFormData = (row) => {
    setFormData(row)
    setShowSidebar(true)
  }

  const calculateData = (vehicleAlarmsResult) => {
    if (vehicleAlarmsResult.data != null) {
      setAlarmData({
        total: vehicleAlarmsResult.data.length,
        open: vehicleAlarmsResult.data.filter((v) => v.status === 0).length,
        ongoing: vehicleAlarmsResult.data.filter((v) => v.status === 1).length,
        done: vehicleAlarmsResult.data.filter((v) => v.status === 2).length
      })
    }
  }

  const [updateVehicleResult, updateVehicle] = useApiPut()
  const handleSubmit = (values) => {
    updateVehicle(apiEndpoints.vehiclealarm.updatevehiclealarmbyid, values)
    setShowSidebar(false)

    const index = vehicleAlarmsResult.data.findIndex((item) => item.id === values.id)
    vehicleAlarmsResult.data[index] = values

    calculateData(vehicleAlarmsResult)
  }

  const columns = [
    {
      accessor: "vehicle.internnumber",
      Header: "Internnr"
    },
    {
      accessor: (row) => {
        return alarmType(row.alarmType)
      },
      Header: "Larmtyp",
      mainFilter: true,
      Filter: SelectColumnFilter,
      FilterHeader: "Larmtyp",
      align: "center",
      FilterOptions: [
        {
          name: "Alkolås",
          value: "Alkolås"
        },
        {
          name: "Förarkort",
          value: "Förarkort"
        }
      ]
    },
    {
      accessor: "vehicle.regNumber",
      Header: "Registreringsnummer"
    },
    {
      accessor: (row) => {
        return moment(row.alarmDate).isValid() && moment.utc(row.alarmDate).local().format("YYYY-MM-DD HH:mm")
      },
      Header: "Larmtid"
    },

    {
      accessor: (row) => {
        return statusString(row.status)
      },
      Cell: ({ cell }) => {
        return status(cell.row.original.status)
      },
      Header: "Status",
      mainFilter: true,
      Filter: SelectColumnFilter,
      FilterHeader: "Status",
      align: "center",
      FilterOptions: [
        {
          name: "Aktiv",
          value: "Aktiv"
        },
        {
          name: "Pågående",
          value: "Pågående"
        },
        {
          name: "Avslutad",
          value: "Avslutad"
        }
      ]
    },
    {
      accessor: "user.name",
      Header: "Ansvarig"
    },
    {
      align: "right",
      Cell: ({ cell }) => {
        return <VehicleAlarmComment action={cell.row.original.measure} id={cell.row.original.id} />
      },
      accessor: (row) => {
        return row.measure
      },
      Header: "Åtgärd"
    }
  ]

  return (
    <div>
      <Container>
        <VehicleBar
          showSidebar={showSidebar}
          formData={formData}
          setShowSidebar={setShowSidebar}
          onSubmit={handleSubmit}
        />
        <Box mb={4} sm={12} xs={12}>
          <Typography variant="h1">Fordonslarm</Typography>
        </Box>
        <Box mb={4} sm={12} xs={12}>
          <VehicleAlarmDashboard
            vehicleAlarmsResult={vehicleAlarmsResult}
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
            alarmData={alarmData}
          />
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rowProps={(row) => ({
            onClick: () => getFormData(row.original)
          })}
          rows={vehicleAlarmsResult.status === 2 && vehicleAlarmsResult.data}
          status={vehicleAlarmsResult.status}
        />
      </Box>
    </div>
  )
}
export default VehicleAlarm
