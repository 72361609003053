import { useApi } from "auth/useApi3"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import React, { useContext, useEffect } from "react"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { NavLink } from "react-router-dom"
import { allRoutes } from "generated/routes"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import { allRoles } from "generated/roles"
import PaperBox from "components/PaperBox"
import TemplateCard from "./TemplateCard"
import { ListReportTemplatesProps, ReportTemplate, ReportTypeEnum } from "../interfaces/reports.interfaces"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { StyledIconBox } from "./reports.styled"

const skeletonRows = [1, 2, 3, 4, 5, 6]

const ListReportTemplates = ({ reportType }: ListReportTemplatesProps) => {
  const appContext = useContext(AppContext)
  const [templatesResult, templateApi] = useApi() as [IApiResult, IApiFunc]
  const loadingContext = useContext(GlobalLoaderContext)
  const isAdmin = hasRole(appContext.appUser, allRoles.ReportsAdmin)

  useEffect(() => {
    const url = `${apiEndpoints.reporting.getreporttemplates}?reportType=${reportType}`
    templateApi.get(url)
  }, [])

  useEffect(() => {
    loadingContext.setLoading(() => templatesResult.status === 1)
  }, [])

  const reportTemplateAdd = reportType === ReportTypeEnum.User
    ? allRoutes.ReportsUserreportsReporttemplatesAdd()
    : allRoutes.ReportsVehiclereportsReporttemplatesAdd()

  return (
    <Container>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" mb={6}>
        <Typography variant="h1">Rapportmallar</Typography>
        {isAdmin && (
          <Button
            component={NavLink}
            to={reportTemplateAdd}
            color="primary"
            variant="contained"
          >
            Skapa ny rapport
            <StyledIconBox>
              <FontAwesomeIcon icon={faPlus as IconProp} color="white" size="lg" />
            </StyledIconBox>
          </Button>
        )}
      </Grid>
      <Grid container direction="column">
        {templatesResult.status === 2 ?
          templatesResult?.data?.length > 0 ? templatesResult?.data?.map((result: ReportTemplate, index: number) => (
            <TemplateCard
              key={result.id}
              data={result}
              reportType={reportType}
              index={index}
              totalCards={templatesResult.data.length}
            />
          )) : (
            <PaperBox>
              <Box>Det finns inga rapportmallar att visa</Box>
            </PaperBox>
          )
          : skeletonRows.map((index) => (
            <Grid key={index} item xs={12}>
              <SkeletonCard padding="13px 15px" />
            </Grid>
          )
        )}
      </Grid>
    </Container>
  )
}

export default ListReportTemplates