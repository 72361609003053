import React, { useContext, useEffect } from "react"
import { Box, Card, CardHeader, Divider, FormControl, Grid, MenuItem, Select } from "@mui/material"
import { styled } from "@mui/material/styles"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { ReportTypeEnum } from "../../interfaces/reports.interfaces"
import { ReportTemplateContext } from "../ReportTemplateContextProvider"

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  "& .MuiTypography-h5": {
    fontSize: "1rem"
  },
  "& .MuiTypography-bodyMedium": {
    fontSize: "0.75rem"
  }
}))
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120
}))

interface PeopleAccessListProps {
  reportType: ReportTypeEnum
}

export const PeopleAccessList = ({ reportType }: PeopleAccessListProps) => {
  const { contextTemplateAuths, setContextTemplateAuths } = useContext(ReportTemplateContext)
  const [readUserReportResult, readUserReportApi] = useApi() as [IApiResult, IApiFunc]
  const readUserReportFeature = readUserReportResult.data

  useEffect(() => {
    readUserReportApi.get(apiEndpoints.reporting.getusersbyrole.replace("{feature}", allRoles.ReadUserReports))
  }, [])

  useEffect(() => {
    if (readUserReportResult.status !== 2) return
    const initialAccessLevels = readUserReportFeature.map((user: any) => ({
      userId: user.id,
      name: user.name,
      authorizationLevel: contextTemplateAuths.find((auth: any) => auth.userId === user.id)?.authorizationLevel || 0
    }))
    setContextTemplateAuths(initialAccessLevels)
  }, [readUserReportResult])

  const handleSelectChange = (userId: number, value: number) => {
    const newAccessList = contextTemplateAuths.map((auth: any) => {
      if (auth.userId !== userId) return auth
      return { ...auth, authorizationLevel: value }
    })
    setContextTemplateAuths(newAccessList)
  }

  const dataExists = readUserReportFeature !== null && readUserReportFeature !== undefined && readUserReportFeature.length > 0

  return (
    <Box component={Card}>
      <StyledCardHeader title="Tilldela behörigheter" />
      {dataExists && readUserReportFeature.map((user: any, index: number) => (
        <Grid container alignItems="center">
          <Grid item md={3} ml={8}>
            {user.name}
          </Grid>
          <Box>
            <StyledFormControl size="small" variant="standard">
              <Select
                labelId="sortby-label"
                id="userReportTemplateAuths"
                value={contextTemplateAuths.find((auth: any) => auth.userId === user.id)?.authorizationLevel || 0}
                onChange={(event) => {
                  let value = event.target.value
                  if (typeof value === "string") value = parseInt(value)
                  handleSelectChange(user.id, value)
                }}
              >
                <MenuItem value={0}>Ingen</MenuItem>
                <MenuItem value={1}>Läsa</MenuItem>
                <MenuItem value={2}>Redigera</MenuItem>
              </Select>
            </StyledFormControl>
          </Box>
          {index < readUserReportFeature.length - 1 && <Divider />}
        </Grid>
      ))}
    </Box>
  )
}