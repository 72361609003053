import React from "react"
import ListReportTemplates from "pages/reports/components/ListReportTemplates"
import { ReportTypeEnum } from "pages/reports/interfaces/reports.interfaces"

const index = () => {
  return (
    <ListReportTemplates reportType={ReportTypeEnum.User} />
  )
}

export default index