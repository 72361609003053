import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  TextField,
  Typography
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { DateTimePicker } from "@mui/x-date-pickers"
import { useApiFile } from "auth/useApi2"
import Button from "components/Button"
import CompanyDepartmentSelection from "components/CompanyDepartmentSelection"
import TextEditor from "components/Fields/TextEditor"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import MultipleFilePickerPreviewList from "components/MultipleFilePickerPreviewList"
import PaperBox from "components/PaperBox"
import { TinyMCE, tinyMceImportDate } from "components/TinyMCE/TinyMCE"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import { removeQueryFromImgSrc } from "pages/documents/helpers/imageSrcSasKey"
import React, { useContext, useEffect, useRef, useState } from "react"
import * as Yup from "yup"

const styles = makeStyles((theme) => ({
  accordion: {
    border: "none",
    "& .MuiButtonBase-root": { paddingLeft: 0 },
    "& .MuiAccordionSummary-content": { margin: 0 }
  },
  rte: {
    minHeight: "300px",
    borderRadius: "4px",
    border: "1px solid #d6d6d6",
    "& img": {
      maxWidth: "100%",
      height: "auto"
    },
    "& .MUIRichTextEditor-container-69": {
      margin: 0
    },
    "& #mui-rte-container > div:nth-child(2)": {
      padding: "0px 14px",
      minHeight: "190px"
    },

    "& .MuiSvgIcon-root": {
      fontSize: "1.25rem"
    }
  }
}))

const articleValidationSchema = Yup.object().shape({
  subject: Yup.string().required("Rubrik är obligatoriskt").min(2, "Namn behöver vara minst 2 tecken långt"),
  publishedDate: Yup.date().required("Publiceringsdatum är obligatoriskt").nullable(),
  priorityEndDate: Yup.date().nullable()
})

const ArticleForm = ({ customer, editItem, onSubmit, result, feedback }) => {
  const initialValues = editItem
    ? {
      ...editItem
    }
    : {
      subject: "",
      subTitle: "",
      body: "",
      priorityEndDate: null,
      publishedDate: new Date(),
      companyIds: [],
      departmentIds: [],
      attachments: []
    }
  const classes = styles()
  const editMode = editItem ? true : false
  const handleSubmit = async (values) => {
    if (values.body) {
      values.body = removeQueryFromImgSrc(values.body)
    }
    onSubmit(values)
  }
  const [expanded, setExpanded] = React.useState(false)
  const toggleAccordion = () => {
    setExpanded((p) => !p)
  }
  const loadingContext = useContext(GlobalLoaderContext)
  const [fileUploadResult, uploadFile] = useApiFile()
  const [files, setFiles] = useState([])
  const [filesPreview, setFilesPreview] = useState(initialValues.attachments)

  useEffect(() => {
    if (files) {
      files.map((file) => {
        return uploadFile(apiEndpoints.newsadmin.uploadattachment, file)
      })
    }
  }, [files, uploadFile])

  useEffect(() => {
    if (fileUploadResult.data && fileUploadResult.data.name) {
      setFilesPreview((p) => {
        return [...p, fileUploadResult.data]
      })
    }
  }, [fileUploadResult])

  const onLogoChanged = (value) => {
    setFilesPreview(value)
  }

  useEffect(() => {
    loadingContext.setLoading(() => fileUploadResult.status === 1)
  })

  const [textEditorValue, setTextEditorValue] = useState()
  const formRef = useRef()
  useEffect(() => {
    if (formRef.current && textEditorValue) {
      formRef.current.setFieldValue("body", textEditorValue)
    }
  }, [textEditorValue])

  return (
    <Formik
      innerRef={formRef}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={articleValidationSchema}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <PaperBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="subject"
                  label="Rubrik"
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.subject && errors.subject ? true : false}
                  helperText={touched.subject && errors.subject ? errors.subject : null}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline={true}
                  rows={5}
                  autoComplete="off"
                  fullWidth
                  id="subTitle"
                  label="Ingress"
                  value={values.subTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.subTitle && errors.subTitle ? true : false}
                  helperText={touched.subTitle && errors.subTitle ? errors.subTitle : null}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                {new Date(initialValues.publishedDate) <= new Date(tinyMceImportDate) ? (
                  <Box className={classes.rte}>
                    <TextEditor
                      value={initialValues.body}
                      setData={(e) => {
                        setTextEditorValue(e)
                      }}
                      uploadApi={apiEndpoints.newsadmin.uploadattachment}
                    />
                  </Box>
                ) : (
                  <TinyMCE
                    setFormikData={(e) => {
                      setTextEditorValue(e)
                    }}
                    formikValues={{ body: initialValues.body }}
                    uploadMediaUrl={apiEndpoints.newsadmin.uploadattachment}
                  />
                )}
              </Grid>
            </Grid>
          </PaperBox>
          <Box marginTop={3}>
            <PaperBox>
              <Typography variant="h5">Bifoga filer</Typography>
              <Typography variant="bodySmall" color="textSecondary">
                Filerna kan laddas ner från nyhetsinlägget.
              </Typography>
              <Box marginTop={2}>
                <MultipleFilePickerPreviewList
                  setFieldValue={setFieldValue}
                  fieldName="attachments"
                  defaultPreviewFiles={filesPreview}
                  setFiles={setFiles}
                  files={files}
                  fileUploadResult={fileUploadResult}
                  onLogoChanged={onLogoChanged}
                  labelInBox={"Bifoga filer från din enhet"}
                />
              </Box>
            </PaperBox>
          </Box>
          <Box marginTop={3}>
            <PaperBox>
              <Grid container direction="row" justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Typography variant="h5">Publiceringsdatum</Typography>
                  <Typography variant="bodySmall" color="textSecondary">
                    Inlägget kommer publiceras från det valda datumet.
                  </Typography>
                </Grid>
                <Grid item>
                  <DateTimePicker
                    value={values.publishedDate ? moment(values.publishedDate) : null}
                    inputFormat="YYYY-MM-DD HH:mm"
                    onChange={(newValue) => {
                      setFieldValue("publishedDate", newValue)
                    }}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </Grid>
              </Grid>
              <Box marginY={3}>
                <Divider light />
              </Box>

              <Grid container direction="row" justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Typography variant="h5">Prioritera nyhet</Typography>
                  <Typography variant="bodySmall" color="textSecondary">
                    Inlägget visas högst upp i listan tills det valda prioriteringsdatumet förfaller.
                  </Typography>
                </Grid>
                <Grid item>
                  <DateTimePicker
                    value={values.priorityEndDate ? moment(values.priorityEndDate) : null}
                    inputFormat="YYYY-MM-DD HH:mm"
                    onChange={(newValue) => {
                      setFieldValue("priorityEndDate", newValue)
                    }}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </Grid>
              </Grid>
            </PaperBox>
          </Box>
          <Box marginTop={3}>
            <PaperBox>
              <Accordion expanded={expanded} onChange={toggleAccordion} className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                  aria-controls="settings">
                  <Typography variant="h5">Avancerade inställningar</Typography>
                  <Typography variant="bodySmall" color="textSecondary">
                    Filtrera inlägget på bolag och avdelningsnivå.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <CompanyDepartmentSelection
                      companies={customer.companies}
                      initCompanies={initialValues.companyIds}
                      initDepartments={initialValues.departmentIds}
                      onChange={(newState) => {
                        const companyIds = []
                        const departmentIds = []

                        const sortedCompanies = newState.sort((a, b) => a?.name.localeCompare(b?.name))
                        sortedCompanies.forEach((c) => {
                          if (c.selected) companyIds.push(c.id)
                          const sortedDepartments = c.departments.sort((a, b) => a?.name.localeCompare(b?.name))
                          sortedDepartments.forEach((d) => {
                            if (d.selected) departmentIds.push(d.id)
                          })
                        })

                        setFieldValue("companyIds", companyIds)
                        setFieldValue("departmentIds", departmentIds)
                      }}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </PaperBox>
          </Box>

          <Box display="flex" justifyContent="flex-end" marginY={3}>
            <Button color="primary" type="submit" result={result} feedback={feedback}>
              {editMode ? "Spara" : "Publicera nyhet"}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )
}
export default ArticleForm
