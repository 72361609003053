import React from 'react'
import { StyledReportBigButton, StyledReportIconEnd, StyledReportIconStart } from '../../report.styled'
import { IReportBigButtonProps } from './reportBigButton.interface'
import { Box, Grid, Typography } from '@mui/material'

const ReportBigButton: React.FC<IReportBigButtonProps> = (props) => {
  const { label, iconStart, iconEnd, handleClick, color } = props
  return (
    <StyledReportBigButton
      {...props}
      color={color ? color : "primary"}
      onClick={handleClick}
      endIcon={iconEnd && <StyledReportIconEnd icon={iconEnd} />}>
      <Box gap={3} display="flex" alignItems="center">
        <Box display="flex" justifyContent="center" sx={{ width: "40px" }}>
          {iconStart && <StyledReportIconStart icon={iconStart} />}
        </Box>
        <Typography fontWeight="500" variant="bodySmall">
          {label}
        </Typography>
      </Box>
    </StyledReportBigButton>
  )
}

export default ReportBigButton