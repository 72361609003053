import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MobileStepper,
  Typography
} from "@mui/material"
import ClpButton from "components/Button"
import React, { useContext, useEffect, useState } from "react"

import { faCheck, faCircle, faExclamation } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useApi } from "auth/useApi3"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Area } from "./Area"
import { ExamFormContext } from "./ExamFormStateProvider"
import { StepperEl } from "./StepperEl"
import { useStyles } from "./useStyles"

export const ExamFormMain = (props) => {
  const { t } = useTranslation()
  const { data, examinationId, examinationName, examQuestionCount } = props
  const routerHistory = useHistory()
  const params = useParams()

  const answerContext = useContext(ExamFormContext)
  const [submitResult, submitFuncs] = useApi()
  const [exam, setExam] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)

  const handleSubmit = () => {
    submitFuncs.post(apiEndpoints.learning.submitanswers, {
      examinationId: examinationId,
      answers: answerContext.values.current
    })
  }

  useEffect(() => {
    answerContext.getRandomizeQuestions(data.examinationDefinition)
  }, [answerContext, data])

  useEffect(() => {
    if (answerContext.randomArea !== null) {
      setExam(answerContext.randomArea)
    }
  }, [answerContext.randomArea])

  React.useEffect(() => {
    if (submitResult.status === 2) {
      routerHistory.push(allRoutes.LearningUserExamsFinished(params.id, submitResult.data.id))
    }
  }, [submitResult, examinationId, routerHistory, params])

  const classes = useStyles()

  const [activeStep, setActiveStep] = useState(0)
  const [isValid, setFormValid] = useState(false)
  const handleNext = () => {
    window.scrollTo(0, 0)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    setFormValid(
      answerContext.values.current?.filter((x) => x.answer?.length > 0).length === examQuestionCount
    )
  }, [answerContext.values.current])

  const [currentTranslatedDetails, setCurrentTranslatedDetails] = useState({
    courseDetails: null,
    examinationName: "",
    areaTitle: "",
    vimeoUrl: ""
  })

  useEffect(() => {
    const courseDetails = t(`learning.details.course`, { returnObjects: true }).find(
      (course) => course.examinationType === data.examinationType
    )
    setCurrentTranslatedDetails((prevDetails) => ({
      ...prevDetails,
      courseDetails: courseDetails
    }))

    if (exam !== null && exam?.areas?.length > 0) {
      if (courseDetails) {
        setCurrentTranslatedDetails((prevDetails) => ({
          ...prevDetails,
          examinationName: courseDetails?.examinationName,
          areaTitle: courseDetails?.examinationDefinition?.areas[activeStep].title,
          vimeoUrl: courseDetails?.examinationDefinition?.areas[activeStep].html
        }))
      }
    }
  }, [exam, activeStep, data.examinationType, t])

  return (
    <>
      <Container className={classes.root}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" color="primary">
              {t("learning.titles.course")}
            </Typography>
            <Typography variant="h4" marginBottom={3} color="textSecondary">
              {currentTranslatedDetails ? currentTranslatedDetails.examinationName : examinationName}
            </Typography>
            <form onSubmit={handleSubmit}>
              {exam !== null && exam?.areas?.length > 0 && (
                <Area
                  vimeoUrl={currentTranslatedDetails.vimeoUrl}
                  translatedTitle={currentTranslatedDetails.areaTitle}
                  activeStep={activeStep}
                  area={exam.areas[activeStep]}
                  examinationType={data.examinationType}
                />
              )}
            </form>
          </Grid>
          <Grid item md={4}>
            {exam?.areas?.length > 0 && (
              <StepperEl
                currentTranslatedDetails={currentTranslatedDetails.courseDetails}
                examQuestionCount={examQuestionCount}
                areas={exam?.areas}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                handleSubmitForm={handleSubmit}></StepperEl>
            )}
          </Grid>
        </Grid>
      </Container>

      <MobileStepper
        variant="progress"
        steps={exam?.areas?.length}
        position="static"
        activeStep={activeStep}
        className={classes.mobileStepper}
        nextButton={
          activeStep === exam?.areas?.length - 1 ? (
            <Button color="primary" variant="contained" onClick={() => setOpenDialog(true)}>
              {t("global.buttons.next")}
            </Button>
          ) : (
            <Button color="primary" variant="contained" onClick={handleNext}>
              {t("global.buttons.next")}
            </Button>
          )
        }
        backButton={
          <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0}>
            {t("global.buttons.previous")}
          </Button>
        }
      />

      {activeStep === exam?.areas?.length - 1 && isValid ? (
        <>
          {openDialog && (
            <Dialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="xs">
              <Grid container direction="column" alignItems="center">
                <Box width="1" textAlign="center">
                  <Grid item my={2}>
                    <DialogTitle id="alert-dialog-title">
                      <Box className="fa-layers fa-fw text-center fa-4x" color="success.light">
                        <FontAwesomeIcon icon={faCircle} color="success.light" />
                        <FontAwesomeIcon icon={faCheck} color={theme.palette.success["main"]} size="xs" />
                      </Box>
                    </DialogTitle>
                  </Grid>
                </Box>
                <DialogContent mb={2}>
                  <Typography align="center" variant="h4" paragraph>
                    {t("learning.exams.titles.send_results")}
                  </Typography>
                  <Typography align="center" paragraph>
                    {t("learning.exams.titles.send_confirm")}
                  </Typography>
                </DialogContent>
                <DialogActions className={classes.fullWidthPadding}>
                  <Box display="flex" justifyContent="space-between" className={classes.fullWidth} mb={3}>
                    <Button
                      onClick={() => setOpenDialog(false)}
                      color="primary"
                      variant="outlined"
                      sx={{ marginRight: 2 }}>
                      {t("learning.exams.titles.close", "Stäng")}
                    </Button>
                    <ClpButton
                      onClick={handleSubmit}
                      color="success"
                      variant="contained"
                      result={submitResult}>
                      {t("learning.exams.buttons.submit")}
                    </ClpButton>
                  </Box>
                </DialogActions>
              </Grid>
            </Dialog>
          )}
        </>
      ) : (
        activeStep === exam?.areas?.length - 1 && (
          <>
            {openDialog && (
              <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs">
                <Grid container direction="column" alignItems="center">
                  <Box width="1" textAlign="center">
                    <Grid item my={2}>
                      <DialogTitle id="alert-dialog-title">
                        <Box className="fa-layers fa-fw text-center fa-4x" color="warning.light">
                          <FontAwesomeIcon icon={faCircle} color="warning.light" />
                          <FontAwesomeIcon
                            icon={faExclamation}
                            color={theme.palette.warning["main"]}
                            size="xs"
                          />
                        </Box>
                      </DialogTitle>
                    </Grid>
                  </Box>
                  <DialogContent mb={2}>
                    <Typography align="center" variant="h4" paragraph>
                      {t("learning.exams.titles.unanswered_questions")}
                    </Typography>
                    <Typography align="center" paragraph>
                      {t("learning.exams.titles.recheck")}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Box mb={3}>
                      <Button onClick={() => setOpenDialog(false)} color="primary" variant="contained">
                        {t("learning.exams.titles.close")}
                      </Button>
                    </Box>
                  </DialogActions>
                </Grid>
              </Dialog>
            )}
          </>
        )
      )}
    </>
  )
}
