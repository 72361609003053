import {
  faArrowUpRightFromSquare,
  faCalendarCheck,
  faCalendarClock,
  faCalendarDays,
  faCalendarRange,
  faIdCardClip,
  faLongArrowRight,
  faMapLocationDot,
  faPaperPlane
} from "@fortawesome/pro-solid-svg-icons"
import {
  Box,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery
} from "@mui/material"
import { useApiGet, useApiPost } from "auth/useApi2"
import BplTokenLink from "components/BplTokenLink"
import Button from "components/Button"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import ActionCardDetailText from "./components/ActionCardDetailText"
import ActionCardDetails from "./components/ActionCardDetails"
import { ActionCardLoading } from "./components/ActionCardLoading"
import { AssignmentListItem } from "./components/AssignmentListItem"
import { AssignmentListLoading } from "./components/AssignmentListLoading"
import { useTheme } from "@emotion/react"
import { useTranslation } from "react-i18next"
import { allRoutes } from "generated/routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AssignmentButton = ({ currentAssignmentResult, toggleDialogAssignment }) => {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down("lg"))
  const { t } = useTranslation()

  if (currentAssignmentResult?.data !== null || currentAssignmentResult?.data !== "") {
    if (currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad) {
      return (
        <Button fullWidth={isPhone} onClick={toggleDialogAssignment} color="primary" variant="contained">
          {t("planning.confirm", "Bekräfta")}
        </Button>
      )
    } else if (currentAssignmentResult.data?.canBekrafta && currentAssignmentResult.data?.bekraftad) {
      return (
        <Button fullWidth={isPhone} onClick={toggleDialogAssignment} color="error" variant="contained">
          {t("planning.undo_confirmed", "Ångra bekräftad")}
        </Button>
      )
    } else {
      return (
        <>
          {currentAssignmentResult.data.bekraftad ? (
            <Button fullWidth={isPhone} color="primary" disabled variant="contained">
              {t("planning.confirmed", "Bekräftad")}
            </Button>
          ) : (
            <Typography variant="bodySmall" fontWeight="500">
              {t("planning.no_active_assignment", "Du har inget aktivt uppdrag just nu")}
            </Typography>
          )}
        </>
      )
    }
  }
  return null
}

const Overview = () => {
  const globalClasses = globalUseStyles()
  const loadingContext = useContext(GlobalLoaderContext)
  const [currentAssignmentResult, getCurrentAssignment] = useApiGet()
  const [timereportResult, getTimereport] = useApiGet()
  const [assignmentResult, getAssignment] = useApiGet()
  const [updateAssignmentResult, acceptAssignmentFunc] = useApiPost()
  const [updateTimeReportResult, acceptTimeReportFunc] = useApiPost()
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down("lg"))
  const { t } = useTranslation()

  const [currentDate, setDate] = useState(moment().format("YYYY-MM-DD"))

  const [updatedAssignment, setUpdatedAssignment] = useState()

  const [updatedTimeReport, setUpdatedTimeReport] = useState()

  useEffect(() => {
    getAssignment(apiEndpoints.planning.getassignment.replace("{date}", currentDate))
  }, [getAssignment, currentDate])

  useEffect(() => {
    getCurrentAssignment(apiEndpoints.planning.getcurrentassignment)
  }, [getCurrentAssignment])

  useEffect(() => {
    getTimereport(apiEndpoints.planning.getcurrenttimereport)
  }, [getTimereport])

  useEffect(() => {
    loadingContext.setLoading(() => assignmentResult.status === 1)
    loadingContext.setLoading(() => currentAssignmentResult.status === 1)
  })

  useEffect(() => {
    if (updateTimeReportResult.status === 2) {
      getTimereport(apiEndpoints.planning.getcurrenttimereport)
      toggleDialogTimeReport()
    }
  }, [updateTimeReportResult.status])

  useEffect(() => {
    if (updateAssignmentResult.status === 2) {
      getCurrentAssignment(apiEndpoints.planning.getcurrentassignment)
      toggleDialogAssignment()
    }
  }, [updateAssignmentResult.status])

  useEffect(() => {
    setUpdatedAssignment(moment().format("YYYY-MM-DD HH:mm"))
  }, [currentAssignmentResult])

  useEffect(() => {
    setUpdatedTimeReport(moment().format("YYYY-MM-DD HH:mm"))
  }, [timereportResult])

  const acceptAssignment = () => {
    const values = {
      assignmentId: currentAssignmentResult?.data?.passStartSchemapostId,
      undo: currentAssignmentResult?.data?.bekraftad
    }
    acceptAssignmentFunc(apiEndpoints.planning.acceptassignment, values)
  }

  const [dialogAssignmentOpen, setDialogAssignmentOpen] = useState(false)

  const toggleDialogAssignment = () => {
    setDialogAssignmentOpen(!dialogAssignmentOpen)
  }

  const acceptTimeReport = () => {
    const values = {
      timeReportId: timereportResult?.data?.id,
      undo: timereportResult?.data?.isSubmitted
    }
    acceptTimeReportFunc(apiEndpoints.planning.accepttidrapport, values)
  }

  const [dialogTimeReportOpen, setDialogTimeReportOpen] = useState(false)

  const toggleDialogTimeReport = () => {
    setDialogTimeReportOpen(!dialogTimeReportOpen)
  }

  const currentAssignmentLinks = [
    {
      iconStart: faIdCardClip,
      iconEnd: faArrowUpRightFromSquare,
      bigButton: true,
      label: t("planning.driver_board", "Förartavla"),
      to: "client/forartavla"
    },
    {
      iconStart: faMapLocationDot,
      iconEnd: faArrowUpRightFromSquare,
      bigButton: true,
      label: t("planning.busmaps", "Busskartor"),
      to: "client/karta"
    },
    {
      iconStart: faCalendarDays,
      iconEnd: faLongArrowRight,
      bigButton: true,
      label: t("planning.schedule", "Schema"),
      link: allRoutes.Planning()
    }
  ]

  const currentAssignmentData = [
    {
      title: t("planning.date", "Datum"),
      data: moment(currentAssignmentResult?.data?.passStarttid).format("YYYY-MM-DD")
    },
    {
      title: t("planning.start_time", "Tid"),
      data: currentAssignmentResult?.data?.starttidsträng ? currentAssignmentResult.data.starttidsträng : null
    },
    {
      title: t("planning.service", "Tjänst"),
      data: currentAssignmentResult?.data?.beskrivning
    },
    {
      title: t("planning.vehicle", "Fordon"),
      data: currentAssignmentResult?.data?.fordonStr
    }
  ]

  return (
    <Box mb={4}>
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Översikt</Typography>
          </Grid>
        </Box>

        <Grid container direction="row" justifyContent="space-between" spacing={2}>
          <Grid item md={8} xs={12}>
            <PaperBox sx={{ marginTop: "0" }}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <Typography variant="h5" className={globalClasses.margin0}>
                    Mina uppdrag
                  </Typography>
                </Grid>
              </Grid>
              <Box marginTop={2}>
                <Divider light />
              </Box>

              {assignmentResult.status === 2 ? (
                assignmentResult.data.map((assignment, i) => {
                  const len = assignmentResult.data.length
                  const newDay = !moment(moment.utc(assignment.passStarttid).format("YYYY-MM-DD")).isSame(
                    moment.utc(assignmentResult.data[(i + len - 1) % len]?.passStarttid).format("YYYY-MM-DD")
                  )
                  return (
                    <>
                      {newDay && (
                        <Box marginTop={1}>
                          <Chip
                            color="primary"
                            className={globalClasses.backgroundPrimary}
                            label={moment.utc(assignment.passStarttid).format("dddd DD/MM")}
                          />
                        </Box>
                      )}
                      <AssignmentListItem assignment={assignment} key={i} />
                      {i !== assignmentResult.data.length - 1 && <Divider light />}
                    </>
                  )
                })
              ) : (
                <AssignmentListLoading />
              )}
              {assignmentResult.status === 2 && assignmentResult.data?.length < 0 && (
                <Typography>Du har inget aktuellt uppdrag just nu</Typography>
              )}
            </PaperBox>
          </Grid>
          <Grid item md={4}>
            <Grid container spacing={2}>
              {currentAssignmentResult.status === 2 ? (
                <Grid item xs={12}>
                  <ActionCardDetails
                    columnDirection="column"
                    bgColor={currentAssignmentResult.data.bekraftad ? theme.palette.success.light : "#F1FCFF"}
                    title={
                      currentAssignmentResult.data?.bekraftad
                        ? t("planning.confirmed_assignment", "Bekräftat uppdrag")
                        : t("planning.current_assignment", "Aktuellt uppdrag")
                    }
                    result={updateAssignmentResult}
                    details={
                      <Grid container direction="column" spacing={1}>
                        {currentAssignmentData?.map((item, index) => (
                          <Grid item xs={12} md={12} ml={1}>
                            <ActionCardDetailText key={index} title={item.title} data={item.data} />
                          </Grid>
                        ))}

                        <Grid item xs={12} mt={3}>
                          <AssignmentButton
                            currentAssignmentResult={currentAssignmentResult}
                            toggleDialogAssignment={toggleDialogAssignment}
                          />
                        </Grid>
                      </Grid>
                    }
                    actions={
                      <Grid container direction="column" spacing={1}>
                        {currentAssignmentLinks.map((link, index) => (
                          <Grid item md={12} xs={12} key={index}>
                            <BplTokenLink {...link} />
                          </Grid>
                        ))}
                      </Grid>
                    }></ActionCardDetails>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <ActionCardLoading sx={{ marginTop: "0px" }} />
                </Grid>
              )}
              {timereportResult.status === 2 ? (
                <Grid item xs={12}>
                  <ActionCardDetails
                    pb={
                      currentAssignmentResult?.data?.bekraftad !== undefined &&
                      currentAssignmentResult?.data?.bekraftad !== null
                        ? 10
                        : 7
                    }
                    columnDirection="column"
                    title={t("planning.timereport", "Tidrapport")}
                    details={
                      <Grid container direction="column" pl={1} spacing={1}>
                        <Grid item>
                          <ActionCardDetailText
                            title={t("planning.number_of_shifts", "Antal pass")}
                            data={
                              timereportResult.data.passCount
                                ? `${timereportResult.data.passCount} ${t("planning.pc", "st")}`
                                : `0 ${t("planning.pc", "st")}`
                            }
                          />
                        </Grid>
                        <Grid item>
                          <ActionCardDetailText
                            title={t("planning.period_to", "Period (till)")}
                            data={
                              timereportResult.data.period
                                ? moment(timereportResult.data.period).format("MMMM YYYY")
                                : "-"
                            }
                          />
                        </Grid>
                      </Grid>
                    }
                    actions={
                      <>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={3}>
                          <Grid item>
                            <BplTokenLink
                              label={t("planning.show_report", "Visa rapport")}
                              to="Client/Tidrapport/"
                              variant="link"
                              color="primary"
                              sx={{ color: theme.palette.primary.main }}
                            />
                          </Grid>
                          <Grid item>
                            {timereportResult &&
                            timereportResult.data &&
                            (!timereportResult.data?.isApproved || timereportResult.data?.isRevertable) ? (
                              <Button
                                disabled={timereportResult?.data?.passCount <= 0 && true}
                                onClick={toggleDialogTimeReport}
                                startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                color="primary">
                                {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                                  ? t("planning.submit", "Lämna in")
                                  : t("planning.undo_submission", "Ångra inlämning")}
                              </Button>
                            ) : (
                              <Typography fontWeight={600}>{t("planning.submitted", "Inlämnad")}</Typography>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <ActionCardLoading sx={{ marginTop: "0px" }} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={dialogTimeReportOpen}
          onClose={toggleDialogTimeReport}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
              ? "Vill du lämna in tidrapport?"
              : "Är du säker att du vill återställa tidrapport?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                ? "Vill du lämna in tidrapporten för godkännande?"
                : "Vill du återställa tidrapporten till aktiv status?"}{" "}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogTimeReport} variant="outlined">
              Avbryt
            </Button>
            <Button result={updateTimeReportResult} onClick={acceptTimeReport} color="primary" autoFocus>
              {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                ? "Lämna in tidrapport"
                : "Ångra tidrapport"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogAssignmentOpen}
          onClose={toggleDialogAssignment}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
              ? "Bekräfta uppdrag"
              : "Återställ uppdrag"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
                ? "Vill du bekräfta uppdrag?"
                : "Vill du återställa uppdrag?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogAssignment} variant="outlined">
              Avbryt
            </Button>
            <Button result={updateAssignmentResult} onClick={acceptAssignment} color="primary" autoFocus>
              {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
                ? "Bekräfta"
                : "Ångra bekräfta"}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  )
}

export default Overview
