import { Grid, Hidden, Typography } from "@mui/material"
import React from "react"
import ExaminationInfoCardVertical from "shared/learning/components/ExaminationInfoCardVertical"
import AvailableExamsSkeletonGroup from "./components/AvailableExamsSkeletonGroup"
import { useTranslation } from "react-i18next"

const AvailableExams = ({ myExamsResult }) => {
  const { t } = useTranslation()
  const courseDetails = t("learning.details.course", { returnObjects: true })

  return (
    <>
      <Hidden lgDown>
        <Typography pb={3} variant="h1">
          {t("global.titles.myCourses")}
        </Typography>
      </Hidden>
      <Grid container spacing={2}>
        {myExamsResult.status === 2 ? (
          myExamsResult.data
            .filter((item) => !item.examinationSummary.hasPassed)
            .map((item, i) => {
              const assignedCourses = courseDetails.find(
                (course) => course.examinationType === item.examinationType
              )
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  key={i}
                  style={{
                    borderRadius: "16px !important"
                  }}>
                  <ExaminationInfoCardVertical assignedCourses={assignedCourses} item={item} />
                </Grid>
              )
            })
        ) : (
          <AvailableExamsSkeletonGroup />
        )}
      </Grid>
    </>
  )
}
export default AvailableExams
