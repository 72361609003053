import { Box, Breadcrumbs, Button, Container, Grid, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { AppContext } from "components/AppContextProvider"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import FileForm from "pages/documents/components/Form/FileForm"
import FolderForm from "pages/documents/components/Form/FolderForm"
import { IDocumentFolder } from "pages/documents/interface/folder.interface"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { removeQueryFromImgSrc } from "../../helpers/imageSrcSasKey"

const EditFolder = ({ match }: any) => {
  const { t } = useTranslation("translation", { keyPrefix: "documents" })
  const appContext = useContext(AppContext)
  const [folderResult, getFolderApi] = useApi() as [IApiResult, IApiFunc]
  const [deleteResult, deleteFolder] = useApi() as [IApiResult, IApiFunc]
  const [showContinueModal, setShowContinueModal] = useState(false)
  const [updateFolderResult, updateFolder] = useApi() as [IApiResult, IApiFunc]
  const [initialValues, setInitialValues] = useState<IDocumentFolder>()
  const routerHistory = useHistory()

  const handleSubmit = (values: IDocumentFolder) => {
    if (values.body) {
      values.body = removeQueryFromImgSrc(values.body)
    }
    updateFolder.put(apiEndpoints.documents.updatedocument, values)
  }

  useEffect(() => {
    getFolderApi.get(
      apiEndpoints.documents.getdocument
        .replace("{id}", match.params.id)
        .replace("{companyId}", appContext.currentCompanyId)
    )

    return () => {
      getFolderApi.leave()
    }
  }, [getFolderApi])

  useEffect(() => {
    if (folderResult.status === 2) {
      setInitialValues(folderResult.data)
    }
  }, [folderResult])

  useEffect(() => {
    if (updateFolderResult.status === 2) {
      getFolderApi.get(
        apiEndpoints.documents.getdocument
          .replace("{id}", match.params.id)
          .replace("{companyId}", appContext.currentCompanyId)
      )
    }
  }, [updateFolderResult, match])

  useEffect(() => {
    if (deleteResult.status === 2) {
      routerHistory.push(allRoutes.Documents())
    }
  }, [deleteResult])

  const removeFile = () => {
    deleteFolder.del(apiEndpoints.documents.deletedocument.replace("{id}", match.params.id))
  }

  return (
    <div>
      <Container>
        <Grid container direction="row" justifyContent="space-between" spacing={3} marginBottom={3}>
          <Grid item xs={12} md={4} lg={6}>
            <Breadcrumbs aria-label="breadcrumb">
              <Box
                component={Link}
                to={allRoutes.Documents()}
                style={{ color: theme.palette.primary.main, textDecoration: "none" }}>
                Dokument
              </Box>
              {folderResult.status === 2 &&
                folderResult?.data?.breadcrumbs?.map(({ name, id }: any, index: number) => {
                  return index !== folderResult?.data?.breadcrumbs.length - 1 ? (
                    <Box
                      component={Link}
                      to={allRoutes.DocumentsDetails(id)}
                      style={{ color: theme.palette.primary.main, textDecoration: "none" }}>
                      {name}
                    </Box>
                  ) : (
                    <Box
                      component={Link}
                      to={allRoutes.DocumentsDetails(folderResult.data.id)}
                      style={{ color: theme.palette.primary.main, textDecoration: "none" }}>
                      {name}
                    </Box>
                  )
                })}
              <Typography color="text.primary">Redigera</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs="auto">
            {hasRole(appContext.appUser, allRoles.DocumentsAdmin) && (
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setShowContinueModal(!showContinueModal)
                  }}>
                  {t(`folder.remove_button`)}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid pt={4} container direction="row" justifyContent="space-between" spacing={3}>
          <Grid item xs={12} md={4} lg={5} xl={5}>
            {hasRole(appContext.appUser, allRoles.DocumentsAdmin) && initialValues !== undefined && (
              <FileForm formValues={initialValues} handleSubmit={handleSubmit} results={updateFolderResult} />
            )}
          </Grid>
          <Grid item xs={12} md={8} lg={7} xl={7}>
            {folderResult.status === 2 && initialValues && (
              <PaperBox>
                <FolderForm
                  formValues={initialValues}
                  handleSubmit={handleSubmit}
                  results={updateFolderResult}
                />
              </PaperBox>
            )}
          </Grid>
        </Grid>
      </Container>
      {showContinueModal && (
        <ContinueDialog
          result={deleteResult}
          setShowContinueModal={setShowContinueModal}
          onAddSubmit={removeFile}
          message={`Är du säker på att du vill ta bort mappen ${folderResult.data?.name} och alla uppladdade filer, ditt val går inte att ångra?`}
          continueBtnText="Ja, ta bort"
          cancelBtnText="Avbryt"
        />
      )}
    </div>
  )
}
export default EditFolder
