import React from "react"
import { ReportsDisplayer } from "pages/reports/components/ReportsDisplayer"
import { ReportTypeEnum } from "pages/reports/interfaces/reports.interfaces"

const index = () => {
  return (
    <ReportsDisplayer reportType={ReportTypeEnum.User} />
  )
}

export default index