import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { StyledReportBox, StyledReportBoxTitle, StyledReportPaperBox } from './report.styled'
import { faLongArrowRight } from '@fortawesome/pro-solid-svg-icons'
import ReportBigButton from './components/ReportBigButton/ReportBigButton'
import { getTemplateSettings } from 'pages/deviation/pages/helpers/templateSettings'
import { useHistory } from "react-router-dom"
import { allRoutes } from 'generated/routes'
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import { allRoles } from 'generated/roles'
import React, { useContext } from 'react'

interface IReportProps {
    activeCasesResult: any
}

const Report: React.FC<IReportProps> = ({ activeCasesResult }) => {

    const { t } = useTranslation()
    const history = useHistory()
    const appContext = useContext(AppContext)

    const reportLinks = [
        {
            key: "issue_reports",
            iconEnd: faLongArrowRight,
            handleClick: () => history.push(allRoutes.IssuereportsDashboard()),
            role: allRoles.IssueReports
        },
        {
            key: "vehicle_damage",
            iconEnd: faLongArrowRight,
            handleClick: () => history.push(`${allRoutes.Deviation()}?id=1`),
            role: allRoles.Deviation
        },
        {
            key: "threats_and_violence",
            iconEnd: faLongArrowRight,
            handleClick: () => history.push(`${allRoutes.Deviation()}?id=2`),
            role: allRoles.Deviation

        },
        {
            key: "working_environment",
            iconEnd: faLongArrowRight,
            handleClick: () => history.push(`${allRoutes.Deviation()}?id=3`),
            role: allRoles.Deviation

        },
        {
            key: "trafic_and_customer_info",
            iconEnd: faLongArrowRight,
            handleClick: () => history.push(`${allRoutes.Deviation()}?id=4`),
            role: allRoles.Deviation
        }

    ];

    return (
        <>
            <StyledReportPaperBox>
                <Grid direction="column" container>
                    {hasRole(appContext.appUser, allRoles.IssueReports) || hasRole(appContext.appUser, allRoles.Deviation) ?
                        <>
                            <Grid item xs={12} mb={2}>
                                <Typography color="textSecondary" variant="labelSmall">{t("dashboard.my_ongoing_matter", "Mina pågående ärenden")}</Typography>
                            </Grid>
                            <Grid container spacing={2}>
                                {hasRole(appContext.appUser, allRoles.IssueReports) &&
                                    <Grid item xs={6} md={3} lg={6} xl={4}>
                                        <StyledReportBox onClick={() => history.push(allRoutes.IssuereportsArchive())} activeData={activeCasesResult?.data?.issueReports}>
                                            <StyledReportBoxTitle color="textSecondary">{activeCasesResult?.data?.issueReports}</StyledReportBoxTitle>
                                            <Typography variant="labelSmall" color="textSecondary">{t("global.features.title.issuereports", "Felrapporter")}</Typography>
                                        </StyledReportBox>
                                    </Grid>}
                                {hasRole(appContext.appUser, allRoles.Deviation) &&
                                    <Grid item xs={6} md={3} lg={6} xl={4}>
                                        <StyledReportBox onClick={() => history.push(allRoutes.DeviationList())} activeData={activeCasesResult?.data?.deviations}>
                                            <StyledReportBoxTitle color="textSecondary">{activeCasesResult?.data?.deviations}</StyledReportBoxTitle>
                                            <Typography variant="labelSmall" color="textSecondary">{t("global.features.title.deviation", "Avvikelser")}</Typography>
                                        </StyledReportBox>
                                    </Grid>}
                                {hasRole(appContext.appUser, allRoles.Violation) &&
                                    <Grid item xs={6} md={3} lg={6} xl={4}>
                                        <StyledReportBox onClick={() => history.push(allRoutes.Violation())} activeData={activeCasesResult?.data?.violations}>
                                            <StyledReportBoxTitle color="textSecondary">{activeCasesResult?.data?.violations}</StyledReportBoxTitle>
                                            <Typography variant="labelSmall" color="textSecondary">{t("global.features.title.violation", "Avvikelser")}</Typography>
                                        </StyledReportBox>
                                    </Grid>}
                            </Grid>
                        </>
                        : null}
                    <Grid item xs={12} pt={5}>
                        <Grid container spacing={1} display="flex" flexDirection="column">
                            <Grid item mb={1}>
                                <Typography color="textSecondary" variant="labelSmall">{t("dashboard.create_new_report", "Skapa ny rapport")}</Typography>
                            </Grid>

                            {reportLinks?.map((link, index) => {
                                const settings = getTemplateSettings(link.key);
                                const label = settings?.label || "";
                                const defaultValue = settings?.defaultValue || "";
                                return (
                                    hasRole(appContext.appUser, link.role) && (
                                        <Grid item xs={12} key={index}>
                                            <ReportBigButton
                                                iconStart={settings?.icon}
                                                label={t(label, defaultValue)}
                                                {...link}
                                            />
                                        </Grid>
                                    )
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </StyledReportPaperBox>
        </>
    )
}

export default Report