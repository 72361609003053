import { Box, Divider, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import React from "react"
import { useTranslation } from "react-i18next"
import { ExamAreaHeader } from "./ExamAreaHeader"
import { MultiQuestion } from "./MultiQuestion"
import { SingleQuestion } from "./SingleQuestion"
import { useStyles } from "./useStyles"

export const Area = ({ area, activeStep, examinationType, translatedTitle, vimeoUrl }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const courseDetails = t(`learning.details.course`, { returnObjects: true }).find(
    (course) => course.examinationType === examinationType
  ).examinationDefinition

  return (
    <div>
      <ExamAreaHeader translatedTitle={translatedTitle} vimeoUrl={vimeoUrl} data={area} />
      {area?.areaQuestions?.length > 0 && (
        <PaperBox className={classes.content}>
          <Typography variant="h3">{t("global.titles.questions")}</Typography>
          {area?.areaQuestions?.map((q, key) => {
            let questionByShuffleId = courseDetails.areas[activeStep].areaQuestions.flatMap((aQ) =>
              aQ.questions.filter((x) => x.shuffleId === q.shuffleId)
            )[0]

            if (questionByShuffleId === undefined) {
              questionByShuffleId = courseDetails.areas[activeStep].areaQuestions.flatMap((aQ) =>
                aQ.questions.filter((x) => x.id === q.id)
              )[0]
            }
            return (
              <Box marginY={2} key={area.title + "_" + key}>
                <Typography variant="h6">{questionByShuffleId?.title}</Typography>
                {q.isMultiChoice && (
                  <Typography variant="bodySmall" color="textSecondary" marginBottom={2}>
                    {t("learning.exams.titles.severalOptions")}
                  </Typography>
                )}

                {q.isMultiChoice ? (
                  <MultiQuestion question={q} />
                ) : (
                  <SingleQuestion
                    translationOptions={questionByShuffleId?.options}
                    area={area.areaQuestions}
                    question={q}
                  />
                )}
                {key !== area?.areaQuestions?.length - 1 && <Divider light sx={{ marginTop: 2 }} />}
              </Box>
            )
          })}
        </PaperBox>
      )}
    </div>
  )
}
