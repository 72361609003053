import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { VehicleStatus } from "./Checks.interfaces"

export const ChecksStyled = styled(Box)(({ theme }) => ({
  background: "#ffffff",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    "& > div": {
      position: "relative",
      "& .MuiTableContainer-root": {
        overflow: "auto"
      }
    }
  }
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary["main"]
}))

interface IconStatus {
  status: VehicleStatus
}

export const StyledInspectionStatusIcon = styled(FontAwesomeIcon)<IconStatus>(({ theme, status }) => {
  let color: string
  let paddingLeft = ""
  switch (status) {
    case VehicleStatus.DrivingBan:
      color = theme.palette.error.main
      break
    case VehicleStatus.Deregistered:
      color = theme.palette.primary.main
      paddingLeft = "4px"
      break
    default:
      color = "inherit"
      break
  }
  return {
    color,
    paddingLeft
  }
})

export const StyledSpanError = styled("span")(({ theme }) => ({
  color: theme.palette.error.main
}))