import { Box, Grid } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApiDelete } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { RemoveDialog } from "../../../vehicleregistry/pages/reports/components/RemoveDialog/RemoveDialog"
import { ReportTypeEnum } from "../../interfaces/reports.interfaces"
import { EditUserReportTemplateProps } from "../../pages/userreports/pages/reporttemplates/pages/edit"
import { CheckedFilter, ReportTemplateAuth, TemplateFormDto } from "../AddTemplateForm/AddTemplateForm.interfaces"
import { customFieldsByCategory } from "../reports.helpers"
import { ReportTemplateContext } from "../ReportTemplateContextProvider"
import TemplateForm from "../TemplateForm/TemplateForm"

const skeletonRows = [1, 2, 3, 4]

const UpdateTemplateForm = ({ match, reportType }: EditUserReportTemplateProps) => {
  const { selectedFields, setSelectedFields, setFieldsToChoose, fieldsToChoose, contextTemplateAuths, setContextTemplateAuths } =
    useContext(ReportTemplateContext)
  const routerHistory = useHistory()
  const [getReportTemplateResult, getReportTemplateApi] = useApi() as [IApiResult, IApiFunc] // for getting the "base template" for the form (for edit this is the edit object)
  const [putReportTemplateResult, putReportTemplateApi] = useApi() as [IApiResult, IApiFunc] // for saving a new template based on the form
  const [deleteTemplateResult, deleteTemplateFuncs, resetDeleteState] = useApiDelete() as [
    IApiResult,
    (url: string) => {},
    () => {}
  ]
  const [openDialog, setDialogOpen] = useState(false)

  useEffect(() => {
    getReportTemplateApi.get(apiEndpoints.reporting.getreporttemplatebyid.replace("{id}", match.params.id))
    return () => {
      getReportTemplateApi.leave()
    }
  }, [match.params.id, getReportTemplateApi])

  useEffect(() => {
    if (getReportTemplateResult.status === 2) {
      const reportTemplateFields = customFieldsByCategory(getReportTemplateResult)
      setFieldsToChoose(reportTemplateFields)
      let selectedField: CheckedFilter[] = []
      getReportTemplateResult.data?.fieldGroups?.map((fieldGroup: any, groupIndex: number) => {
        return fieldGroup?.items.map((checkedFilter: any, index: number) => {
          if (checkedFilter.checked) {
            checkedFilter.groupIndex = groupIndex
            checkedFilter.ReportFieldCategoryEnum = fieldGroup.reportFieldCategoryEnum
            checkedFilter.index = index
            checkedFilter.title = fieldGroup.title
            selectedField.push(checkedFilter)
          }
        })
      })
      const templateAccessList: ReportTemplateAuth[] = []
      getReportTemplateResult.data?.userReportTemplateAuths?.map((templateAuth: ReportTemplateAuth) => {
        const { authorizationLevel, userId, templateId } = templateAuth
        if (authorizationLevel === 0) return
        const templateUserAuth = { userId, templateId, authorizationLevel }
        if (getReportTemplateResult.data?.userReportTemplateAuths?.length > 0) {
          templateUserAuth.templateId = getReportTemplateResult.data.id
        }
        templateAccessList.push(templateUserAuth)
      })
      setContextTemplateAuths(templateAccessList)
      return setSelectedFields(selectedField.sort((a, b) => a.order - b.order))
    }
  }, [getReportTemplateResult])

  useEffect(() => {
    if (deleteTemplateResult.status !== 2) return
    const reportTemplateRoute =
      reportType === ReportTypeEnum.User
        ? allRoutes.ReportsUserreportsReporttemplates
        : allRoutes.ReportsVehiclereportsReporttemplates
    routerHistory.push(reportTemplateRoute())
  }, [deleteTemplateResult.status, routerHistory])

  const saveTemplate = (values: TemplateFormDto) => {
    const sortedSelectedFields: CheckedFilter[] = []
    selectedFields.forEach((selectedField: any, index: number) => {
      selectedField.order = index
      sortedSelectedFields.push(selectedField)
    })

    const templateData = {
      id: values.id,
      name: values.name,
      description: values.description,
      fields: sortedSelectedFields,
      userReportTemplateAuths: contextTemplateAuths
    }
    putReportTemplateApi.put(apiEndpoints.reporting.updatereporttemplate, templateData)
  }

  const deleteTemplateDialog = () => {
    resetDeleteState()
    setDialogOpen(true)
  }

  const deleteTemplate = () => {
    deleteTemplateFuncs(apiEndpoints.reporting.deletereporttemplate.replace("{id}", match.params.id))
    resetDeleteState()
  }

  return (
    <>
      <Box mb={4}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center"></Grid>
      </Box>
      {getReportTemplateResult.status === 2 && fieldsToChoose.fieldGroups.length > 0 ? (
        <TemplateForm
          editMode={true}
          onSubmit={saveTemplate}
          editTemplate={fieldsToChoose}
          submitFeedbackResult={putReportTemplateResult}
          deleteTemplate={deleteTemplateDialog}
          reportType={reportType}
        />
      ) : (
        <Grid container direction="row" spacing={5}>
          <Grid item xs={12} md={5}>
            <Grid container direction="column" spacing={1}>
              {skeletonRows.map((index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <SkeletonCard />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>

          <Grid item xs={12} md={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <SkeletonCard padding="30px 20px" />
              </Grid>
              <Grid item xs={12}>
                <SkeletonCard padding="13px 15px" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <RemoveDialog
        deleteTemplate={deleteTemplate}
        deleteResult={deleteTemplateResult}
        openDialog={openDialog}
        setDialogOpen={setDialogOpen}
      />
    </>
  )
}

export default UpdateTemplateForm
