import { Search } from "@mui/icons-material"
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Typography
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import React, { useMemo, useState } from "react"
import { useAsyncDebounce } from "react-table"
import { useStyles } from "./style.js"
export function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter, Header } }) {
  return (
    <TextField
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      label={`Sök på ${Header}...`}
      variant="outlined"
      size="small"
    />
  )
}

export function SelectColumnFilter({
  column: {
    filterValue,
    setFilter,
    preFilteredRows,
    id,
    Header,
    defaultValue,
    FilterOptionName,
    FilterOptions
  }
}) {
  const classes = useStyles()
  const options = useMemo(() => {
    const options = new Set()

    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const [value, setValue] = React.useState(filterValue || defaultValue || "")

  const handleChange = (event) => {
    setFilter(() => event.target.value)
    setValue(String(event.target.value))
  }

  return (
    <FormControl size="small" className={classes.formControl} fullWidth>
      <InputLabel htmlFor={Header} id={`label${Header}`}>
        {Header}
      </InputLabel>

      <Select
        labelId={`label${Header}`}
        autoWidth
        value={value}
        label={Header}
        id={Header}
        onChange={handleChange}>
        <MenuItem value="">
          <em>Visa alla</em>
        </MenuItem>
        {FilterOptions
          ? FilterOptions.map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {option.name}
              </MenuItem>
            ))
          : options.map((option, i) => (
              <MenuItem key={i} value={option}>
                {FilterOptionName
                  ? FilterOptionName(option)
                  : typeof option == "boolean"
                  ? option
                    ? Header
                    : `Ej ${Header}`
                  : option}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  )
}

export function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <Slider
        type="range"
        step={1}
        marks
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Av</button>
    </>
  )
}

export function NumberRangeColumnFilter({ column: { preFilteredRows, setFilter, id, Header } }) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])
  const [value, setValue] = React.useState([min, max])

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setFilter(() => newValue)
  }

  const marks = [
    {
      value: min,
      label: `${min}`
    },
    {
      value: max,
      label: `${max}`
    }
  ]

  return (
    <div
      style={{
        width: "250px",
        maxWidth: "100%"
      }}>
      <Typography id="range-slider" gutterBottom>
        {Header}
      </Typography>
      <Slider
        track={false}
        value={value || ""}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        min={min}
        max={max}
        marks={marks}
      />
    </div>
  )
}

export function SwitchColumnFilter({
  column: { defaultValue, filterValue, setFilter, preFilteredRows, id, Header, FilterOptions }
}) {
  const [value, setValue] = React.useState(defaultValue)

  const handleChange = () => {
    const selectedValue = FilterOptions.find((x) => x !== value)
    setFilter(selectedValue)
    setValue(String(selectedValue))
  }
  return (
    <FormControl size="small">
      <FormControlLabel
        control={<Switch onChange={handleChange} id="switch" checked={value === defaultValue} />}
        label={Header}
      />
    </FormControl>
  )
}

export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <Grid container spacing={1} alignItems="flex-end" flexWrap="nowrap" width="auto">
      <Grid item>
        <Search />
      </Grid>
      <Grid item>
        <TextField
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
          label={`Sök...`}
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export function DatePickerColumnFilter({ column: { setFilter, Header } }) {
  const [selectedDate, setDate] = useState(null)
  const handleDateChange = (date) => {
    setDate(moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : null)
    setFilter(moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : null)
  }

  return (
    <DatePicker
      slotProps={{
        actionBar: {
          actions: ["clear"]
        }
      }}
      value={selectedDate ? moment(selectedDate) : null}
      onChange={handleDateChange}
      renderInput={(params) => (
        <TextField
          label={Header}
          {...params}
          id={"date" + Header}
          value={selectedDate}
          onChange={handleDateChange}
        />
      )}
      id={"date" + Header}
    />
  )
}
