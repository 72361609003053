import React from "react"
import { Container } from "@mui/material"
import { ReportTypeEnum } from "pages/reports/interfaces/reports.interfaces"
import UpdateTemplateForm from "pages/reports/components/UpdateTemplateForm/UpdateTemplateForm"
import { ReportTemplateContextProvider } from "pages/reports/components/ReportTemplateContextProvider"

export interface EditUserReportTemplateProps {
  match: {
    params: {
      id: string
    }
    isExact: boolean
    path: string
    url: string
  },
  reportType: ReportTypeEnum
}

const EditUserReportTemplate = ({ match }: EditUserReportTemplateProps) => {
  return (
    <ReportTemplateContextProvider>
      <Container>
        <UpdateTemplateForm
          match={match}
          reportType={ReportTypeEnum.User}
        />
      </Container>
    </ReportTemplateContextProvider>
  )
}

export default EditUserReportTemplate