import * as Yup from "yup"

export const companyValidationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required("Namn är obligatoriskt")
    .min(2, "Namn behöver vara minst 2 tecken långt"),
  organisationNumber: Yup.number().nullable().typeError("Organisationsnummer behöver vara ett nummer."),
  streetAddress: Yup.string().nullable(),
  postalCode: Yup.number().typeError("Postnummer behöver vara ett nummer.").nullable(),
  cityAddress: Yup.string().nullable()
})

export const step1 = Yup.object().shape({
  existingName: Yup.boolean(),
  name: Yup.string()
    .nullable()
    .when("existingName", {
      is: (value) => value === true,
      then: (schema) => schema.matches(true, "Kund finns redan registrerad")
    })
    .required("Namn är obligatoriskt")
    .min(2, "Namn behöver vara minst 2 tecken långt"),
  organisationNumber: Yup.number()
    .typeError("Organisationsnummer behöver vara ett nummer.")
    .required("Organisationsnummer är obligatoriskt")
    .nullable(),
  streetAddress: Yup.string().nullable(),
  postalCode: Yup.number().nullable().typeError("Postnummer behöver vara ett nummer."),
  cityAddress: Yup.string().nullable()
})
export const step2 = Yup.object().shape({
  modules: Yup.array()
})

export const step3 = Yup.object().shape({
  companies: Yup.array().of(companyValidationSchema)
})

export const step4 = Yup.object().shape({
  adminUser: Yup.object().shape({
    name: Yup.string().nullable().required("Namn är obligatoriskt"),
    existingUsername: Yup.boolean(),
    username: Yup.string()
      .nullable()
      .when("existingUsername", {
        is: (value) => value === true,
        then: (schema) => schema.matches(true, "Användarnamn finns redan registrerad")
      })
      .when("useUsername", {
        is: (value) => value === false,
        then: (schema) => schema.email("E-post har felaktigt format")
      })
      .required("Användarnamn är obligatoriskt"),
    sendEmailOnCreate: Yup.boolean(),
    email: Yup.string()
      .nullable()
      .email("E-post har felaktigt format")
      .when("sendEmailOnCreate", {
        is: (value) => value === true,
        then: (schema) =>
          schema.required(
            "E-post är obligatorisk om du också valt att skicka e-post med inloggningsuppgifter"
          )
      }),
    password: Yup.string()
      .nullable()
      .required("Lösenord är obligatoriskt")
      .matches(
        "(?=(.*[A-Z])+)(?=(.*[a-z])+)(?=(.*[0-9])+).{8,}$",
        "Lösenord måste vara minst 8 tecken långt, en stor bokstav, en liten bokstav och en siffra"
      ),
    adminSendEmailOnCreate: Yup.bool()
  })
})
