import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Editor } from "@tinymce/tinymce-react"
import React, { useEffect, useState } from "react"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { apiEndpoints } from "generated/apiEndpoints"
import { addSasKeyToImageSrc } from "pages/documents/helpers/imageSrcSasKey"
import { tinyMceApiKey } from "./TinyMCE"

interface TinyMCEViewerProps {
  initVal: string
  editorRef: React.MutableRefObject<any | null>
}

const StyledBox = styled(Box)({
  "& .tox-tinymce": {
    border: "none"
  },
  "& .tox-statusbar": {
    display: "none"
  },
  "& .tox-bottom-anchorbar": {
    display: "none",
    border: "none"
  }
})

export const TinyMCEViewer = ({ initVal, editorRef }: TinyMCEViewerProps) => {
  const [sasKeyResult, sasKeyApi] = useApi() as [IApiResult, IApiFunc]
  const [initialValue, setInitialValue] = useState<string>()

  useEffect(() => {
    sasKeyApi.get(apiEndpoints.documents.getsaskey)
  }, [])

  useEffect(() => {
    if (sasKeyResult.status !== 2) return
    if (initVal === null) {
      setInitialValue("")
      return
    }
    setInitialValue(addSasKeyToImageSrc(initVal, sasKeyResult.data))
  }, [sasKeyResult, initVal])

  return (
    <StyledBox>
      <Editor
        apiKey={tinyMceApiKey}
        onInit={(_evt, editor) => editorRef.current = editor}
        initialValue={initialValue}
        disabled={true}
        init={{
          height: 500,
          menubar: false,
          toolbar: false,
          branding: false,
          content_style: "body {" +
            "font-family:Helvetica,Arial,sans-serif; font-size:14px" +
            "}"
        }}
      />
    </StyledBox>
  )
}