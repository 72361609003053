import React from "react"

export enum ReportFieldDataTypeEnum {
  String = 1,
  Integer = 2,
  DateShort = 3,
  DateDetailed = 4,
  Decimal2 = 5,
  DateOnly = 6,
  Boolean = 7
}

export interface DataGridResultDto {
  columns: DataGridColumnDto[]
  subColumns: DataGridColumnDto[]
  rows: ReportRow[]
  totalSum: CalculatedItemDto[]
}

export interface ReportDataGrid {
  columns: ReportColumn[]
  subColumns: DataGridColumnDto[]
  rows: ReportRow[]
  totalSum: CalculatedItemDto[]
}

interface CalculatedItemDto {
  fieldName: string
  value: number | null
}

export interface ReportRow {
  id: number
  subRows: ReportRow[]
  items: { [key: string]: string }
}

export interface DataGridColumnDto {
  title: string
  dataType: ReportFieldDataTypeEnum
  accessor: string
  description: string
  datafilterId: number
  mainFilter: boolean
}

interface Column {
  Header: string;
  tooltip: string;
  FilterHeader: string;
  mainFilter: boolean;
  accessor?: string | ((row: any) => any);
  hidden?: boolean;
  Filter?: any;
  Cell?: any;
}

export interface ReportData {
  columns: Column[]
  subColumns: any[]
  rows: ReportRow[]
  footer: any
  totalSum: any
}

interface Template {
  id: number
  name: string
  requireDate: boolean
  editable: boolean
}

export interface Parameters {
  dateFrom: Date | number | undefined
  dateTo: Date | undefined
  filter: {
    name: string | null
    subcontract: string | null
  }
  selectedTemplate: Template | null
  showDateRange: boolean | string
}

export interface ReportResultsProps {
  parameters: Parameters
  reportType: ReportTypeEnum
}

export interface ParametersState {
  parameters: Parameters
  setParameters: React.Dispatch<React.SetStateAction<Parameters>>
}

export interface TemplateAndParameters extends ParametersState {
  templates: Template[]
}

export enum ReportTypeEnum {
  Vehicle = 1,
  User = 2
}

export interface ListReportTemplatesProps {
  reportType: ReportTypeEnum
}

export interface ReportDisplayerProps {
  reportType: ReportTypeEnum
}

export interface AddTemplateFormProps {
  reportType: ReportTypeEnum
}

export interface TemplateCardProps {
  data: ReportTemplate
  reportType: ReportTypeEnum
  index: number
  totalCards: number
}

export interface ReportTemplate {
  id: string
  name: string
  description: string
  isEditable: boolean
}

interface ReportColumn {
  Header: string
  tooltip: string | ((row: any) => string)
}

export interface FieldsByHeader {
  columns: ReportColumn[]
  rows: ReportRow[]
}