import { Avatar } from "@mui/material"
import classnames from "classnames"
import globalUseStyles from "css/theme/globalUseStyles.js"
import React from "react"

export function stringAvatar(name) {
  var names = name.split(" "),
    initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return {
    children: `${initials}`
  }
}
const GetSize = (size) => {
  switch (size) {
    case "xs":
      return { width: 12, height: 12, padding: "12px" }
    case "sm":
      return { width: 15, height: 15, padding: "15px" }
    default:
      return { width: 20, height: 20, padding: "20px" }
  }
}

const UserAvatar = (props) => {
  const globalClasses = globalUseStyles()
  const { label, size, sx } = props

  return (
    <Avatar
      {...props}
      sx={{ ...GetSize(size), ...sx }}
      {...stringAvatar(label)}
      className={classnames(globalClasses.avatar, globalClasses.backgroundPrimary)}
    />
  )
}
export default UserAvatar
