import React from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

export enum ScreenSize {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl"
}

export interface ResponsiveAccordionProps {
  screenSize: ScreenSize
  title: string
  customCss?: React.CSSProperties
  stats?: { name: string, value: string, icon?: IconProp }[]
  shouldExpand?: boolean
  children: React.ReactNode
}