import moment from "moment/moment"
import { Palette } from "@mui/material/styles/createPalette";
import { faClock, faPartyHorn, faCircleExclamation } from "@fortawesome/pro-solid-svg-icons"

interface ExaminationText {
  hasExpired: boolean;
  hasPassed: boolean;
  date: Date | undefined;
  isExpiresIn30Days: boolean
}

export const getColors = (status: string, theme: {palette: Palette}) => {
  const style: {
    textColor: string | undefined
    bgColor: string | undefined
  } = {
    textColor: `${status}.dark`,
    bgColor: `${status}.light`
  }
  if (status === "warning") {
    style.textColor = theme.palette.warning[500]
  } else if (status === "background") {
    style.textColor = theme.palette.grey[800]
    style.bgColor = `${status}.default`
  }
  return style
}

export const getExaminationText = (examinationStatusObject: ExaminationText) => {
  if (examinationStatusObject.hasExpired) {
    return "Förfallen"
  } else if (examinationStatusObject.hasPassed) {
    return "Avklarad"
  } else if (examinationStatusObject?.date && moment(examinationStatusObject.date).diff(moment(), "days") > 30) {
    const daysCount = moment(examinationStatusObject.date).diff(moment(), "days")
    return `Förfaller om ${daysCount} dagar`
  } else if (examinationStatusObject.isExpiresIn30Days) {
    const daysCount = moment(examinationStatusObject.date).diff(moment(), "days")
    return `Förfaller inom ${daysCount} dagar`
  }
}
export const getExaminationColor = (examinationStatusObject: ExaminationText) => {
  if (examinationStatusObject.hasExpired) {
    return "error"
  } else if (examinationStatusObject.hasPassed) {
    return "secondary"
  } else if (examinationStatusObject?.date && moment(examinationStatusObject.date).diff(moment(), "days") > 30) {
    return "background"
  } else if (examinationStatusObject.isExpiresIn30Days) {
    return "warning"
  }
}
export const getExaminationIcon = (examinationStatusObject: ExaminationText) => {
  if (examinationStatusObject.hasExpired) {
    return faCircleExclamation
  } else if (examinationStatusObject.hasPassed) {
    return faPartyHorn
  } else if (examinationStatusObject?.date && moment(examinationStatusObject.date).diff(moment(), "days") > 30) {
    return faClock
  } else if (examinationStatusObject.isExpiresIn30Days) {
    return faClock
  }
}