import { Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { FC } from "react";

interface LoadingTypographyProps {
    width?: number | string;
    variant?: any;
    height?: number | string;
    noAnimate?: boolean;
    text?: string;
}

const LoadingTypography: FC<LoadingTypographyProps> = ({ width = -100, variant, height = -100, noAnimate = false }) => {
    return (
        <Typography variant={variant} style={{ flex: "1" }}>
            <Skeleton
                animation={noAnimate ? false : "wave"}
                variant="text"
                width={width === -100 ? width : "100%"}
                height={height === -100 ? height : "100%"}
            />
        </Typography>
    );
};

export default LoadingTypography;