import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

export const StyledReportResultBox = styled(Box)(({ theme }) => ({
  background: "#ffffff",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    "& > div": {
      position: "relative",
      "& .MuiTableContainer-root": {
        overflow: "auto"
      }
    }
  }
}))

export const StyledIconBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  backgroundColor: (theme.palette.background as any).blue,
  borderRadius: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "28px",
  minWidth: "28px"
}))