import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Editor } from "@tinymce/tinymce-react"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { apiEndpoints } from "generated/apiEndpoints"
import { addSasKeyToImageSrc } from "pages/documents/helpers/imageSrcSasKey"
import { AppContext } from "components/AppContextProvider"

interface TinyMCEProps {
  setFormikData: (value: any) => void
  formikValues: {
    body: string
  }
  uploadMediaUrl: string
}

const StyledBox = styled(Box)(({ theme }) => ({
  "& .tox-toolbar__group": {
    padding: "0 !important"
  }
}))

const getLanguageCode = (languageString: string): string => {
  switch (languageString) {
    case "sv":
      return "sv_SE";
    case "en":
      return "en_GB";
    default:
      return "sv_SE";
  }
};

export const tinyMceImportDate = "2025-01-16"
export const tinyMceApiKey = "763z2su1l2dtn586xkq0voysr8fst8atxfhq0b1hdg174ttn"

export const TinyMCE = (props: TinyMCEProps) => {
  const { setFormikData, formikValues, uploadMediaUrl } = props
  const [fileUploadResult, uploadFile] = useApi() as [IApiResult, IApiFunc]
  const editorRef = useRef(null) as any
  const [sasKeyResult, sasKeyApi] = useApi() as [IApiResult, IApiFunc]
  const [initVal, setInitVal] = useState<string>("")
  const callbackRef = useRef<((imgSrc: string) => void) | null>(null)
  const { selectedLanguage } = useContext(AppContext);

  useEffect(() => {
    sasKeyApi.get(apiEndpoints.documents.getsaskey)
  }, [])

  useEffect(() => {
    if (sasKeyResult.status !== 2) return
    if (formikValues?.body === null) {
      setInitVal("")
      return
    }
    setInitVal(addSasKeyToImageSrc(formikValues.body, sasKeyResult.data))
  }, [sasKeyResult])

  useEffect(() => {
    if (fileUploadResult.status !== 2) return
    const imgSrc = `${fileUploadResult.data?.blobUrl}/${fileUploadResult.data?.fileId}${fileUploadResult.data?.sasKey}`
    // editorRef.current?.execCommand("mceInsertContent", false, `<img src="${imgSrc}" alt="image" />`)
    if (callbackRef.current) {
      callbackRef.current(imgSrc)
      callbackRef.current = null
    }
  }, [fileUploadResult])

  const handleFileUpload = (file: File) => {
    uploadFile.file(uploadMediaUrl, file)
  }

  return (
    <StyledBox>
      <Editor
        apiKey={tinyMceApiKey}
        onInit={(_evt, editor) => {
          editorRef.current = editor
        }}
        initialValue={initVal || ""}
        onEditorChange={(content: any, editor: any) => {
          setFormikData(content)
        }}
        init={{
          language: getLanguageCode(selectedLanguage),
          height: 600,
          menubar: false,
          plugins: [
            "advlist", "autolink", "lists", "link", "image", "charmap", "preview",
            "anchor", "searchreplace", "visualblocks", "fullscreen",
            "insertdatetime", "media", "mediaembed", "table", "code", "help", "wordcount", "save"
          ],
          toolbar: "undo redo | blocks | bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | image | media | help ",
          statusbar: false,
          content_style: "body { font-family:Poppins,sans-serif; font-size:14px }",
          media_live_embeds: true,
          branding: false,
          images_upload_handler: (blobInfo, _progress) => {
            return new Promise(resolve => {
              const file: any = blobInfo.blob()
              callbackRef.current = resolve
              handleFileUpload(file)
            })
          }
        }}
      />
    </StyledBox>
  )
}