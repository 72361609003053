import { faComment, faListCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, CardContent, Grid, Hidden, Typography } from "@mui/material"
import { useTheme } from "@mui/styles"
import { ChipTag } from "components/ChipTag/ChipTag"
import LinkPaperBox from "components/LinkPaperBox"
import { allRoutes } from "generated/routes"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import {
  getColors,
  getExaminationColor,
  getExaminationIcon,
  getExaminationText
} from "shared/learning/components/helpers/examinationHelpers"
import useStyles from "./styles.js"

const ExaminationInfoCardVertical = (props) => {
  const { item, assignedCourses } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const examinationText = getExaminationText(item.examinationSummary)
  const examinationColor = getExaminationColor(item.examinationSummary)
  const examinationIcon = getExaminationIcon(item.examinationSummary)
  const { textColor, bgColor } = getColors(examinationColor, theme)

  const styleProps = {
    image: "/assets/learning/card_".concat(item.examinationType).concat(".jpg"),
    borderTopLeftRadius: "16px !important",
    borderTopRightRadius: "16px !important"
  }

  const classes = useStyles(styleProps)

  return (
    <LinkPaperBox
      p={0}
      mt={0}
      sx={{ borderRadius: "16px !important", boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 4%)" }}>
      <NavLink
        style={{ position: "relative" }}
        to={allRoutes.LearningUserExamsDetails(item.userExaminationId)}>
        <Hidden lgUp>
          <ChipTag
            customCss={{ position: "absolute", top: "16px", right: "16px" }}
            text={examinationText}
            textColor={textColor}
            bgColor={bgColor}
            icon={examinationIcon}
          />
        </Hidden>
        <Box item className={classes.image} xs={12} />
        <CardContent className={classes.content}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap" pb={3}>
            <Typography fontSize="14px" fontWeight="500">
              {assignedCourses ? assignedCourses?.examinationName : item.examinationName}
            </Typography>
            <Hidden lgDown>
              <ChipTag
                text={examinationText}
                textColor={textColor}
                bgColor={bgColor}
                icon={examinationIcon}
              />
            </Hidden>
          </Box>
          <Box>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item className={classes.flex}>
                <FontAwesomeIcon icon={faComment} className={classes.icon} />
                <Typography fontSize="12px">
                  {item.examinationQuestionCount} {t("learning.titles.questions")}
                </Typography>
              </Grid>
              <Grid item className={classes.flex}>
                <FontAwesomeIcon icon={faListCheck} className={classes.icon} />
                <Typography fontSize="12px">
                  {item.examinationAreaCount} {t("learning.titles.segments")}
                </Typography>
              </Grid>
              <Grid item className={classes.flex}></Grid>
            </Grid>
          </Box>
        </CardContent>
      </NavLink>
    </LinkPaperBox>
  )
}

export default ExaminationInfoCardVertical
