import { IApiResult } from "auth/interface/api.interface"
import { FormikProps } from "formik"

export interface UserCustomFieldFormik {
  id: number
  name: string;
  valueFields: UserCustomFieldValueFieldFormik[];
  active: boolean;
  userCustomFieldCategoryId: number;
}

export interface UserCustomFieldValueFieldFormik {
  id?: number
  name: string
  type: string
  isRequired: boolean
}

export interface UserCustomFieldDto {
  id: number;
  customerId: number;
  name: string;
  valueFields: UserCustomFieldFormFieldDto[];
  active: boolean;
  userCustomFieldCategoryId: number;
  category: UserCustomFieldCategoryDto;
}

export interface UserCustomFieldFormFieldDto {
  id: number
  userCustomFieldValueFieldId: number;
  userCustomFieldValueId: number;
  name: string;
  type: string;
  component: FormFieldComponentEnum;
  dataType: FormFieldDataTypeEnum;
  value: string;
  isRequired: boolean;
}

export interface UserCustomFieldCategoryDto {
  id: number;
  customerId: number;
  name: string;
  active: boolean;
}

export enum FormFieldComponentEnum {
  TextField = "TextField",
  MultiTextField = "MultiTextField",
  Upload = "Upload",
  RadioButton = "RadioButton",
  Checkbox = "Checkbox",
  BigRadioButtons = "BigRadioButtons",
  Select = "Select",
  DatePicker = "DatePicker",
  DateTimePicker = "DateTimePicker",
  VehiclePicker = "VehiclePicker",
  Text = "Text",
  CardButton = "CardButton",
  LocationPicker = "LocationPicker",
  DepartmentPicker = "DepartmentPicker",
  CompanyPicker = "CompanyPicker",
  Switch = "Switch"
}

export enum FormFieldDataTypeEnum {
  String = 0,
  Number = 1,
  Boolean = 2,
  DateTime = 3,
  Date = 4,
  JsonString = 5,
  BlobStorage = 6
}

export interface UserCustomFieldValuesProps {
  userResult: IApiResult
}

export interface UserCustomFieldWithCategory extends UserCustomFieldFormFieldDto {
  category: string
  formikKey: string | number
  isFirstInCategory: boolean
  parentName?: string
}

export interface CategoryTabsProps {
  categoryTabValue: number
  setCategoryTabValue: (value: number) => void
  categories: string[]
  formikProps: FormikProps<any>
  cachedValues: { [key: number | string]: UserCustomFieldWithCategory }
}