import React from "react"
import ListReportTemplates from "pages/reports/components/ListReportTemplates"
import { ReportTypeEnum } from "pages/reports/interfaces/reports.interfaces"

const ListTemplates = () => {
  return (
    <ListReportTemplates reportType={ReportTypeEnum.Vehicle} />
  )
}

export default ListTemplates
