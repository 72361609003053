import { Feedback } from "@mui/icons-material"
import { Container } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect } from "react"
import { removeQueryFromImgSrc } from "pages/documents/helpers/imageSrcSasKey"
import CrisisInfoForm from "./CrisisInfoForm"

const CrisisInfoSettings = () => {
  const [crisisInfoResult, crisisInfoApi] = useApi() as [IApiResult, IApiFunc]
  const [saveCrisisInfoResult, saveCrisisInfo] = useApi() as [IApiResult, IApiFunc]

  useEffect(() => {
    crisisInfoApi.get(apiEndpoints.crisismanagement.getcrisisinfo)
  }, [crisisInfoApi])

  const handleSubmit = (objectToSave: any) => {
    if (objectToSave.body) {
      objectToSave.body = removeQueryFromImgSrc(objectToSave.body)
    }
    saveCrisisInfo.put(apiEndpoints.crisismanagement.upsertcrisisinfo, objectToSave)

    const feedback = {
      errorTitle: "Nyhet kunde inte skapas"
    }
  }

  return (
    <>
      {console.log(crisisInfoResult.data)}
      <Container>
        <h2>Krishantering</h2>
        {crisisInfoResult.status === 2 || crisisInfoResult.status === 3 ? (
          <>
            <CrisisInfoForm
              handleSubmit={handleSubmit}
              initialCrisisInfo={crisisInfoResult.data ? crisisInfoResult.data : null}
              result={saveCrisisInfoResult}
              feedback={Feedback}
            />
          </>
        ) : null}
      </Container>
    </>
  )
}

export default CrisisInfoSettings
