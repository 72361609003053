import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Grid, Hidden, Typography, useMediaQuery, useTheme } from "@mui/material"

const StyledBox = styled(Box)(({ isLargeDown, bgColor, hasAccordionWrapper }) => ({
  backgroundColor: hasAccordionWrapper && isLargeDown ? "none" : bgColor ? bgColor : "#FFFFFF",
  borderRadius: hasAccordionWrapper && isLargeDown ? "0px" : "16px",
  boxShadow: hasAccordionWrapper && isLargeDown ? "none" : "0px 0px 15px 0px rgb(0 0 0 / 4%)",
  height: "100%"
}))

export const ActionCardDetails = (props) => {
  const { title, icon, details, actions, bgColor, borderColor, columnDirection, pb, hasAccordionWrapper } =
    props
  const theme = useTheme()
  const isLargeDown = useMediaQuery(theme.breakpoints.down("lg"))

  const borderStyles = `1px solid ${borderColor ? borderColor : "#E9E9E9"}`

  return (
    <StyledBox isLargeDown={isLargeDown} bgColor={bgColor} hasAccordionWrapper={hasAccordionWrapper}>
      <Grid container direction={columnDirection ? columnDirection : "row"}>
        <Grid item md={6} xs={12} p={3} pb={pb && !isLargeDown ? pb : 4}>
          {hasAccordionWrapper ? (
            <Hidden lgDown>
              <>
                {icon && <FontAwesomeIcon size="lg" icon={icon} />}
                {title && (
                  <Typography variant="h3" fontWeight="500" mb={4} ml={icon && 1}>
                    {title}
                  </Typography>
                )}
              </>
            </Hidden>
          ) : (
            <>
              {icon && <FontAwesomeIcon size="lg" icon={icon} />}
              {title && (
                <Typography variant="h3" fontWeight="500" mb={4} ml={icon && 1}>
                  {title}
                </Typography>
              )}
            </>
          )}
          {details}
        </Grid>
        <Grid
          sx={{
            borderLeft: !isLargeDown && columnDirection === "row" ? borderStyles : undefined,
            borderTop: isLargeDown ? borderStyles : columnDirection === "column" ? borderStyles : undefined
          }}
          p={3}
          item
          md={6}
          xs={12}>
          {actions}
        </Grid>
      </Grid>
    </StyledBox>
  )
}

export default ActionCardDetails
