import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faBus,
  faCar,
  faCircleInfo,
  faPenToSquare,
  faTrailer,
  faTruck,
  faXmark
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import { CircleIcon } from "components/icons/CircleIcon"
import PaperBox from "components/PaperBox"
import { allRoles } from "generated/roles"
import { useContext, useState } from "react"
import { TextCell } from "./components/TextCell"

const vehicleIcon = (variant: string) => {
  switch (variant) {
    case "Lastbil" || "LB":
      return faTruck
    case "Personbil":
      return faCar
    case "Släpvagn":
      return faTrailer
    default:
      return faBus
  }
}

const StyledButton = styled(Button)({
  padding: 0,
  marginRight: "-12px",
  marginTop: "-20px",
  minWidth: "50px",
  minHeight: "50px"
})

interface HeaderProps {
  status: number
  headerData: any
  openForm: () => void
}

const StyledPaperBoxVehicleCard = styled(PaperBox)(({ isArchived }) => ({
  backgroundColor: isArchived ? "#F1F1F1" : "#F1FCFE",
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)"
}))

const StyledToolTip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "#FFFFFF",
    color: theme.palette.text.primary
  },
  "& .MuiTooltip-arrow": {
    color: "#FFFFFF"
  }
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "0",
  top: "18px"
}))

const StyledFontAwesomeXmark = styled(FontAwesomeIcon)(({ theme }) => ({
  width: "20px",
  height: "20px"
}))

const CustomTooltipContent = ({ handleTooltipClose }: { handleTooltipClose: () => void }) => (
  <Grid container direction="column" spacing={2} p={2} position="relative">
    <StyledIconButton onClick={handleTooltipClose} size="small">
      <StyledFontAwesomeXmark icon={faXmark} />
    </StyledIconButton>
    <Grid item>
      <Typography variant="h4">Mätarställning</Typography>
    </Grid>
    <Grid item>
      <Typography fontWeight="500" variant="labelSmall">
        Mätarställning
      </Typography>
      <Typography variant="bodyXsmall" color="textSecondary">
        Manuellt angiven mätarställning.
      </Typography>
    </Grid>
    <Grid item>
      <Typography fontWeight="500" variant="labelSmall">
        Mätarställning färdskrivare
      </Typography>
      <Typography variant="bodyXsmall" color="textSecondary">
        Uppgift hämtas från färdskrivaren [1 gång per dygn].
      </Typography>
    </Grid>
    <Grid item>
      <Typography fontWeight="500" variant="labelSmall">
        Mätarställning föregående besiktning
      </Typography>
      <Typography variant="bodyXsmall" color="textSecondary">
        Rapporterad mätarställning vid den senaste besiktningen som hämtas från Transportstyrelsen.
      </Typography>
    </Grid>
  </Grid>
)

const Header = ({ headerData, openForm }: HeaderProps) => {
  const appContext = useContext(AppContext)
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const CustomToolTip = () => (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={{ marginTop: "-4px" }}>
        <StyledToolTip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: theme.palette.background["paper"],
                color: theme.palette.text.primary,
                boxShadow: "0px 0px 65px 0px rgb(0 0 0 / 6%)"
              }
            }
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<CustomTooltipContent handleTooltipClose={handleTooltipClose} />}>
          <IconButton onClick={handleTooltipOpen} color="primary" size="small">
            <FontAwesomeIcon icon={faCircleInfo} />
          </IconButton>
        </StyledToolTip>
      </Box>
    </ClickAwayListener>
  )

  return (
    <Grid container direction="row" alignItems="flex-end" spacing={5} md={10}>
      <Grid item md={10}>
        <StyledPaperBoxVehicleCard isArchived={headerData.archived}>
          <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
            <Grid item>
              <Box component={Grid} container direction="row" marginBottom={3} alignItems="center">
                <CircleIcon size="lg" icon={vehicleIcon(headerData.fordonstyp)} color="#254467" />
                <Grid flexDirection="column">
                  <Typography marginLeft={2} variant="h2">
                    {headerData && headerData.regNumber}
                  </Typography>
                  <Typography marginLeft={2} variant="bodyMedium">
                    {headerData && headerData.fordonstyp}
                  </Typography>
                </Grid>
                {headerData.archived && (
                  <Box
                    sx={{ backgroundColor: "#F8CCC6", borderRadius: "20px" }}
                    px={3}
                    py={0.5}
                    ml={4}
                    mb={2}
                    color={"black"}>
                    <Typography variant="paragraphMedium">Inaktivt</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            {hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin) && (
              <Grid>
                <StyledButton
                  sx={{
                    border: headerData.archived ? "2px grey solid" : ""
                  }}
                  onClick={() => openForm()}>
                  <FontAwesomeIcon
                    color={theme.palette.grey[600]}
                    style={{
                      borderRadius: "100%",
                      fontSize: "30px",
                      width: "26px",
                      height: "26px",
                      color: headerData.archived ? "" : "#254467"
                    }}
                    icon={faPenToSquare as IconProp}
                  />
                </StyledButton>
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item>
              <TextCell label="Internnr" text={headerData && headerData.internnumber} icon={null} />
            </Grid>
            <Grid item>
              <TextCell label="Fabrikat" text={headerData && headerData.fabrikat} icon={null} />
            </Grid>
            <Grid item>
              <TextCell
                icon={hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin) && <CustomToolTip />}
                label="Mätarställning"
                text={headerData && headerData.meterSetting}
              />
            </Grid>
            <Grid item>
              <TextCell label="Fordonstyp" text={headerData && headerData.fordonstyp} icon={null} />
            </Grid>
            <Grid item>
              <TextCell label="Bolag" text={headerData && headerData.companyName} icon={null} />
            </Grid>
            <Grid item>
              <TextCell label="Avdelning" text={headerData && headerData.departmentName} icon={null} />
            </Grid>
            <Grid item>
              <TextCell
                label="Drivmedel"
                text={
                  headerData && headerData.mainPropellant !== null
                    ? headerData.mainPropellant
                    : headerData.drivMedel1Kod
                }
              />
            </Grid>
            <Grid item>
              <TextCell
                label="Färdskrivare"
                text={headerData && headerData.tachographIntegrationActive ? "Ja" : "Nej"}
                icon={null}
              />
            </Grid>
          </Grid>
        </StyledPaperBoxVehicleCard>
      </Grid>
    </Grid>
  )
}

export default Header
