import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faCarCrash,
  faCarWrench,
  faHand,
  faMemoPad,
  faScreenUsers,
  faThoughtBubble,
  faTrafficLight
} from "@fortawesome/pro-solid-svg-icons"
import { TemplateSettings } from "pages/deviation/deviation.interface"

export const getTemplateSettings = (key: string) => {


  switch (key) {
    case "vehicle_damage":
      return {
        defaultValue: "Fordonskada",
        label: "global.features.title.deviationvehicledamage",
        icon: faCarCrash as IconProp,
        description: "Om du råkat ut för en trafikolycka eller fått en skada på ditt fordon"
      } as TemplateSettings
    case "threats_and_violence":
      return {
        defaultValue: "Hot och våld",
        label: "global.features.title.deviationthreatsandviolence",
        icon: faHand as IconProp,
        description: "Du har blivit hotad eller skadad på din arbetsplats."
      } as TemplateSettings
    case "traffic_and_customerinfo":
      return {
        defaultValue: "Trafik och kundinfo",
        label: "global.features.title.deviationtrafficandcustomerinfo",
        icon: faTrafficLight as IconProp,
        description: "Olycka eller stopp i trafiken"
      } as TemplateSettings
    case "working_environment":
      return {
        defaultValue: "Arbetsmiljö",
        label: "global.features.title.deviationworkingenvironment",
        icon: faScreenUsers as IconProp,
        description: "Du har råkat ut för något på din arbetsplats"
      } as TemplateSettings
    case "traffic_log":
      return {
        defaultValue: "Trafiklogg",
        label: "global.features.title.deviationtrafficlog",
        icon: faMemoPad as IconProp,
        description: ""
      } as TemplateSettings
    case "suggestion_box":
      return {
        defaultValue: "Förslagslåda",
        label: "global.features.title.deviationsuggestionbox",
        icon: faThoughtBubble as IconProp,
        description: ""
      } as TemplateSettings
    case "trafic_and_customer_info":
      return {
        defaultValue: "Trafik och kundinfo",
        label: "global.features.title.deviationtrafficandcustomerinfo",
        icon: faTrafficLight as IconProp,
        description: ""
      } as TemplateSettings
    case "issue_reports":
      return {
        defaultValue: "Felrapporter",
        label: "global.features.title.issuereports",
        icon: faCarWrench as IconProp,
        description: ""
      } as TemplateSettings
    default:
      return
  }
}
