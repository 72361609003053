import { styled } from "@mui/material/styles"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Button, Box } from '@mui/material'
import PaperBox from "components/PaperBox";

export const StyledReportPaperBox = styled(PaperBox)(({ theme }) => ({
    margin: "0px",
    padding: "24px",
    borderRadius: "16px",
    [theme.breakpoints.down("lg")]: {
        padding: "24px"
    }
}))

export const StyledReportBox = styled(Box)<{ activeData: number }>(({ theme, activeData }) => ({
    textAlign: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "34px 24px",
    [theme.breakpoints.only("lg")]: {
        padding: "28px 24px"
    },
    backgroundColor: activeData <= 0 ? "#eef0f2" : theme.palette.primary.light,
    borderRadius: "12px",
    cursor: "pointer",
}));

export const StyledReportBoxTitle = styled(Typography)(() => ({
    fontSize: "32px",
    fontWeight: "500",
}))

export const StyledReportBigButton = styled(Button)(({ theme }) => ({
    borderRadius: "12px",
    padding: "32px 30px",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "none",
    color: theme.palette.text.primary,
    width: "100%",
}))

export const StyledReportIconStart = styled(FontAwesomeIcon)(({ theme }) => ({
    fontSize: "24px",
    color: theme.palette.primary["main"]
}))

export const StyledReportIconEnd = styled(FontAwesomeIcon)(({ theme }) => ({
    fontSize: "12px",
    color: theme.palette.primary["main"]
}))