import { ReportTemplateContextProvider } from "pages/reports/components/ReportTemplateContextProvider"
import React from "react"
import { Container } from "@mui/material"
import { ReportTypeEnum } from "pages/reports/interfaces/reports.interfaces"
import AddTemplateForm  from "pages/reports/components/AddTemplateForm/AddTemplateForm"

const AddTemplate = () => {
  return (
    <ReportTemplateContextProvider>
      <Container>
        <AddTemplateForm reportType={ReportTypeEnum.Vehicle} />
      </Container>
    </ReportTemplateContextProvider>
  )
}

export default AddTemplate
