export const removeQueryFromImgSrc = (html: string): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")

  const imgTags = doc.getElementsByTagName("img")
  for (let img of imgTags as any) {
    const src = img.getAttribute("src")
    if (src) {
      const cleanSrc = src.split("?")[0]
      img.setAttribute("src", cleanSrc)
    }
    const mceSrc = img.getAttribute("data-mce-src")
    if (mceSrc) {
      const cleanSrc = mceSrc.split("?")[0]
      img.setAttribute("data-mce-src", cleanSrc)
    }
  }

  return doc.body.innerHTML
}

export const addSasKeyToImageSrc = (html: string, sasKey: string): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")

  const imgTags = doc.getElementsByTagName("img")
  for (let img of imgTags as any) {
    const src = img.getAttribute("src")
    if (src) {
      const newSrc = src.includes("?") ? `${src}&${sasKey}` : `${src}${sasKey}`
      img.setAttribute("src", newSrc)
    }
    const mceSrc = img.getAttribute("data-mce-src")
    if (mceSrc) {
      const newMceSrc = mceSrc.includes("?") ? `${mceSrc}&${sasKey}` : `${mceSrc}${sasKey}`
      img.setAttribute("data-mce-src", newMceSrc)
    }
  }

  return doc.body.innerHTML
}