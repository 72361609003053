import React, { useContext, useEffect, useState } from "react"
import { Box, Container, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import PaperBox from "components/PaperBox"
import ReportResult from "./ReportResult/ReportResult"
import { ReportParameters } from "./ReportParameters"
import { Parameters, ReportDisplayerProps } from "../interfaces/reports.interfaces"
import { StyledReportResultBox } from "./reports.styled"

export const ReportsDisplayer = ({ reportType }: ReportDisplayerProps) => {
  // lite copypasta för default denna månad
  const date = new Date()
  const firstDay = date.setMonth(date.getMonth() - 1)
  const lastDay = new Date()

  const [reportTemplatesResult, reportTemplatesApi] = useApi() as [IApiResult, IApiFunc]
  const loadingContext = useContext(GlobalLoaderContext)

  const [parameters, setParameters] = useState<Parameters>({
    selectedTemplate: null,
    showDateRange: false,
    dateFrom: firstDay,
    dateTo: lastDay,
    filter: { name: null, subcontract: null }
  })

  useEffect(() => {
    const url = `${apiEndpoints.reporting.getavailablereporttemplates}?reportType=${reportType}`
    reportTemplatesApi.get(url)

    return () => {
      reportTemplatesApi.leave()
    }
  }, [reportTemplatesApi])

  useEffect(() => {
    if (reportTemplatesResult.status !== 2) return
    setParameters((prevState) => {
      return {
        ...prevState,
        selectedTemplate: reportTemplatesResult.data[0]
      }
    })
  }, [reportTemplatesResult])

  useEffect(() => {
    loadingContext.setLoading(() => reportTemplatesResult.status === 1)
  })

  return (
    <div>
      <Container>
        <Box mb={4}>
          <Typography variant="h1">Rapporter</Typography>
        </Box>
      </Container>
      {reportTemplatesResult.status === 2 && (
        reportTemplatesResult.data.length > 0 ? (
        <>
          <PaperBox p={0}>
            <Box paddingX={3}>
              <ReportParameters
                parameters={parameters}
                setParameters={setParameters}
                templates={reportTemplatesResult.data}
              />
            </Box>
          </PaperBox>
          <StyledReportResultBox>
            <ReportResult
              parameters={parameters}
              reportType={reportType}
            />
          </StyledReportResultBox>
        </>
        ) : (
          <PaperBox>
            <Box>Du har inga rapporter att läsa</Box>
          </PaperBox>
        )
      )}
    </div>
  )
}