import {
  faArrowUpRightFromSquare,
  faCalendarDays,
  faIdCardClip,
  faLongArrowRight,
  faMapLocationDot
} from "@fortawesome/pro-solid-svg-icons"
import { Alert } from "@mui/lab"
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Grow,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApiGet, useApiPost } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import Button from "components/Button"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import CrisisButton from "components/navigation/CrisisButton"
import ResponsiveAccordion from "components/ResponsiveAccordion/ResponsiveAccordion"
import { ScreenSize } from "components/ResponsiveAccordion/ResponsiveAccordion.interface"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import moment from "moment"
import { ActionCardLoading } from "pages/planning/components/ActionCardLoading"

import { AppContext } from "components/AppContextProvider"
import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { allRoutes } from "generated/routes"
import LearningAvailableExams from "./components/Learning/AvailableExams"
import News from "./components/News"
import Planning from "./components/Planning"
import Report from "./components/Report"
import { ReportSkeleton } from "./components/Report/components/ReportSkeleton"
import { customCss, customCssLearning } from "./components/styles/customAccordionStyles"
import Welcome from "./components/Welcome"
import AnonymousUserMessage from "./components/Welcome/AnonymousUserMessage"
import { StyledAlert } from "./components/Welcome/StyledAlert.styled"

const DisabledUserMessage = () => {
  const { t } = useTranslation()

  return (
    <Grid item xs={12}>
      <Alert severity="error">
        {t(
          "dashboard.account_inactive",
          "Ditt konto är inaktiverat i Transportportalen. Kontaka administratör för åtkomst."
        )}
      </Alert>
    </Grid>
  )
}

const AssignmentButton = ({ currentAssignmentResult, toggleDialogAssignment }) => {
  const theme = useTheme()
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"))
  const { t } = useTranslation()

  if (currentAssignmentResult?.data !== null || currentAssignmentResult?.data !== "") {
    if (currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad) {
      return (
        <Button fullWidth={isLgDown} onClick={toggleDialogAssignment} color="primary" variant="contained">
          {t("planning.confirm", "Bekräfta")}
        </Button>
      )
    } else if (currentAssignmentResult.data?.canBekrafta && currentAssignmentResult.data?.bekraftad) {
      return (
        <Button fullWidth={isLgDown} onClick={toggleDialogAssignment} color="error" variant="contained">
          {t("planning.undo_confirmed", "Ångra bekräftad")}
        </Button>
      )
    } else {
      return (
        <>
          {currentAssignmentResult.data.bekraftad && (
            <Button fullWidth={isLgDown} color="primary" disabled variant="contained">
              {t("planning.confirmed", "Bekräftad")}
            </Button>
          )}
          {!currentAssignmentResult.data?.canBekrafta == null &&
            !currentAssignmentResult.data.canBekrafta &&
            !currentAssignmentResult.data.bekraftad && (
              <Typography variant="bodySmall" fontWeight="500">
                {t(
                  "planning.assignment_expired_to_confirm",
                  "Ditt uppdrag har paserat tiden för att bli bekräftat"
                )}
              </Typography>
            )}
          {currentAssignmentResult.data?.canBekrafta == null && (
            <Typography variant="bodySmall" fontWeight="500">
              {t("planning.no_active_assignment", "Du har inget aktivt uppdrag just nu")}
            </Typography>
          )}
        </>
      )
    }
  }
  return null
}

const Dashboard = () => {
  const { t } = useTranslation()

  const appContext = useContext(AppContext)
  const theme = useTheme()
  const loadingContext = useContext(GlobalLoaderContext)
  const [currentAssignmentResult, getCurrentAssignment] = useApiGet()
  const [timereportResult, getTimereport] = useApiGet()
  const [assignmentResult, getAssignment] = useApiGet()
  const [updateAssignmentResult, acceptAssignmentFunc] = useApiPost()
  const [updateTimeReportResult, acceptTimeReportFunc] = useApiPost()
  const [showNewsDialog, setShowNewsDialog] = useState(false)
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"))
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"))
  const newsSectionRef = useRef(null)
  const [newsResult, newsApi] = useApi()
  const [activeCasesResult, getActiveCases] = useApi()
  const [myExamsResult, myExamsApi] = useApi()

  const scrollToNewsSection = () => {
    if (newsSectionRef.current) {
      newsSectionRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    newsApi.get(apiEndpoints.news.getnewslatest)
    return () => {
      newsApi.leave()
    }
  }, [newsApi])

  useEffect(() => {
    const hasPriorityNews = newsResult?.data?.results?.find((news) => news.priority === true)
    if (hasPriorityNews) {
      setShowNewsDialog(true)
    } else {
      const hasRecentNews = newsResult?.data?.results?.some((news) =>
        isNewsCreatedWithin24Hours(news.publishedDate)
      )
      if (hasRecentNews) {
        setShowNewsDialog(true)
      }
    }
  }, [newsResult])

  const isNewsCreatedWithin24Hours = (newsDate) => {
    return moment().diff(moment(newsDate), "hours") <= 23
  }

  const [updatedAssignment, setUpdatedAssignment] = useState()

  const [updatedTimeReport, setUpdatedTimeReport] = useState()

  useEffect(() => {
    getActiveCases.get(apiEndpoints.dashboard.getdriverongoingcases)
  }, [])

  console.log(activeCasesResult)

  useEffect(() => {
    hasRole(appContext.appUser, allRoles.Planning) &&
      getCurrentAssignment(apiEndpoints.planning.getcurrentassignment)
  }, [appContext.appUser])

  useEffect(() => {
    hasRole(appContext.appUser, allRoles.Planning) &&
      getTimereport(apiEndpoints.planning.getcurrenttimereport)
  }, [appContext.appUser])

  useEffect(() => {
    loadingContext.setLoading(() => assignmentResult.status === 1)
    loadingContext.setLoading(() => currentAssignmentResult.status === 1)
  })

  useEffect(() => {
    if (updateTimeReportResult.status === 2) {
      hasRole(appContext.appUser, allRoles.Planning) &&
        getTimereport(apiEndpoints.planning.getcurrenttimereport)
      toggleDialogTimeReport()
    }
  }, [updateTimeReportResult.status])

  useEffect(() => {
    if (updateAssignmentResult.status === 2) {
      hasRole(appContext.appUser, allRoles.Planning) &&
        getCurrentAssignment(apiEndpoints.planning.getcurrentassignment)
      toggleDialogAssignment()
    }
  }, [updateAssignmentResult.status])

  useEffect(() => {
    setUpdatedAssignment(moment().format("YYYY-MM-DD HH:mm"))
  }, [currentAssignmentResult])

  useEffect(() => {
    setUpdatedTimeReport(moment().format("YYYY-MM-DD HH:mm"))
  }, [timereportResult])

  useEffect(() => {
    myExamsApi.get(apiEndpoints.learning.getassignedexaminations)

    return () => {
      myExamsApi.leave()
    }
  }, [myExamsApi])

  const acceptAssignment = () => {
    const values = {
      assignmentId: currentAssignmentResult?.data?.passStartSchemapostId,
      undo: currentAssignmentResult?.data?.bekraftad
    }
    acceptAssignmentFunc(apiEndpoints.planning.acceptassignment, values)
  }

  const [dialogAssignmentOpen, setDialogAssignmentOpen] = useState(false)

  const toggleDialogAssignment = () => {
    setDialogAssignmentOpen(!dialogAssignmentOpen)
  }

  const acceptTimeReport = () => {
    const values = {
      timeReportId: timereportResult?.data?.id,
      undo: timereportResult?.data?.isSubmitted
    }
    acceptTimeReportFunc(apiEndpoints.planning.accepttidrapport, values)
  }

  const [dialogTimeReportOpen, setDialogTimeReportOpen] = useState(false)

  const toggleDialogTimeReport = () => {
    setDialogTimeReportOpen(!dialogTimeReportOpen)
  }

  const currentAssignmentData = [
    {
      title: t("planning.date", "Datum"),
      data: moment(currentAssignmentResult?.data?.passStarttid).format("YYYY-MM-DD")
    },
    {
      title: t("planning.start_time", "Tid"),
      data: currentAssignmentResult?.data?.starttidsträng ? currentAssignmentResult.data.starttidsträng : null
    },
    {
      title: t("planning.service", "Tjänst"),
      data: currentAssignmentResult?.data?.beskrivning
    },
    {
      title: t("planning.vehicle", "Fordon"),
      data: currentAssignmentResult?.data?.fordonStr
    }
  ]

  const currentAssignmentLinks = [
    {
      showButton: true,
      iconStart: faIdCardClip,
      iconEnd: faArrowUpRightFromSquare,
      bigButton: true,
      label: t("planning.driver_board", "Förartavla"),
      to: "client/forartavla"
    },
    {
      showButton: appContext.appUser.customer.id === 33,
      iconStart: faMapLocationDot,
      iconEnd: faLongArrowRight,
      bigButton: true,
      label: t("planning.busmaps", "Busskartor"),
      link: allRoutes.DocumentsDetails(52)
    },
    {
      showButton: true,
      iconStart: faCalendarDays,
      iconEnd: faLongArrowRight,
      bigButton: true,
      label: t("planning.schedule", "Schema"),
      link: allRoutes.Planning()
    }
  ]

  const relevantExams = myExamsResult?.data?.filter((exam) => !exam.examinationSummary.hasPassed)
  const userHasExams =
    hasRole(appContext.appUser, allRoles.Learning) &&
    myExamsResult?.data?.length > 0 &&
    relevantExams?.length > 0

  return (
    <Box>
      {showNewsDialog && isPhone && (
        <Grow in={showNewsDialog}>
          <Grid container mt={4} px={2}>
            <Grid item xs={12} md={6} xl={4}>
              <StyledAlert
                icon={<FontAwesomeIcon color={theme.palette.primary["main"]} icon={faInfoCircle} />}
                onClose={() => setShowNewsDialog(false)}
                severity="info">
                <Box>
                  <Typography>
                    {t("dashboard.there_is_news_to_read", "Det finns nyheter att läsa!")}
                  </Typography>
                  <Button onClick={scrollToNewsSection} variant="text">
                    {t("dashboard.read_news", "Läs nyheter")}
                  </Button>
                </Box>
              </StyledAlert>
            </Grid>
          </Grid>
        </Grow>
      )}
      <Container>
        {appContext.appUser.profile.disabled ? (
          <DisabledUserMessage />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              gap: {
                xs: "16px",
                lg: "60px"
              }
            }}>
            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <CrisisButton />
                </Grid>
              </Hidden>
              <Grid item sm={9}>
                {appContext.appUser.isAnonymous ? <AnonymousUserMessage /> : <Welcome />}
              </Grid>
            </Grid>
            {hasRole(appContext.appUser, allRoles.Planning) && (
              <Planning
                currentAssignmentResult={currentAssignmentResult}
                currentAssignmentData={currentAssignmentData}
                currentAssignmentLinks={currentAssignmentLinks}
                timereportResult={timereportResult}
                toggleDialogAssignment={toggleDialogAssignment}
                toggleDialogTimeReport={toggleDialogTimeReport}
                AssignmentButton={AssignmentButton}
              />
            )}

            <Grid container direction="row" spacing={2} ref={newsSectionRef}>
              <Grid item xs={12} md={12} lg={8}>
                <Hidden lgDown>
                  <Box pb={3}>
                    <Typography variant="h1"> {t("global.titles.news")}</Typography>
                  </Box>
                </Hidden>
                {newsResult?.status === 2 ? (
                  <ResponsiveAccordion
                    screenSize={ScreenSize.LG}
                    title={t("global.titles.news", "Nyheter")}
                    customCss={customCss}
                    stats={[
                      {
                        name: t("dashboard.new", "Nya"),
                        value:
                          newsResult?.data?.results.filter((news) =>
                            isNewsCreatedWithin24Hours(news.publishedDate)
                          ).length || 0
                      }
                    ]}>
                    <News newsResult={newsResult} />
                  </ResponsiveAccordion>
                ) : (
                  <ActionCardLoading
                    sx={{ padding: isLgDown ? "12px 24px" : "24px", margin: "0px" }}
                    hasAccordionWrapper
                  />
                )}
              </Grid>
              {hasRole(appContext.appUser, allRoles.IssueReports) ||
              hasRole(appContext.appUser, allRoles.Violation) ||
              hasRole(appContext.appUser, allRoles.Deviation) ? (
                <>
                  <Grid item xs={12} lg={4}>
                    <Hidden lgDown>
                      <Grid item pb={3} xs={12}>
                        <Typography variant="h1">{t("dashboard.report", "Rapportera")}</Typography>
                      </Grid>
                    </Hidden>
                    {activeCasesResult.status === 2 ? (
                      <ResponsiveAccordion
                        screenSize={ScreenSize.LG}
                        title={t("dashboard.report", "Rapportera")}
                        customCss={customCss}
                        stats={[
                          {
                            name: t("global.features.title.issuereports", "Felrapporter"),
                            value: activeCasesResult?.data?.issueReports
                          },
                          {
                            name: t("settings.modules.deviations", "Avvikelser"),
                            value: activeCasesResult?.data?.deviations
                          },
                          {
                            name: t("settings.user.modules.violation", "Överträdelser"),
                            value: activeCasesResult?.data?.violations
                          }
                        ]}>
                        <Report activeCasesResult={activeCasesResult} />
                      </ResponsiveAccordion>
                    ) : (
                      <ReportSkeleton
                        sx={{ padding: isLgDown ? "12px 24px" : "24px", margin: "0px" }}
                        hasAccordionWrapper
                      />
                    )}
                  </Grid>
                </>
              ) : null}
            </Grid>
            <Grid container direction="row" spacing={2} pb={2}>
              {userHasExams && (
                <Grid item xs={12} lg={12} xl={8}>
                  <ResponsiveAccordion
                    screenSize={ScreenSize.LG}
                    title={t("global.titles.myCourses")}
                    customCss={customCssLearning}
                    stats={[
                      {
                        name: t("global.titles.myCourses", "Aktuella"),
                        value: relevantExams?.length
                      }
                    ]}>
                    <LearningAvailableExams myExamsResult={myExamsResult} />
                  </ResponsiveAccordion>
                </Grid>
              )}
            </Grid>
          </Box>
        )}

        <Dialog
          open={dialogTimeReportOpen}
          onClose={toggleDialogTimeReport}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
              ? t("planning.do_you_want_to_submit", "Vill du lämna in tidrapport?")
              : t("planning.are_you_sure_reset_timereport", "Är du säker att du vill återställa tidrapport?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                ? t("submit_for_approval", "Vill du lämna in tidrapporten för godkännande?")
                : t("planning.reset_to_active", "Vill du återställa tidrapporten till aktiv status?")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogTimeReport} variant="outlined">
              {t("global.buttons.cancel", "Avbryt")}
            </Button>
            <Button result={updateTimeReportResult} onClick={acceptTimeReport} color="primary" autoFocus>
              {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                ? t("planning.submit_timereport", "Lämna in tidrapport")
                : t("undo_timereport", "Ångra tidrapport")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogAssignmentOpen}
          onClose={toggleDialogAssignment}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
              ? t("planning.confirm_assignment", "Bekräfta uppdrag")
              : t("planning.reset_timereport", "Återställ uppdrag")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
                ? t("do_you_want_to_confirm_assignment", "Vill du bekräfta uppdrag?")
                : t("do_you_want_to_reset_assignment", "Vill du återställa uppdrag?")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogAssignment} variant="outlined">
              {t("global.buttons.cancel", "Avbryt")}
            </Button>
            <Button result={updateAssignmentResult} onClick={acceptAssignment} color="primary" autoFocus>
              {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
                ? t("planning.confirm", "Bekräfta")
                : t("undo_submit", "Ångra bekräfta")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  )
}
export default Dashboard
