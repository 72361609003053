import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton, List } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MapContext } from "../../MapContextProvider";
import DeviceListItem from "./DeviceListItem";
import FilterSearch from "./FilterSearch";

import { useStyles } from "./styles";

const DeviceList = ({ devices }) => {
    const classes = useStyles();

    const { toggleDeviceBar } = useContext(MapContext);
    const [sortedDevices, setSortedDevices] = useState(devices);
    const [isSorted, setIsSorted] = useState(false);

    const showByFilter = (filterStatus) => {
        const filteredDevices =
            filterStatus === 0 ? devices : devices.filter((device) => (filterStatus === 3 ? device.status === 3 : device.status === 2 || device.status === 1));
        setSortedDevices(filteredDevices);
    };

    const searchByString = (str) => {
        if (str.length >= 3) {
            const searchedDevices = devices.filter((device) => {
                let found = false;
                Object.keys(device).forEach((key) => {
                    if (device[key] && device[key].indexOf && device[key].indexOf(str.toUpperCase()) > -1) {
                        found = true;
                    }
                });
                return found;
            });

            setSortedDevices(searchedDevices);
        }
    };

    // small fix to se inital devices without changing it everytime we fetch new data every 5000ms.
    useEffect(() => {
        if (!isSorted) {
            setSortedDevices(devices.sort((a, b) => a?.internalDeviceId.localeCompare(b?.internalDeviceId)));
        }
    }, [devices, setIsSorted, isSorted]);

    return (
        <List
            subheader={
                <>
                    <div className={classes.listHeader}>
                        <IconButton onClick={toggleDeviceBar} aria-label="delete">
                            <ArrowForwardIosIcon />
                        </IconButton>
                        <h4>{"Filtrera"}</h4>
                        <div></div>
                        {/* <SettingsIcon /> */}
                    </div>
                    {<FilterSearch devices={devices} showItemByFilter={showByFilter} search={searchByString} />}
                </>
            }
        >
            {sortedDevices.map((device) => {
                return <DeviceListItem key={device.id} device={device} />;
            })}
        </List>
    );
};
export default DeviceList;
