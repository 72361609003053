import { faBan, faCarOn, faUpload, faWrench } from "@fortawesome/pro-solid-svg-icons"
import { Box, Container, Grid, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"

import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import { useContext, useEffect } from "react"
import { DashboardCard } from "./DashboardCard"
import NoDataCard from "./DashboardNoDataCard"
import { DashboardQuickLinks } from "./DashboardQuickLinks"
const Dashboard = () => {
  const loadingContext = useContext(GlobalLoaderContext)

  const [dashboardResult, dashboardFuncs] = useApi([])
  const [dashboardVtrResult, dashboardVtrFuncs] = useApi([])
  const appContext = useContext(AppContext)
  const isVehicleRegistryAdmin = hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)

  useEffect(() => {
    dashboardFuncs.get(apiEndpoints.vehicleregistry.getdashboard)
    dashboardVtrFuncs.get(apiEndpoints.vehicleregistry.getvtrfordashboard)
    return () => {
      dashboardFuncs.leave()
      dashboardVtrFuncs.leave()
    }
  }, [dashboardFuncs, dashboardVtrFuncs])

  useEffect(() => {
    loadingContext.setLoading(() => dashboardResult.status === 1)
  })

  return (
    <>
      <Container>
        <Grid
          spacing={2}
          container
          direction="row"
          justifyContent="spaceBetween"
          sx={{ display: { xs: "block", md: "flex" } }}>
          <Grid item md={8} lg={9} direction="column">
            <Box mb={2}>
              <Typography variant="h4">Dina fordon</Typography>
            </Box>
            <>
              {dashboardResult.status === 2 && dashboardResult.data.totalVehicles < 0 ? <NoDataCard /> : null}
              <Grid container direction="row" spacing={2}>
                <DashboardCard
                  icon={faUpload}
                  route={allRoutes.VehicleregistryVehicles}
                  itemLength={dashboardResult.data?.totalVehicles}
                  title="Upplagda fordon"
                  quantityType="st"
                  status={dashboardResult.status}
                />

                <>
                  <DashboardCard
                    icon={faBan}
                    route={`${allRoutes.VehicleregistryVehicles()}?brukandeförbud=Ja`}
                    itemLength={dashboardResult.data?.totalKorforbud}
                    title="Körförbud"
                    quantityType="st"
                    status={dashboardResult.status}
                  />
                  <DashboardCard
                    icon={faWrench}
                    route={`${allRoutes.VehicleregistryVehicles()}?besiktningInom14Dagar=Ja`}
                    itemLength={dashboardResult.data?.totalBesiktningInom14Dagar}
                    title="Besiktning inom 14 dagar"
                    quantityType="st"
                    status={dashboardResult.status}
                  />
                </>
                {isVehicleRegistryAdmin && (
                  <DashboardCard
                    img="assets/Transportstyrelsen-icon.jpg"
                    route={allRoutes.VehicleregistryVehiclesImportvtr()}
                    itemLength={dashboardVtrResult.data}
                    title="Importera från Transportstyrelsen"
                    quantityType="st"
                    status={dashboardVtrResult.status}
                  />
                )}
                <DashboardCard
                  icon={faCarOn}
                  route={allRoutes.IssuereportsArchive()}
                  itemLength={dashboardResult.data?.totalIssueReports}
                  title="Felrapporter"
                  quantityType="st"
                  status={dashboardResult.status}
                />
              </Grid>
            </>
          </Grid>
          <Grid item md={4} lg={3}>
            <Box mb={2}>
              <Typography variant="h4">Snabblänkar</Typography>
            </Box>
            <PaperBox mt={0}>
              <DashboardQuickLinks />
            </PaperBox>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
export default Dashboard
