import { Grid, useMediaQuery, useTheme } from "@mui/material"
import PaperBox from "components/PaperBox"

import LoadingBox from "components/skeleton/LoadingBox"
import LoadingTypography from "components/skeleton/LoadingTypography"

const numberOfLoadingTypography = [1, 2, 3, 4]

export const ReportSkeleton = (props) => {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down("lg"))
  const { hasAccordionWrapper } = props

  return (
    <PaperBox {...props}>
      {hasAccordionWrapper && isPhone ? (
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={12}>
            <LoadingTypography variant="bodySmall" />
            <LoadingTypography variant="bodySmall" />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3} lg={6} xl={4}>
              <LoadingBox width="100%" height="125px" />
            </Grid>
            <Grid item xs={6} md={3} lg={6} xl={4}>
              <LoadingBox width="100%" height="125px" />
            </Grid>
            <Grid item xs={6} md={3} lg={6} xl={4}>
              <LoadingBox width="100%" height="125px" />
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Grid item xs={12}>
              {numberOfLoadingTypography.map((index) => (
                <Grid mb={1} item xs={12} key={index}>
                  <LoadingBox width="100%" height="50px" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </PaperBox>
  )
}
