import { Grid, Typography } from "@mui/material"

const ActionCardDetailText = ({ data, title }) => {
  return (
    <Grid container row alignItems="center" spacing={3}>
      <Grid item sx={{ width: "150px" }}>
        <Typography color="textSecondary" variant="labelSmall">
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary" fontWeight="500" variant="labelMedium">
          {data ? data : "-"}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ActionCardDetailText
