import React, { useContext, useEffect, useState } from "react"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment/moment"
import { useApi } from "auth/useApi3"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import { Autocomplete, Grid, MenuItem, Select, TextField } from "@mui/material"
import { Parameters, ParametersState, TemplateAndParameters } from "../interfaces/reports.interfaces"

const ReportSelector = ({ templates, parameters, setParameters }: TemplateAndParameters) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState(parameters.selectedTemplate && parameters.selectedTemplate.id)

  useEffect(() => {
    if (templates && templates.length > 0) {
      setSelectedTemplateId(templates[0].id)
    }
  }, [templates])

  return (
    <Select
      variant="outlined"
      labelId="sortby-label"
      id="selectedReportTemplate"
      value={selectedTemplateId}
      onChange={(event) => {
        const value = event.target.value
        if (typeof value === "string") {
          setSelectedTemplateId(parseInt(value))
        } else {
          setSelectedTemplateId(value)
        }
        const selectedTemplate = templates.filter((template) => template.id === value)[0]

        setParameters((prevState: Parameters) => {
          return {
            ...prevState,
            selectedTemplate: selectedTemplate,
            showDateRange: value != null && value > 0 ? selectedTemplate.requireDate : ""
          }
        })
      }}>
      {templates &&
        templates.map((item) => {
          return <MenuItem value={item.id}>{item.name}</MenuItem>
        })}
    </Select>
  )
}

const DateFrom = ({ parameters, setParameters }: ParametersState) => {
  return (
    <DatePicker
      label="Från datum"
      value={parameters.dateFrom ? moment(parameters.dateFrom) : null}
      onChange={(value) => {
        setParameters((prevState) => {
          return {
            ...prevState,
            dateFrom: value?.toDate()
          }
        })
      }}
      format="YYYY-MM-DD"
      slotProps={{
        textField: (params) => ({
          ...params
        })
      }}
    />
  )
}

const DateTo = ({ parameters, setParameters }: ParametersState) => {
  return (
    <DatePicker
      label="Till datum"
      value={parameters.dateTo ? moment(parameters.dateTo) : null}
      onChange={(value) => {
        setParameters((prevState) => {
          return {
            ...prevState,
            dateTo: value?.toDate()
          }
        })
      }}
      format="YYYY-MM-DD"
      slotProps={{
        textField: (params) => ({
          ...params
        })
      }}
    />
  )
}
const FilterSubTable = ({ parameters, setParameters }: ParametersState) => {
  const [contractDataResult, contractDataApi] = useApi() as [IApiResult, IApiFunc]
  const loadingContext = useContext(GlobalLoaderContext)
  const [name, setValue] = useState()
  const [subContract, setSubContractValue] = useState()
  const [subContractsResult, subContractDataApi] = useApi() as [IApiResult, IApiFunc]

  useEffect(() => {
    if (
      parameters.selectedTemplate != null &&
      parameters.selectedTemplate.id === 3 &&
      contractDataResult.status !== 2
    ) {
      contractDataApi.get(apiEndpoints.reporting.getcontractlistdistinct)

      subContractDataApi.get(apiEndpoints.reporting.getsubcontractlistdistinct)
    }
    return () => {
      contractDataApi.leave()
    }
  }, [contractDataApi, subContractDataApi, parameters])

  useEffect(() => {
    loadingContext.setLoading(() => contractDataResult.status === 1)
  })

  return (
    <>
      <Grid item>
        <Autocomplete
          id="selectedReportTemplate"
          options={contractDataResult.status === 2 && contractDataResult.data}
          getOptionLabel={(option) => (option?.name ? option.name : "")}
          value={contractDataResult.status === 2 ? contractDataResult.data.find((i: any) => i.name === name) : ""}
          onChange={(e, selectedValue) => {
            setValue(selectedValue?.name)
            const selectedName = {
              ...parameters.filter,
              name: selectedValue?.name
            }

            setParameters((prevState) => {
              return {
                ...prevState,
                filter: selectedName
              }
            })
          }}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Kunder" variant="outlined" />}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id="subContracts"
          options={subContractsResult.status === 2 && subContractsResult.data}
          getOptionLabel={(option) => (option?.subContract ? option.subContract : "")}
          value={
            subContractsResult.status === 2
              ? subContractsResult.data.find((i: any) => i.subContract === subContract)
              : ""
          }
          onChange={(e, selectedValue) => {
            setSubContractValue(selectedValue?.subContract)
            const selectedSubContract = {
              ...parameters.filter,
              subcontract: selectedValue?.subContract
            }

            setParameters((prevState) => {
              return {
                ...prevState,
                filter: selectedSubContract
              }
            })
          }}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Underavtal" variant="outlined" />}
        />
      </Grid>
    </>
  )
}
export const ReportParameters = ({ parameters, setParameters, templates }: TemplateAndParameters) => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item>
        <ReportSelector templates={templates} parameters={parameters} setParameters={setParameters} />
      </Grid>
      {parameters.selectedTemplate && parameters.selectedTemplate.id === 3 && (
        <FilterSubTable
          parameters={parameters}
          setParameters={setParameters}
        />
      )}
      {parameters.selectedTemplate && parameters.selectedTemplate.requireDate ? (
        <>
          <Grid item>
            <DateFrom parameters={parameters} setParameters={setParameters} />
          </Grid>
          <Grid item>
            <DateTo parameters={parameters} setParameters={setParameters} />
          </Grid>
        </>
      ) : null}
    </Grid>
  )
}