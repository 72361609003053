import Button from "components/Button"
import { useContext, useEffect, useRef, useState } from "react"
import { useStyles } from "./useStyles"

import { faBusAlt } from "@fortawesome/pro-solid-svg-icons"
import {
  Autocomplete,
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField
} from "@mui/material"

import { RouterPrompt } from "components/RouterPrompt"
import { Formik } from "formik"

import { AppContext } from "components/AppContextProvider"
import CustomFieldInput from "components/CustomFieldInput"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import SidebarHeader from "components/sidedrawer/SidebarHeader"
import SidebarInputLabel from "components/sidedrawer/SidebarInputLabel"
import * as Yup from "yup"
import { apiEndpoints } from "generated/apiEndpoints"
import { useApi } from "auth/useApi3"

const yupField = (customField) => {
  switch (customField.type) {
    case "Boolean":
      return customField.isRequired ? Yup.bool().required(customField.name + " är obligatoriskt") : Yup.bool()
    case "Date":
      return customField.isRequired
        ? Yup.date()
            .typeError("Du måste ange ett giltigt datumformat YYYY-MM-DD")
            .required(customField.name + " är obligatoriskt")
        : Yup.date().nullable().typeError("Du måste ange ett giltigt datumformat YYYY-MM-DD")
    case "Numeric":
      return customField.isRequired
        ? Yup.number().required(customField.name + " är obligatoriskt")
        : Yup.number()
    default:
      return customField.isRequired
        ? Yup.string().required(customField.name + " är obligatoriskt")
        : Yup.string()
  }
}

export const VehicleForm = ({
  toggleMenu,
  vehicleData,
  customFieldsData,
  onSubmit,
  editMode,
  addOrUpdateResultState,
  mainPropellantResult,
  feedback,
  showSidebar
}) => {
  const appContext = useContext(AppContext)
  const companies = appContext.appUser.customer.companies
  const showEnvironmentReportFields =
    appContext.appUser.customer.customerHasNonEditableReportsInVehicleRegistry

  const initialValues = editMode
    ? { ...vehicleData, customFieldValues: customFieldsData }
    : {
        companyId: null,
        regNumber: "",
        internnumber: "",
        vehicleType: "",
        basicFuelConsumptionKm: 0,
        averageSpeed: 0,
        mainPropellantId: null,
        tachographIntegrationActive: false,
        archived: false,
        customFieldValues: customFieldsData
      }

  const classes = useStyles()
  const formRef = useRef()

  const handleSubmit = (values) => {
    onSubmit(values)
  }

  const [vehicleValidationSchema, setvehicleValidationSchema] = useState(null)

  let defaultSchema = {
    regNumber: Yup.string()
      .min(6, "Registreringsnummer måste vara minst 6 tecken långt")
      .max(10, "Registreringsnummer kan max vara 10 tecken långt"),
    internnumber: Yup.string().required("Internnummer är obligatoriskt"),
    companyId: Yup.number().nullable().required("Bolag är obligatoriskt"),
    vehicleType: Yup.string().nullable(),
    basicFuelConsumptionKm: Yup.number(),
    averageSpeed: Yup.number(),
    mainPropellantId: Yup.number().nullable(),
    tachographIntegrationActive: Yup.bool(),
    archived: Yup.bool(),
    meterSetting: Yup.number().required("Mätarställning är obligatoriskt")
  }

  useEffect(() => {
    customFieldsData.map((cf) => {
      const validationObj = yupField(cf)
      return (defaultSchema = {
        ...defaultSchema,
        ["customField" + cf.customFieldId]: validationObj
      })
    })
    setvehicleValidationSchema(Yup.object().shape(defaultSchema))
  }, [customFieldsData])

  const [dateFields, getDateFields] = useApi()
  useEffect(() => getDateFields.get(apiEndpoints.vehicleregistryadmin.getdatetimefields), [])

  return (
    vehicleValidationSchema && (
      <>
        <Formik
          validationSchema={vehicleValidationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
          innerRef={formRef}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form id="VehicleList" onSubmit={handleSubmit} noValidate>
              {showSidebar && <RouterPrompt />}

              <List className={classes.vehicleList}>
                <CloseSidebarButton toggleMenu={toggleMenu} />
                <SidebarHeader
                  color="primary"
                  size="lg"
                  icon={faBusAlt}
                  title={values.regNumber ? values.regNumber : "Lägg till fordon"}
                  subtitle={values.internnumber ? values.internnumber : "Fordonsregister"}
                />

                <List sx={{ padding: "40px 0 0 0" }}>
                  <ListItem className={classes.listItem}>
                    <SidebarInputLabel labelText="Internnummer *" />
                    <TextField
                      className={classes.textField}
                      autoComplete="off"
                      fullWidth
                      id="internnumber"
                      value={values.internnumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.internnumber && errors.internnumber ? true : false}
                      helperText={touched.internnumber && errors.internnumber ? errors.internnumber : null}
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.formAreaInput },
                        disableUnderline: true
                      }}
                    />
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <SidebarInputLabel labelText="Registeringsnummer" />

                    <TextField
                      autoComplete="off"
                      className={classes.textField}
                      fullWidth
                      id="regNumber"
                      value={values.regNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.regNumber && errors.regNumber ? true : false}
                      helperText={touched.regNumber && errors.regNumber ? errors.regNumber : null}
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.formAreaInput },
                        disableUnderline: true
                      }}
                    />
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <SidebarInputLabel labelText="Fordonstyp" />

                    <TextField
                      autoComplete="off"
                      fullWidth
                      className={classes.textField}
                      id="vehicleType"
                      value={values.vehicleType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.formAreaInput },
                        disableUnderline: true
                      }}
                    />
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <SidebarInputLabel labelText="Bolag *" />
                    <Autocomplete
                      className={classes.fullWidth}
                      id="companyId"
                      options={companies}
                      getOptionLabel={(option) => (option.name ? option.name : "")}
                      value={companies.find((i) => i.id === values.companyId) || null}
                      getOptionSelected={(option, value) => {
                        return option.id === value.id
                      }}
                      onChange={(e, value) => {
                        setFieldValue("companyId", value?.id)
                      }}
                      renderInput={(params) => (
                        <TextField
                          className={classes.dropdown}
                          {...params}
                          variant="outlined"
                          name="companyId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.companyId && errors.companyId ? true : false}
                          helperText={touched.companyId && errors.companyId ? errors.companyId : null}
                        />
                      )}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <SidebarInputLabel labelText="Mätarställning *" />
                    <TextField
                      autoComplete="off"
                      InputProps={{
                        classes: { input: classes.formAreaInput },
                        disableUnderline: true,
                        InputProps: { min: 0 }
                      }}
                      className={classes.textField}
                      fullWidth
                      id="meterSetting"
                      value={values.meterSetting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.meterSetting && errors.meterSetting ? true : false}
                      helperText={touched.meterSetting && errors.meterSetting ? errors.meterSetting : null}
                      variant="outlined"
                      type="number"
                    />
                  </ListItem>
                  {showEnvironmentReportFields && (
                    <>
                      <ListItem className={classes.listItem}>
                        <SidebarInputLabel labelText="Drivmedel" />
                        {mainPropellantResult.status === 2 && (
                          <Autocomplete
                            className={classes.fullWidth}
                            id="mainPropellantId-autocomplete"
                            options={mainPropellantResult?.data}
                            getOptionLabel={(option) => (option.name ? option.name : "")}
                            value={
                              mainPropellantResult.data.find((i) => i.id === values.mainPropellantId) || null
                            }
                            onChange={(e, value) => setFieldValue("mainPropellantId", value?.id || null)}
                            onOpen={handleBlur}
                            includeInputInList
                            renderInput={(params) => (
                              <TextField
                                className={classes.dropdown}
                                {...params}
                                error={touched.mainPropellantId && errors.mainPropellantId ? true : false}
                                helperText={
                                  touched.mainPropellantId && errors.mainPropellantId
                                    ? errors.mainPropellantId
                                    : null
                                }
                                name="mainPropellantId"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                      </ListItem>

                      <ListItem className={classes.listItem}>
                        <SidebarInputLabel labelText="Snittförbrukning km" />

                        <TextField
                          autoComplete="off"
                          className={classes.textField}
                          InputProps={{
                            classes: { input: classes.formAreaInput },
                            disableUnderline: true
                          }}
                          fullWidth
                          id="basicFuelConsumptionKm"
                          value={values.basicFuelConsumptionKm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          type="number"
                        />
                      </ListItem>

                      <ListItem className={classes.listItem}>
                        <SidebarInputLabel labelText="Snitthastighet km/h" />
                        <TextField
                          autoComplete="off"
                          InputProps={{
                            classes: { input: classes.formAreaInput },
                            disableUnderline: true
                          }}
                          className={classes.textField}
                          fullWidth
                          id="averageSpeed"
                          value={values.averageSpeed}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          type="number"
                        />
                      </ListItem>
                    </>
                  )}

                  <ListItem className={classes.listItem}>
                    <SidebarInputLabel labelText="Färdskrivare" />
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(item) => {
                            const checked = item.target.checked
                            setFieldValue("tachographIntegrationActive", checked)
                          }}
                          checked={values.tachographIntegrationActive}
                        />
                      }
                    />
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <SidebarInputLabel labelText="Inaktivera fordon" />
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(item) => {
                            const checked = item.target.checked
                            setFieldValue("archived", checked)
                          }}
                          checked={values.archived}
                        />
                      }
                    />
                  </ListItem>
                </List>

                <Box pt={3} pb={5}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    result={addOrUpdateResultState}
                    feedback={feedback}>
                    {editMode ? "Spara fordon" : "Lägg till fordon"}
                  </Button>
                </Box>
              </List>
            </form>
          )}
        </Formik>
      </>
    )
  )
}
