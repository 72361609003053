import { makeStyles } from "@mui/styles"
import { SignedOutPage } from "auth/SignedOutPage"
import { knownComponents } from "generated/knownRouteComponents"
import { useContext, useEffect, useState } from "react"
import { Route, Switch, useLocation } from "react-router-dom"
import Dashboard from "../pages/dashboard"
import { AppContext } from "./AppContextProvider"
import { RenderComponent } from "./RenderComponent"

const PagesRouter = (props) => {
  const appContext = useContext(AppContext)
  const location = useLocation()

  const [routes, setRoutes] = useState([])

  useEffect(() => {
    setRoutes(appContext.appUser.app.routes)
  }, [appContext])

  const useStyles = makeStyles((theme) => ({
    content: {
      flexGrow: 1,
      background: theme.palette.background.default,
      minHeight: "100vh"
    },
    root: {
      paddingTop: location.pathname === "/" || location.pathname === "/dashboard" ? "40px" : "80px",
      [theme.breakpoints.up("sm")]: {
        paddingTop: "48px",
        height: "100%",
        "& > div": {
          position: "relative",
          height: "100%"
        }
      }
    }
  }))

  const classes = useStyles()
  return (
    <main className={classes.content}>
      <div className={classes.root}>
        <Switch>
          <Route exact path="/">
            {appContext.isAuth ? <Dashboard /> : <SignedOutPage />}
          </Route>
          {routes.map((route, key) => {
            return (
              <Route
                exact
                {...props}
                key={key}
                path={route.path}
                render={(props) => {
                  return appContext.isAuth ? (
                    <RenderComponent
                      {...props}
                      name={route.componentName}
                      knownComponents={knownComponents}
                    />
                  ) : (
                    <SignedOutPage />
                  )
                }}></Route>
            )
          })}
        </Switch>
      </div>
    </main>
  )
}

export default PagesRouter
