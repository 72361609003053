import { Drawer } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledSidebarDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 1,
  ".MuiPaper-root": {
    backgroundColor: "#ffff",
    width: "450px",
    paddingBottom: "50px",
    marginTop: "54px",
    zIndex: 1100,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%"
    }
  }
}))