import { Button, Grid, Hidden, Typography, useMediaQuery, useTheme } from "@mui/material"
import ResponsiveAccordion from "components/ResponsiveAccordion/ResponsiveAccordion"
import moment from "moment"
import { customCssAssigment, customCssTimereport } from "../styles/customAccordionStyles"
import { useTranslation } from "react-i18next"
import { ScreenSize } from "components/ResponsiveAccordion/ResponsiveAccordion.interface"
import ActionCardDetails from "pages/planning/components/ActionCardDetails"
import ActionCardDetailText from "pages/planning/components/ActionCardDetailText"
import BplTokenLink from "components/BplTokenLink"
import { ActionCardLoading } from "pages/planning/components/ActionCardLoading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons"

const Planning = (props) => {
  const {
    currentAssignmentResult,
    currentAssignmentData,
    currentAssignmentLinks,
    timereportResult,
    toggleDialogAssignment,
    toggleDialogTimeReport,
    AssignmentButton
  } = props
  const theme = useTheme()
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"))
  const { t } = useTranslation()

  return (
    <Grid container direction="row" justifyContent="flex-start">
      <Hidden lgDown>
        <Grid item xs={12} pb={3}>
          <Typography variant="h1">{t("global.titles.planning", "Planering")}</Typography>
        </Grid>
      </Hidden>
      <Grid item md={12} xs={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item md={12} lg={8} xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            {currentAssignmentResult.status === 2 ? (
              <ResponsiveAccordion
                shouldExpand={
                  currentAssignmentResult?.data !== null &&
                  currentAssignmentResult?.data !== "" &&
                  currentAssignmentResult.data?.canBekrafta &&
                  !currentAssignmentResult.data?.bekraftad
                }
                stats={[
                  {
                    name: t("planning.date", "Datum"),
                    value: `${moment(currentAssignmentResult?.data?.passStarttid).format("YYYY-MM-DD")} ${t(
                      "planning.time",
                      "kl"
                    )} ${moment(currentAssignmentResult?.data?.passStarttid).format("HH:mm")}`
                  }
                ]}
                customCss={{
                  ...customCssAssigment,
                  backgroundColor: currentAssignmentResult.data?.bekraftad
                    ? theme.palette.success.light
                    : "#F1FCFF"
                }}
                screenSize={ScreenSize.LG}
                title={
                  currentAssignmentResult.data?.bekraftad
                    ? t("planning.confirm_assignment", "Bekräfta uppdrag")
                    : t("planning.current_assignment", "Aktuellt uppdrag")
                }>
                <ActionCardDetails
                  hasAccordionWrapper
                  columnDirection="row"
                  bgColor={currentAssignmentResult.data.bekraftad ? theme.palette.success.light : "#F1FCFF"}
                  title={
                    currentAssignmentResult.data?.bekraftad
                      ? t("planning.confirmed_assignment", "Bekräftat uppdrag")
                      : t("planning.current_assignment", "Aktuellt uppdrag")
                  }
                  details={
                    <Grid container direction="column" spacing={1}>
                      {currentAssignmentData?.map((item, index) => (
                        <Grid item xs={12} md={12} ml={1}>
                          <ActionCardDetailText key={index} title={item.title} data={item.data} />
                        </Grid>
                      ))}

                      <Grid item xs={12} mt={3}>
                        <AssignmentButton
                          currentAssignmentResult={currentAssignmentResult}
                          toggleDialogAssignment={toggleDialogAssignment}
                        />
                      </Grid>
                    </Grid>
                  }
                  actions={
                    <Grid container direction="column" spacing={1}>
                      {currentAssignmentLinks
                        .filter((link) => link.showButton)
                        .map((link, index) => (
                          <Grid item md={12} xs={12} key={index}>
                            <BplTokenLink {...link} />
                          </Grid>
                        ))}
                    </Grid>
                  }
                />
              </ResponsiveAccordion>
            ) : (
              <ActionCardLoading
                sx={{ padding: isLgDown ? "12px 24px" : "24px", margin: "0px" }}
                hasAccordionWrapper
              />
            )}
          </Grid>
          <Grid item md={12} lg={4} xs={12}>
            {timereportResult.status === 2 ? (
              <ResponsiveAccordion
                stats={[
                  {
                    name: t("planning.period_to", "Period (till)"),
                    value: timereportResult.data.period
                      ? moment(timereportResult.data.period).format("YYYY-MM-DD")
                      : "-"
                  }
                ]}
                customCss={customCssTimereport}
                screenSize={ScreenSize.LG}
                title={t("planning.timereport", "Tidrapport")}>
                <ActionCardDetails
                  hasAccordionWrapper
                  pb={
                    currentAssignmentResult?.data?.bekraftad !== undefined &&
                    currentAssignmentResult?.data?.bekraftad !== null
                      ? 10
                      : 7
                  }
                  columnDirection="column"
                  title={t("planning.timereport", "Tidrapport")}
                  details={
                    <Grid container direction="column" pl={1} spacing={1}>
                      <Grid item>
                        <ActionCardDetailText
                          title={t("planning.number_of_shifts", "Antal pass")}
                          data={
                            timereportResult.data.passCount
                              ? `${timereportResult.data.passCount} ${t("planning.pc", "st")}`
                              : `0 ${t("planning.pc", "st")}`
                          }
                        />
                      </Grid>
                      <Grid item>
                        <ActionCardDetailText
                          title={t("planning.period_to", "Period (till)")}
                          data={
                            timereportResult.data.period
                              ? moment(timereportResult.data.period).format("YYYY-MM-DD")
                              : "-"
                          }
                        />
                      </Grid>
                    </Grid>
                  }
                  actions={
                    <>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={3}>
                        <Grid item>
                          <BplTokenLink
                            label={t("planning.show_report", "Visa rapport")}
                            to="Client/Tidrapport/"
                            variant="link"
                            color="primary"
                            sx={{ color: theme.palette.primary.main }}
                          />
                        </Grid>
                        <Grid item>
                          {timereportResult &&
                          timereportResult.data &&
                          (!timereportResult.data?.isApproved || timereportResult.data?.isRevertable) ? (
                            <Button
                              disabled={timereportResult?.data?.passCount <= 0 && true}
                              onClick={toggleDialogTimeReport}
                              startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                              color="primary">
                              {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                                ? t("planning.submit", "Lämna in")
                                : t("planning.undo_submission", "Ångra inlämning")}
                            </Button>
                          ) : (
                            <Typography fontWeight={600}>{t("planning.submitted", "Inlämnad")}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  }
                />
              </ResponsiveAccordion>
            ) : (
              <ActionCardLoading
                sx={{ padding: isLgDown ? "12px 24px" : "24px", margin: "0px" }}
                hasAccordionWrapper
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Planning
