export enum ReportFieldCategoryEnum {
  Basinfo = 1,
  VTR_Grundinfo = 2,
  VTR_TekniskInfo = 3,
  Calculated = 4,
  CustomFields = 5
}

interface FieldItemDto {
  reportFieldId: number;
  id: number;
  name: string;
  description: string | null;
  checked: boolean;
  order: number;
  category: string;
}

export interface TemplateFieldGroupDto {
  title: string;
  reportFieldCategoryEnum: ReportFieldCategoryEnum;
  items: FieldItemDto[];
}

export interface ReportTemplateAuth {
  userId: number
  templateId?: number
  authorizationLevel: number
}

export interface TemplateFormDto {
  id: number;
  name: string;
  description: string;
  fieldGroups: TemplateFieldGroupDto[];
  templateAccessList: ReportTemplateAuth[];
}

export interface CheckedFilter extends FieldItemDto {
  groupIndex: number
  ReportFieldCategoryEnum: ReportFieldCategoryEnum
  index: number
  title: string
}

export interface AddTemplateFormikValues {
  name: string
  description: string
  items: CheckedFilter[]
}