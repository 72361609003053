import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons"
import { Box, Button, Grid, List, Tabs, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import DefaultButton from "components/Button"
import FormComponents from "components/Form/FormComponents/FormComponents"
import FormComponentsView from "components/Form/FormComponents/FormComponentsView"
import { IFormFieldProps } from "components/Form/FormField/formfield.interface"
import { getValidation } from "components/Form/FormTemplate/validation"
import PaperBox from "components/PaperBox"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { StyledFontAwesomeIconEdit, StyledIconButtonEdit } from "shared/vehicle/CustomFields/CustomFields.styled"
import * as Yup from "yup"
import { removeBrackets, removeDataField } from "helpers/CustomField/file"
import {
  CategoryTabsProps,
  UserCustomFieldDto,
  UserCustomFieldFormFieldDto,
  UserCustomFieldValuesProps,
  UserCustomFieldWithCategory
} from "./UserCustomField.interfaces"
import { StyledGridBorderGrey, StyledTabVertical } from "./UserCustomField.styled"

const CategoryTabs = ({ categoryTabValue, setCategoryTabValue, categories, formikProps, cachedValues }: CategoryTabsProps) => {
  const { errors, touched } = formikProps

  const countCategoryErrors = (category: string) => {
    return Object.keys(errors).filter(key => cachedValues[key]?.category === category && touched[key]).length
  }

  return (
    <StyledGridBorderGrey>
      <Tabs
        indicatorColor="primary"
        value={categoryTabValue}
        onChange={(_, newValue) => {
          setCategoryTabValue(newValue)
        }}
        variant="scrollable"
        orientation="vertical"
        TabIndicatorProps={{ style: { left: 0 } }}
      >
        {categories.map((category, index) => {
          const errorCount = countCategoryErrors(category)

          return (
            <StyledTabVertical
              id={`tab-${index}`}
              label={
                <Box>
                  {category}
                  {errorCount > 0 && (
                    <Box
                      ml={1}
                      width={24}
                      height={24}
                    >
                      {errorCount}
                    </Box>
                  )}
                </Box>
              }
              key={index}
              tabIndex={index}
              tabValue={categoryTabValue}
            />
          )
        })}
      </Tabs>
    </StyledGridBorderGrey>
  )
}

export const UserCustomFieldValues = ({ userResult }: UserCustomFieldValuesProps) => {
  const [userCustomFieldValuesResult, userCustomFieldValuesApi] = useApi() as [IApiResult, IApiFunc]
  const [userCustomFieldUpsertResult, userCustomFieldValuesUpsertApi] = useApi() as [IApiResult, IApiFunc]
  const [categoryTabValue, setCategoryTabValue] = useState(0)
  const [editMode, setEditMode] = useState(false)
  const [userCustomFieldValidation, setUserCustomFieldValidation] = useState<any>()
  const [formikValues, setFormikValues] = useState<{[x: number | string]: string }>({})
  const [cachedValueFields, setCachedValueFields] = useState<{[key: number | string]: UserCustomFieldWithCategory}>({})
  const [categories, setCategories] = useState<string[]>([])

  useEffect(() => {
    if (userResult.status !== 2) return
    fetchUserCustomFieldValues()
  }, [userResult])

  useEffect(() => {
    if (userCustomFieldValuesResult.status !== 2) return
    const initialFormikValues: { [p: number | string]: string } = {}
    const cachedFormikValues: { [p: number | string]: UserCustomFieldWithCategory } = {}
    const validationShape: { [p: number | string]: Yup.Schema } = {}
    const categoriesSet = new Set<string>()
    const firstInCategory: { [category: string]: boolean } = {}

    userCustomFieldValuesResult.data.forEach((userCustomField: UserCustomFieldDto, parentIndex: number) => {
      categoriesSet.add(userCustomField.category.name)
      userCustomField.valueFields.forEach((valueField: UserCustomFieldFormFieldDto, index: number) => {
        const key = valueField.userCustomFieldValueId > 0 ? valueField.userCustomFieldValueId : `userCustomFieldValueId_${parentIndex}_${index}`
        const userCustomFieldWithCategory: UserCustomFieldWithCategory = {
          ...valueField,
          formikKey: key,
          category: userCustomField.category.name,
          isFirstInCategory: !firstInCategory[userCustomField.category.name]
        }
        firstInCategory[userCustomField.category.name] = true
        if (index === 0) {
          userCustomFieldWithCategory.parentName = userCustomField.name
        }
        initialFormikValues[key] = valueField.value
        cachedFormikValues[key] = userCustomFieldWithCategory
        validationShape[key] = getValidation(valueField.dataType, !valueField.isRequired, valueField.name)
      })
    })

    setFormikValues(initialFormikValues)
    setCategories(Array.from(categoriesSet))
    setUserCustomFieldValidation(Yup.object().shape(validationShape))
    setCachedValueFields(cachedFormikValues)
  }, [userCustomFieldValuesResult])

  useEffect(() => {
    if (userCustomFieldUpsertResult.status !== 2) return
    fetchUserCustomFieldValues()
    setEditMode(false)
  }, [userCustomFieldUpsertResult])

  const handleFormikSubmit = (formikValues: any) => {
    const formData = Object.entries(formikValues).map(([key, value]) => {
      const original = cachedValueFields[key]
      const isFile = original.component === "Upload"
      let formikValue
      if (value === null || value === "") {
        formikValue = null
      } else {
        const stringValue = value as string
        formikValue = typeof value === "number" ? value.toString() : value
        if (isFile) {
          formikValue = removeBrackets(stringValue)
          formikValue = JSON.stringify(removeDataField(JSON.parse(formikValue)))
        }
      }
      const id: number = isNaN(parseInt(key)) ? 0 : parseInt(key)
      return {
        id: id,
        userCustomFieldValueFieldId: original.userCustomFieldValueFieldId,
        userId: userResult.data.id,
        value: formikValue
      }
    })

    const url = apiEndpoints.usercustomfieldsadmin.upsertusercustomfieldvalues
    userCustomFieldValuesUpsertApi.put(url, formData)
  }

  function fetchUserCustomFieldValues() {
    const url = apiEndpoints.usercustomfieldsadmin.getusercustomfieldformvalues.replace("{userId}", userResult.data.id)
    userCustomFieldValuesApi.get(url)
  }

  return (
    <Grid container spacing={5}>
      <Grid item md={12}>
        <PaperBox>
          {userCustomFieldValuesResult.status === 2 && userCustomFieldValuesResult.data.length === 0 ? (
            <Box p={3}>
              <Typography variant="bodyLarge" mb={3}>
                Det finns inga värden att visa. Klicka på knappen för att navigera till Egna fält
              </Typography>
              <Button
                component={Link}
                to={allRoutes.CustomerSettingsUsersCustomfields()}
                color="primary"
                variant="contained"
              >
                Gå till egna fält
              </Button>
            </Box>
          ) : (
            <Formik
              validationSchema={userCustomFieldValidation}
              initialValues={formikValues}
              onSubmit={handleFormikSubmit}
              enableReinitialize={true}
            >
              {(props) => (
                <form id="UserCustomFields" onSubmit={props.handleSubmit} noValidate style={{ position: "relative" }}>
                  <StyledIconButtonEdit
                    top={10}
                    right={0}
                    size="medium"
                    color="primary"
                    onClick={() => {
                      setEditMode(!editMode)
                    }}>
                    <StyledFontAwesomeIconEdit
                      editMode={editMode}
                      icon={faPenToSquare}
                      size="lg"
                    />
                  </StyledIconButtonEdit>
                  <Grid container spacing={2}>
                    <Grid md={1.5}>
                      <CategoryTabs
                        categoryTabValue={categoryTabValue}
                        setCategoryTabValue={setCategoryTabValue}
                        categories={categories}
                        formikProps={props}
                        cachedValues={cachedValueFields}
                      />
                    </Grid>
                    <Grid item md={10}>
                      <Grid container>
                        <Grid item xs pl={4}>
                          <List style={{ width: "50%" }}>
                            {Object.entries(cachedValueFields)
                              .filter(([_, valueField]) => (valueField.category === categories[categoryTabValue]))
                              .map(([_, valueField]) => {
                                const isFile = valueField.component === "Upload" && valueField.value !== null && valueField.value !== ""
                                const field: IFormFieldProps = {
                                  component: valueField.component,
                                  dataType: valueField.dataType,
                                  formFieldKey: "",
                                  id: valueField.formikKey,
                                  label: "",
                                  labelDefault: valueField.name,
                                  optional: !valueField.isRequired,
                                  labelKey: valueField.userCustomFieldValueId.toString(),
                                  value: (isFile && !editMode) ? `[${valueField.value}]` : valueField.value,
                                  key: valueField.formikKey.toString()
                                }
                                return <>
                                  {valueField.parentName && (
                                    <Typography variant="bodyMedium" mt={valueField.isFirstInCategory ? 0 : 4} fontWeight={700} mb={1}>
                                      {valueField.parentName}
                                    </Typography>
                                  )}
                                  {editMode ? (
                                    <FormComponents
                                      field={field}
                                      initialValues={formikValues}
                                      handleNext={() => {}}
                                      formikProps={props}
                                      feature="usercustomfield"
                                    />
                                  ) : (
                                    <Box mb={3}>
                                      <FormComponentsView field={field} />
                                    </Box>
                                  )}
                                </>
                              })
                            }
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end" pt={3} pb={5}>
                      {editMode && (
                        <Box display="flex" gap={3}>
                          <Button
                            color="primary"
                            variant="outlined"
                            sx={{ width: "150px" }}
                            onClick={() => {
                              setEditMode(false)
                            }}
                          >
                            Avbryt
                          </Button>
                          <DefaultButton
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={!props.dirty}
                            sx={{ width: "150px" }}
                            result={userCustomFieldUpsertResult}
                          >
                            Spara
                          </DefaultButton>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          )}
        </PaperBox>
      </Grid>
    </Grid>
  )
}