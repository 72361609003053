import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto"
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    "& .MuiTypography-h5": {
      fontSize: "1rem"
    },
    "& .MuiTypography-bodyMedium": {
      fontSize: "0.75rem"
    }
  },
  list: {
    width: "100%",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  accordion: {
    "& .MuiAccordionSummary-root": { paddingLeft: 0 },
    "& .MuiAccordionSummary-content": { margin: 0, padding: "8px 0" },
    "& .MuiAccordionDetails-root": {
      padding: 0
    }
  },
  inline: {
    display: "inline"
  },
  activeListItem: {
    background: "#fff",
    boxShadow: " 0px 0px 16px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    margin: "0 15px;",
    zIndex: 100
  },
  isOver: {
    borderBottom: "2px solid #4BADC6!important"
  },
  sortIcon: {
    color: "#BDBDBD",

    "& svg": {
      paddingLeft: 15
    }
  },
  overlaySortItem: {
    background: "#fff",
    boxShadow: " 0px 0px 16px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    margin: "0 15px;",
    width: "100%",
    padding: "10px 15px"
  },
  listItem: { borderBottom: "1px solid #EEEEEE" }
}))