import React, { useState } from "react"
import { AccordionDetails, AccordionSummary, Box, Typography, useTheme } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import useMediaQuery from "@mui/material/useMediaQuery"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ResponsiveAccordionProps } from "./ResponsiveAccordion.interface"
import { StyledAccordion, StyledStatNameTypography } from "./ResponsiveAccordion.styled"

const ResponsiveAccordion = ({
  screenSize,
  title,
  customCss,
  stats,
  children,
  shouldExpand = false
}: ResponsiveAccordionProps) => {
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.up(screenSize))
  const [expanded, setExpanded] = useState<boolean>(shouldExpand)

  const handleChange = () => {
    setExpanded(!expanded)
  }

  if (breakpoint) return <>{children}</>

  return (
    <StyledAccordion sx={{ borderBottom: 0 }} customCss={customCss} expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}>
        <Typography variant="h4">{title}</Typography>
        {stats && !expanded &&
          <Box display="flex" gap="8px 16px" mt={1} flexWrap="wrap">
            {stats.map((stat, i) => (
              <Box key={i} display="flex" gap={1}>
                <StyledStatNameTypography>{stat.name}</StyledStatNameTypography>
                <Typography variant="bodyXsmall">{stat.value}</Typography>
                {stat.icon && (
                  <FontAwesomeIcon style={{ marginTop: "2px" }} size="xs" icon={stat.icon} />
                )}
              </Box>
            ))}
          </Box>}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px", margin: "0px" }}>{children}</AccordionDetails>
    </StyledAccordion>
  )
}

export default ResponsiveAccordion