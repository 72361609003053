import { faCircleNotch } from "@fortawesome/pro-light-svg-icons"
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Grid, styled, Typography } from "@mui/material"
import { useApiPost } from "auth/useApi2"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

function BplTokenLink(props) {
  const { to, link, label, variant, color, bigButton, iconStart, iconEnd } = props
  const [bplLinkResult, linkToBplApi] = useApiPost()
  const history = useHistory()

  const linkToTp = () => {
    history.push(link)
  }

  const linkToBpl = () => {
    linkToBplApi(apiEndpoints.planning.linktoken)
  }
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer")
  }
  useEffect(() => {
    if (bplLinkResult.status === 2 && bplLinkResult.data.token !== null) {
      var url = bplLinkResult.data.bplUrl
        .concat("/login.aspx?id=")
        .concat(bplLinkResult.data.id)
        .concat("&t=")
        .concat(encodeURIComponent(bplLinkResult.data.token))
        .concat("&ReturnURL=")
        .concat(encodeURIComponent(bplLinkResult.data.bplUrl.concat("/").concat(to)))

      openInNewTab(url)
    }
  }, [bplLinkResult])

  const StyledBigButton = styled(Button)({
    borderRadius: "12px",
    padding: "32px 30px",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "none",
    color: theme.palette.text.primary,
    width: "100%",
    "&:hover": {
      boxShadow: "none"
    }
  })

  const StyledIconStart = styled(FontAwesomeIcon)({
    fontSize: "24px",
    color: theme.palette.primary["main"]
  })

  const StyledIconEnd = styled(FontAwesomeIcon)({
    fontSize: "12px",
    color: theme.palette.primary["main"]
  })

  return bigButton ? (
    <StyledBigButton
      {...props}
      variant={variant && variant}
      color={color ? color : "primary"}
      result={bplLinkResult}
      onClick={to ? linkToBpl : link ? linkToTp : null}
      endIcon={iconEnd && <StyledIconEnd icon={iconEnd} />}>
      <Box gap={3} display="flex" alignItems="center">
        <Box display="flex" justifyContent="center" sx={{ width: "40px" }}>
          {iconStart && <StyledIconStart icon={iconStart} />}
        </Box>
        <Typography fontWeight="500" variant="bodySmall">
          {bplLinkResult.status === 1
            ? "Laddar"
            : label
            ? label
            : to
            ? "Gå till BPL"
            : "Gå till Transportportalen"}
        </Typography>
      </Box>
    </StyledBigButton>
  ) : (
    <Button
      {...props}
      variant={variant ? variant : "contained"}
      color={color && color}
      result={bplLinkResult}
      onClick={linkToBpl}
      startIcon={
        bplLinkResult.status === 1 ? (
          <FontAwesomeIcon spin icon={faCircleNotch} />
        ) : (
          iconStart && <FontAwesomeIcon icon={iconStart} />
        )
      }>
      {bplLinkResult.status === 1 ? "Laddar" : label ? label : "Gå till BPL"}
    </Button>
  )
}

export default BplTokenLink
