import { Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useTranslation } from "react-i18next"

const style = makeStyles({
  marginHeader: {
    marginBottom: "20px"
  },
  marginBody: {
    marginBottom: "5px"
  }
})

const AnonymousUserMessage = () => {
  const classes = style()
  const { t } = useTranslation()

  return (
    <Grid item xs={12}>
      <Typography variant="h1" className={classes.marginHeader}>
        {t("dashboard.something_went_wrong", "Någonting gick fel")}
      </Typography>
      <Typography variant="bodyMedium" className={classes.marginBody}>
        {t(
          "dashboard.account_not_linked",
          "Du har kommit till denna sida eftersom ditt konto finns, men inte finns länkat på rätt sätt till en kund."
        )}
      </Typography>
      <Typography variant="bodyMedium" className={classes.marginBody}>
        {t(
          "dashboard.contact_support",
          "Var vänlig kontakta din systemansvarige eller Cloudplannings support."
        )}
      </Typography>
    </Grid>
  )
}
export default AnonymousUserMessage
