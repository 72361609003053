import { Box } from "@mui/material"
import { DefaultColumnFilter, SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { allRoutes } from "generated/routes"
import moment from "moment/moment"
import { Link } from "react-router-dom"
import { IApiResult } from "auth/interface/api.interface"
import {
  DataGridColumnDto,
  DataGridResultDto,
  ReportData,
  ReportFieldDataTypeEnum,
  ReportRow
} from "../interfaces/reports.interfaces"
import { ReportFieldCategoryEnum, TemplateFieldGroupDto } from "./AddTemplateForm/AddTemplateForm.interfaces"

const getValueFromKey = (row: ReportRow, data: DataGridColumnDto) => {
  switch (data.dataType) {
    case ReportFieldDataTypeEnum.String:
      return row.items[data.accessor]
    case ReportFieldDataTypeEnum.Integer:
      return isNaN(parseInt(row.items[data.accessor])) || row.items[data.accessor] === "" ? 0 : parseInt(row.items[data.accessor])
    case ReportFieldDataTypeEnum.DateShort:
      return row.items[data.accessor] && moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD")
    case ReportFieldDataTypeEnum.DateDetailed:
      return row.items[data.accessor] && moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD hh:mm")
    case ReportFieldDataTypeEnum.Decimal2:
      return !isNaN(parseFloat(row.items[data.accessor])) ? parseFloat(row.items[data.accessor]).toFixed(2).replace(".", ",") : ""
    case ReportFieldDataTypeEnum.DateOnly:
      return row.items[data.accessor] && moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD")
    case ReportFieldDataTypeEnum.Boolean:
      return row.items[data.accessor] !== undefined ? (Boolean(row.items[data.accessor]) === true ? "Ja" : "Nej") : ""
    default:
      return row.items[data.accessor] ? row.items[data.accessor] : null
  }
}

const getFilterType = (filterType: number) => {
  switch (filterType) {
    case 1:
      return SelectColumnFilter
    default:
      return DefaultColumnFilter
  }
}

const getFilterLabel = (title: string) => {
  switch (title) {
    case "Kundnamn":
      return "Alla kunder"
    default:
      return `Alla ${title}`
  }
}

export const convertBeStructureToDataGridStructure = (apiResultData: DataGridResultDto) => {
  // vi får ett objekt från api som liknar datagrid men vi behöver ändå konvertera det till nån form av react
  // främst när vi typbestämmer kolumner och hur de ska visas.

  let reportData: ReportData = {
    columns: [],
    subColumns: [],
    rows: apiResultData.rows,
    footer: apiResultData.totalSum,
    totalSum: apiResultData.totalSum
  }

  apiResultData.columns.forEach((data, row) => {
    reportData.columns.push({
      accessor: (row: any) => {
        switch (data.dataType) {
          case ReportFieldDataTypeEnum.String:
            return row.items[data.accessor]
          case ReportFieldDataTypeEnum.Integer:
            return isNaN(row.items[data.accessor]) || row.items[data.accessor] === "" ? 0 : parseInt(row.items[data.accessor])
          case ReportFieldDataTypeEnum.DateShort:
            return moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD")
          case ReportFieldDataTypeEnum.DateDetailed:
            return moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD hh:mm")
          case ReportFieldDataTypeEnum.Decimal2:
            return parseFloat(row.items[data.accessor]).toFixed(2).replace(".", ",")
          default:
            return row.items[data.accessor]
        }
      },
      Header: data.title,
      tooltip: data.description,
      Filter: getFilterType(data.datafilterId),
      FilterHeader: getFilterLabel(data.title),
      mainFilter: data.mainFilter,
      Cell: ({ row }: any) => {
        return (
          row.original.items && (
            <Box
              display="block"
              style={{
                color: "inherit",
                textDecoration: "inherit"
              }}
            >
              {getValueFromKey(row.original, data)}
            </Box>
          )
        )
      }
    })
  })

  reportData.columns.push({
    accessor: "id",
    Header: "",
    tooltip: "",
    hidden: true,
    FilterHeader: "",
    mainFilter: false,
  })

  //SubColumns
  apiResultData.subColumns.forEach((data) => {
    reportData.subColumns.push({
      accessor: (row: any) => {
        switch (data.dataType) {
          case ReportFieldDataTypeEnum.String:
            return row.items[data.accessor]
          case ReportFieldDataTypeEnum.Integer:
            return isNaN(row.items[data.accessor]) || row.items[data.accessor] === "" ? 0 : parseInt(row.items[data.accessor])
          case ReportFieldDataTypeEnum.DateShort:
            return moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD")
          case ReportFieldDataTypeEnum.DateDetailed:
            return moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD hh:mm")
          case ReportFieldDataTypeEnum.Decimal2:
            return parseFloat(row.items[data.accessor]).toFixed(2).replace(".", ",")
          default:
            return row.items[data.accessor]
        }
      },
      Header: data.title,
      tooltip: data.description,
      Filter: getFilterType(data.datafilterId),
      FilterHeader: getFilterLabel(data.title),
      mainFilter: data.mainFilter,
      Cell: ({ row }: any) => {
        return (
          row.original.items && (
            <Box
              display="block"
              component={Link}
              to={allRoutes.VehicleregistryVehiclesDetails(row.original.id)}
              style={{
                color: "inherit",
                textDecoration: "inherit"
              }}
            >
              {getValueFromKey(row.original, data)}
            </Box>
          )
        )
      }
    })
  })

  return reportData
}

export const customFieldsByCategory = (emptyTemplateResult: IApiResult) => {
  const { id, name, description, userReportTemplateAuths, fieldGroups } = emptyTemplateResult.data
  const templateWithoutCustomFields = fieldGroups?.filter((fieldGroup: any) => {
    return fieldGroup.reportFieldCategoryEnum !== ReportFieldCategoryEnum.CustomFields
  })
  const customFieldsByCategory: { [key: string]: any[] } = {}
  fieldGroups.forEach((group: any) => {
    if (group.reportFieldCategoryEnum !== ReportFieldCategoryEnum.CustomFields) return
    group.items.forEach((item: any) => {
      if (!customFieldsByCategory[item.category]) {
        customFieldsByCategory[item.category] = []
      }
      customFieldsByCategory[item.category].push(item)
    })
  })
  const categoryGroups: TemplateFieldGroupDto[] = []
  for (const categorizedItemsKey in customFieldsByCategory) {
    categoryGroups.push({
      title: categorizedItemsKey,
      reportFieldCategoryEnum: ReportFieldCategoryEnum.CustomFields,
      items: customFieldsByCategory[categorizedItemsKey]
    })
  }
  return {
    id,
    name,
    description,
    userReportTemplateAuths,
    fieldGroups: [...templateWithoutCustomFields, ...categoryGroups]
  }
}