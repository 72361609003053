import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Box, Button, ButtonGroup, Container, Grid, Typography, useMediaQuery } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import PaginationForPages from "components/paginationForPages/PaginationForPages"
import usePagination from "components/paginationForPages/usePagination"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import IssueReportCard from "pages/issuereports/pages/dashboard/components/DashboardCard"
import React, { useContext, useEffect, useState } from "react"
import ImportIssueReportForm from "../../components/CustomForms/ImportIssueReportForm"
import { DashboardQuickLinks } from "./components/DashboardQuickLinks"
import DashboardSelect from "./components/DashboardSelect"
import theme from "css/theme/light"

const IssueReportsDashboard = () => {
  const appContext = useContext(AppContext)

  // open sidebar
  const [showSidebarForm, setShowSidebarForm] = useState(false)
  const openAdd = () => {
    setShowSidebarForm(true)
  }

  const loadingContext = React.useContext(GlobalLoaderContext)
  const [issuereportResult, issuereportFuncs] = useApi()
  const [vehiclesResult, vehiclesApi] = useApi()
  const [filteredIssueReports, setFilteredIssueReports] = useState([])
  const [chosenVehicle, setChosenVehicle] = useState(null)
  const [activeIssueReports, setActiveIssueReports] = useState(null)
  const skeletonRows = [1, 2, 3, 4, 5, 6]
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    vehiclesApi.get(apiEndpoints.issuereports.getvehiclelist)
    return () => {
      vehiclesApi.leave()
    }
  }, [vehiclesApi])

  useEffect(() => {
    issuereportFuncs.get(apiEndpoints.issuereports.getissuereportsoverview)
    return () => {
      issuereportFuncs.leave()
    }
  }, [issuereportFuncs])

  useEffect(() => {
    loadingContext.setLoading(() => issuereportResult.status === 1)
  })

  // update dashboard data
  const updateExistingData = () => {
    issuereportFuncs.get(apiEndpoints.issuereports.getissuereportsoverview)
  }

  let [page, setPage] = useState(1)
  const itemsPerPage = 10
  const count = Math.ceil(
    issuereportResult.status === 2 &&
      filteredIssueReports?.length > 0 &&
      filteredIssueReports?.length / itemsPerPage
  )
  const issueReportResultPagination = usePagination(
    issuereportResult.status === 2 && filteredIssueReports?.length > 0 && filteredIssueReports,
    itemsPerPage
  )

  // handle page on pagination
  const handleChange = (e, page) => {
    setPage(page)
    issueReportResultPagination.jump(page)
  }

  useEffect(() => {
    const filteredVehicles = issuereportResult?.data?.filter((i) => i.vehicleId === activeIssueReports?.id)
    setFilteredIssueReports(filteredVehicles)
  }, [activeIssueReports, issuereportResult])

  return (
    <Box pb={3}>
      <Container>
        <Grid container direction="row" justifyContent="space-between" marginBottom="40px">
          <Typography variant="h1" mb={isPhone ? 2 : 0}>
            Felrapportering
          </Typography>
          {issuereportResult.status === 2 && hasRole(appContext.appUser, allRoles.IssueReports) && (
            <ButtonGroup>
              <Button
                onClick={() => openAdd()}
                endIcon={<AddCircleIcon />}
                variant="contained"
                color="primary">
                Skapa felrapport
              </Button>
            </ButtonGroup>
          )}
        </Grid>
        <Grid container direction="row" justifyContent="space-between" p={0} spacing={4}>
          <Grid item md={8} xl={6} xs={12}>
            <Grid container direction="column" spacing={5}>
              {hasRole(appContext.appUser, allRoles.IssueReportsAdmin) && (
                <Grid item md={12} xl={12}>
                  {vehiclesResult.status === 2 ? (
                    <PaperBox mt={0}>
                      <DashboardSelect
                        setActiveIssueReports={setActiveIssueReports}
                        setChosenVehicle={setChosenVehicle}
                        vehiclesResult={vehiclesResult}
                      />
                    </PaperBox>
                  ) : (
                    <SkeletonCard height="172px" />
                  )}
                </Grid>
              )}
              {hasRole(appContext.appUser, allRoles.IssueReportsAdmin) && (
                <Grid item md={12} xl={12}>
                  {issuereportResult.status === 2 && activeIssueReports && (
                    <Box>
                      <Typography mb={2} variant="h1">
                        Aktuella felrapporter och arbetsordrar
                      </Typography>
                      {filteredIssueReports?.length > 0 ? (
                        <PaperBox>
                          {issueReportResultPagination.currentData().map((assignment) => (
                            <IssueReportCard
                              filteredIssueReports={filteredIssueReports}
                              assignment={assignment}
                            />
                          ))}
                        </PaperBox>
                      ) : (
                        <PaperBox>
                          <Box textAlign="center">
                            <Typography variant="bodySmall">
                              Det finns inga aktuella felrapporter eller arbetsordrar för valt fordon
                            </Typography>
                          </Box>
                        </PaperBox>
                      )}
                      {filteredIssueReports?.length > 0 && (
                        <Box mt={2}>
                          <PaginationForPages
                            count={count}
                            page={page}
                            onChange={handleChange}
                            size="medium"
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={4} xl={3} xs={12}>
            <Typography variant="h4">Snabblänkar</Typography>
            {issuereportResult.status === 2 ? (
              <PaperBox>
                <DashboardQuickLinks />
              </PaperBox>
            ) : (
              <Box mt={2}>
                <SkeletonCard />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>

      <ImportIssueReportForm
        setChosenVehicle={setChosenVehicle}
        chosenVehicle={chosenVehicle && chosenVehicle}
        updateExistingData={updateExistingData}
        setShowSidebarForm={setShowSidebarForm}
        showSidebarForm={showSidebarForm}
      />
    </Box>
  )
}
export default IssueReportsDashboard
