import React, { createContext, useState } from "react"

export const ReportTemplateContext = createContext<any>(null)

const reportTemplateAuthsMock = [
  {
    id: 1,
    name: "Admin",
    authorizationLevel: 1,
  },
  {
    id: 2,
    name: "User",
    authorizationLevel: 2,
  },
  {
    id: 3,
    name: "Guest",
    authorizationLevel: 2,
  }
]

export const ReportTemplateContextProvider = (props: any) => {
  const [selectedFields, setSelectedFields] = useState([])
  const [fieldsToChoose, setFieldsToChoose] = useState({
    fieldGroups: [],
    name: "",
    description: "",
    userReportTemplateAuths: []
  })
  const [contextTemplateAuths, setContextTemplateAuths] = useState([])

  return (
    <ReportTemplateContext.Provider value={{
      selectedFields,
      setSelectedFields,
      fieldsToChoose,
      setFieldsToChoose,
      contextTemplateAuths,
      setContextTemplateAuths
    }}>
      {props.children}
    </ReportTemplateContext.Provider>
  )
}