export const isJson = (str: string) => {
  try {
    const parse = JSON.parse(str)
    if (parse === null) {
      return false
    }
  } catch (e) {
    return false
  }
  return true
}