export const customCss = {
  borderRadius: "16px !important",
  boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 4%)",
  "& .MuiAccordionDetails-root": {
    padding: 0
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#000000"
  },
  "& .MuiAccordionSummary-root": {
    padding: "12px 24px"
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    padding: "16px",
    borderBottom: "1px solid #E9E9E9"
  },
  "& .MuiAccordionSummary-content": { margin: 0 },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0
  }
}

export const customCssLearning = {
  ...customCss,
  "& .MuiAccordionDetails-root": {
    padding: "0 16px 24px 16px"
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    padding: "16px",
    borderBottom: "1px solid #E9E9E9",
    marginBottom: "32px"
  }
}

export const customCssTimereport = {
  borderRadius: "16px !important",
  boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 4%)",
  "& .MuiAccordionSummary-root": {
    padding: "12px 24px"
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    borderBottom: "1px solid #E9E9E9",
    padding: "16px"
  },
  "& .MuiAccordionSummary-content": { margin: 0 },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0
  }
}

export const customCssAssigment = {
  ...customCss,
  borderRadius: "16px !important",
  boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 4%)",
  "& .MuiAccordionSummary-root": {
    padding: "12px 24px",
    borderRadius: "16px"
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    borderBottom: "1px solid #E9E9E9",
    borderRadius: "16px 16px 0 0",
    padding: "16px"
  },
  "& .MuiAccordionSummary-content": { margin: 0 },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0
  }
}
