import { UploadData } from "./file.interfaces"

export const removeBrackets = (value: string): string => {
  if (value.startsWith("[") && value.endsWith("]")) {
    return value.slice(1, -1)
  }
  return value
}

export function removeDataField(fileInfo: UploadData): Omit<UploadData, "Data"> {
  const { Data, ...leftoverFields } = fileInfo
  return leftoverFields
}