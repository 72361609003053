import { Box, Typography } from "@mui/material"
import LoadingTypography from "components/skeleton/LoadingTypography"
import React, { FC, ReactNode } from "react"

interface TextCellProps {
    label: string
    text?: string
    icon?: ReactNode | null
}

export const TextCell: FC<TextCellProps> = ({ label, text, icon = null }) => (
    <Box marginBottom={3}>
        <Box display="flex">
            <Typography variant="bodySmall" gutterBottom>
                {label}
            </Typography>
            {icon}
        </Box>
        <Typography variant="bodyMedium" fontWeight={600}>
            {text ? text : "-"}
        </Typography>
    </Box>
)

interface LoadingTextCellProps {
    label: string
    text?: string
    status?: string
    noAnimate?: boolean
}

export const LoadingTextCell: FC<LoadingTextCellProps> = ({ label, text, status, noAnimate }) => (
    <>
        <LoadingTypography variant="bodySmall" noAnimate={noAnimate}>
            {label}
        </LoadingTypography>
        <LoadingTypography variant="bodyMedium" text={text} width={"80%"} noAnimate={noAnimate} />
    </>
)