import { AppContext } from "components/AppContextProvider"
import React, { useContext } from "react"

import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import moment from "moment"

const Welcome = () => {
  const appContext = React.useContext(AppContext)
  const { t } = useTranslation()
  const { selectedLanguage } = useContext(AppContext)

  const locale = selectedLanguage === "en" ? "en-gb" : selectedLanguage
  const formattedDate = moment().locale(locale).format("DD MMMM YYYY")

  return (
    <Grid item xs={12} pb={{ xs: 3, lg: 0 }}>
      <Typography variant="mainTitle" sx={{ color: "#454444" }}>
        {t("dashboard.welcome", "Välkommen")}, {appContext.appUser.profile.name}!
      </Typography>
      <Typography mt={1} variant="bodySmall" color="textSecondary">
        {formattedDate}
      </Typography>
    </Grid>
  )
}

export default Welcome
