import { grey } from "@mui/material/colors"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "24px 20px"
  },
  root: {
    marginTop: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  examCard: {
    textAlign: "center",
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:hover > div": {
      boxShadow: "0px 3px 38px 0px rgb(205 205 205)"
    },
    "& > div": {
      transition: "all 0.5s ease"
    }
  },
  dotIcon: {
    marginRight: 8
  },
  chartInfo: {
    marginTop: 8
  },
  textGrey: {
    color: grey[500]
  },
  blue: {
    background: theme.palette.primary["light"],
    color: theme.palette.primary["dark"],
    margin: 0
  },
  // cyan: {
  //     background: theme.palette.cyan[100],
  //     color: theme.palette.cyan[500],
  //     margin: 0
  // },
  image: {
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    color: theme.palette.grey[50],
    textAlign: "center",
    minHeight: "220px",
    backgroundImage: (props) => `url(${props.image})`,
    backgroundPosition: "center",
    backgroundSize: "cover"
  },
  icon: {
    color: theme.palette.primary["main"],
    marginRight: 8
  },
  flex: {
    display: "flex"
  }
}))

export default useStyles
