import React from "react"
import { Box, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

interface ChipTagProps {
  text: string
  textColor: string
  bgColor: string
  icon: IconProp
  customCss?: React.CSSProperties
}

export const ChipTag = ({ text, textColor, bgColor, icon, customCss }: ChipTagProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      color={textColor}
      bgcolor={bgColor}
      sx={{ borderRadius: "12px", paddingX: "13px", ...customCss }}
      px={2}
      height="20px"
    >
      <FontAwesomeIcon icon={icon} size="xs" />
      <Typography fontSize="10px">
        {text}
      </Typography>
    </Box>
  )
}