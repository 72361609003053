import { Grid, useMediaQuery, useTheme } from "@mui/material"
import PaperBox from "components/PaperBox"

import LoadingBox from "components/skeleton/LoadingBox"
import LoadingTypography from "components/skeleton/LoadingTypography"

const numberOfLoadingTypography = [1, 2, 3, 4]

export const ActionCardLoading = (props) => {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down("lg"))
  const { hasAccordionWrapper } = props

  return (
    <PaperBox {...props}>
      {hasAccordionWrapper && isPhone ? (
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={12}>
            <LoadingTypography variant="bodySmall" />
            <LoadingTypography variant="bodySmall" />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={12}>
            <LoadingBox width="250px" />
            <Grid container mb={1.5} mt={2} spacing={1}>
              {numberOfLoadingTypography.map((index) => (
                <Grid item xs={12} key={index}>
                  <LoadingTypography variant="bodyMedium" />
                </Grid>
              ))}
            </Grid>
            <LoadingBox width="100px" />
          </Grid>
        </Grid>
      )}
    </PaperBox>
  )
}
