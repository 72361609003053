import { Box, Button, Divider, Grid, Hidden, styled, Typography, useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useApi } from "auth/useApi3"
import PaperBox from "components/PaperBox"
import RouterLink from "components/RouterLink"
import UserAvatar from "components/UserAvatar"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import NoNewsCard from "pages/news/components/NoNewsCard"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import { NavigateTo } from "../shared/NavigateTo"
import NewsSkeletonGroup from "./components/NewsSkeletonGroup"
import { AppContext } from "components/AppContextProvider"
import moment from "moment"

const truncate = (str, isLargeDown) => {
  const maxLength = isLargeDown ? 75 : 200
  return str.length > maxLength ? str.substring(0, maxLength) + "..." : str
}

const StyledImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "112px",
  [theme.breakpoints.down("lg")]: {
    height: "100px"
  },
  width: "136px",
  backgroundColor: "#F9FAFB",
  borderRadius: "12px"
}))

const StyledDateNumberTypography = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "24px"
  },
  fontWeight: "bold",
  textAlign: "center"
}))

const StyledMonthNameTypography = styled(Typography)({
  fontSize: "14px",
  textAlign: "center"
})

const StyledGreyTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  textDecoration: "none",
  fontSize: "0.75rem",
  [theme.breakpoints.down("lg")]: {
    fontSize: "0.625rem"
  }
}))

const StyledPaperBox = styled(PaperBox)(({ theme, isLargeDown }) => ({
  borderRadius: "16px",
  margin: "0px",
  padding: "40px 24px",
  [theme.breakpoints.down("lg")]: {
    padding: "24px 16px"
  }
}))

const StyledDivider = styled(Divider)({
  height: "1px",
  borderBottomWidth: "1px",
  borderBottomColor: "#E9E9E9"
})

const NewsList = ({ items }) => {
  const theme = useTheme()
  const isLargeDown = useMediaQuery(theme.breakpoints.down("lg"))
  const { t } = useTranslation()
  const [hoveredIndex, setHoveredIndex] = useState(null)

  const { selectedLanguage } = useContext(AppContext)
  const locale = selectedLanguage === "en" ? "en-gb" : selectedLanguage

  return (
    <Grid container direction="column">
      {items.results.slice(0, 4).map((item, i, data) => {
        const formattedDate = moment(item.publishedDate).locale(locale).format("DD MMMM YYYY")

        return (
          <>
            <NavigateTo to={allRoutes.NewsListRead(item.id)} key={item.id}>
              <Grid
                xs={12}
                display="flex"
                flexDirection="row"
                alignItems="center"
                onMouseEnter={() => setHoveredIndex(i)}
                onMouseLeave={() => setHoveredIndex(null)}
                item>
                <StyledImageBox>
                  <StyledDateNumberTypography>
                    <Moment format="DD">{item.publishedDate}</Moment>
                  </StyledDateNumberTypography>
                  <StyledMonthNameTypography>
                    <Moment format="MMM">{item.publishedDate}</Moment>
                  </StyledMonthNameTypography>
                </StyledImageBox>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  pl={isLargeDown ? 2 : 3}
                  pr={isLargeDown ? 0 : 3}
                  py={isLargeDown ? 0 : 1}>
                  <Box>
                    <Typography variant="labelMedium" fontWeight={hoveredIndex === i ? "600" : "500"}>
                      {item.subject}
                    </Typography>
                  </Box>
                  <Grid item xs={12} xl={10} my={1}>
                    <Typography variant="bodyXsmall" color="textSecondary">
                      {truncate(item.subTitle, isLargeDown)}
                    </Typography>
                  </Grid>
                  <Grid item display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center" gap={isLargeDown ? 1 : 1}>
                      <Box display="flex" alignItems="center">
                        <Hidden lgDown>
                          <UserAvatar
                            sx={{ marginRight: "12px !important", fontSize: "12px !important" }}
                            label={item.publishedByUserFullName}
                            size="xs"
                          />
                        </Hidden>
                        <StyledGreyTypography isLargeDown={isLargeDown}>
                          {t("global.titles.by", "Av")} {item.publishedByUserFullName}
                        </StyledGreyTypography>
                      </Box>
                      <StyledGreyTypography isLargeDown={isLargeDown}>{" • "}</StyledGreyTypography>
                      <StyledGreyTypography isLargeDown={isLargeDown}>{formattedDate}</StyledGreyTypography>
                    </Box>

                    <Hidden lgDown>
                      <Button
                        component={Link}
                        size="small"
                        sx={{
                          padding: "0",
                          height: "20px",
                          fontSize: "12px"
                        }}
                        color="primary"
                        variant="text"
                        to={allRoutes.NewsListRead(item.id)}>
                        {t("global.buttons.readmore")}
                      </Button>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </NavigateTo>
            {data.length > 1 && i !== data.length - 1 && (
              <Box sx={{ my: { xs: 3, lg: 2 } }}>
                <StyledDivider />
              </Box>
            )}
          </>
        )
      })}
    </Grid>
  )
}

const News = ({ newsResult }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isLargeDown = useMediaQuery(theme.breakpoints.down("lg"))

  return (
    <Grid container>
      <Grid item xs={12}>
        {newsResult.status === 2 && newsResult.data.results.length < 1 && (
          <Grid item md={8} xs={12}>
            <Box mt={4}>
              <NoNewsCard />
            </Box>
          </Grid>
        )}
        {newsResult.status === 2 && newsResult.data.results.length > 0 && (
          <StyledPaperBox isLargeDown={isLargeDown}>
            <NewsList items={newsResult.data} />
            <Hidden lgUp>
              <Box sx={{ mt: 3 }}>
                <StyledDivider />
              </Box>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item pt={3}>
                  <RouterLink style={{ fontSize: "12px" }} to={allRoutes.NewsList()}>
                    {t("news.more_news", "Fler nyheter")}
                  </RouterLink>
                </Grid>
              </Grid>
            </Hidden>
          </StyledPaperBox>
        )}
        {newsResult.status !== 2 && (
          <PaperBox sx={{ margin: 0 }}>
            <NewsSkeletonGroup />
          </PaperBox>
        )}
      </Grid>
    </Grid>
  )
}

export default News
