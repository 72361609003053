import React from "react"
import { useHistory } from "react-router-dom"
import { Box, Card, Grid, IconButton, ListItem, Typography } from "@mui/material"
import { allRoutes } from "generated/routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import theme from "css/theme/light"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { ReportTypeEnum, TemplateCardProps } from "../interfaces/reports.interfaces"

const TemplateCard = ({ data, reportType, index, totalCards }: TemplateCardProps) => {
  const { id, name, isEditable, description } = data
  const history = useHistory()
  const reportTemplateRoute = reportType === ReportTypeEnum.User
    ? allRoutes.ReportsUserreportsReporttemplatesEdit
    : allRoutes.ReportsVehiclereportsReporttemplatesEdit

  const iconGrid = (
    <Grid item xs={1} justifyContent="end" display="flex">
      <IconButton
        onClick={() => history.push(reportTemplateRoute(id))}
        disabled={!isEditable}
        size="large"
        aria-label="navigate"
        sx={{
          width: 40,
          height: 40
        }}
      >
        <FontAwesomeIcon
          color={isEditable ? theme.palette.grey["A400"] : theme.palette.background["paper"]}
          icon={faChevronRight}
          fontSize="large"
        />
      </IconButton>
    </Grid>
  )

  const getBorderRadius = () => {
    const isFirstCard = index === 0
    const isLastCard = index === totalCards - 1
    const onlyOneCard = totalCards === 1
    if (onlyOneCard) {
      return "8px"
    } else if (isFirstCard) {
      return "8px 8px 0 0"
    } else if (isLastCard) {
      return "0 0 8px 8px"
    } else {
      return "0"
    }
  }

  return (
    <Card variant="outlined" sx={{
      borderRadius: getBorderRadius(),
      display: "flex",
      direction: "row"
    }}>
      <Grid
        container
        sx={{
          paddingX: { xs: 2, md: 4 },
          paddingY: { xs: 2, md: 2 },
          flexGrow: 1
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={11}>
          <Grid container direction="column" alignItems="flex-start" justifyContent="center">
            <Grid item>
              <Typography variant="h5">{name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="bodySmall" color="textSecondary">{description}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {iconGrid}
      </Grid>
    </Card>
  )
}

export default TemplateCard