import React, { useContext, useEffect, useRef } from "react"
import { Box, Button, Container, Divider, Grid, ListItem, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useApiGet, useApiGetBlob } from "auth/useApi2"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { tinyMceImportDate } from "components/TinyMCE/TinyMCE"
import { TinyMCEViewer } from "components/TinyMCE/TinyMCEViewer"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import "moment/locale/sv"
import MUIRichTextEditor from "mui-rte"
import Moment from "react-moment"

const styles = makeStyles({
  iconMr: {
    marginRight: 5
  },
  bodytext: {
    "& img": {
      maxWidth: "100%",
      height: "auto"
    }
  },
  flexColumn: {
    [theme.breakpoints.between("md", "lg")]: {
      alignItems: "flex-start",
      flexDirection: "column"
    }
  }
})
const BodyRichContent = ({ content }) => {
  return <MUIRichTextEditor defaultValue={content} readOnly={true} toolbar={false} />
}

const NewsRead = ({ match }) => {
  const loadingContext = useContext(GlobalLoaderContext)
  const [articleResult, getArticle] = useApiGet()
  const [getAttachmentResult, getAttachment] = useApiGetBlob()
  const editorRef = useRef(null)
  const classes = styles()

  useEffect(() => {
    getArticle(apiEndpoints.news.getarticle.replace("{id}", match.params.id))
  }, [getArticle, match.params.id])

  useEffect(() => {
    loadingContext.setLoading(() => articleResult.status === 1)
  })

  const downloadAttachment = React.useCallback(
    (id, fileName) => {
      console.log("get file ", id)
      getAttachment(apiEndpoints.news.getattachment.replace("{id}", id), fileName)
    },
    [getAttachment]
  )

  const article = articleResult.data

  return (
    <>
      <Container>
        <Grid container spacing={4} direction="row" justifyContent="space-between">
          <Grid item xs={12} md={8}>
            {articleResult.status === 1 && <SkeletonCard />}
            {articleResult.status === 2 ? (
              <>
                <PaperBox className={classes.bodytext}>
                  {article.priorityEndDate && (
                    <Typography variant="bodySmall" color="primary" gutterBottom>
                      Viktigt!
                    </Typography>
                  )}
                  <Typography variant="h2" gutterBottom>
                    {article.subject}
                  </Typography>
                  <Typography variant="paragraphLarge" gutterBottom>
                    {article.subTitle}
                  </Typography>
                  {articleResult.status === 2 && new Date(article.publishedDate) <= new Date(tinyMceImportDate) ? (
                    <BodyRichContent content={article.body} />
                  ) : (
                    <TinyMCEViewer
                      initVal={article.body}
                      editorRef={editorRef}
                    />
                  )}
                  <Box my={2}>
                    <Divider />
                    <Box my={2}>
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid className={classes.grey}>
                          Av {article.publishedByUserFullName}
                          {" • "}
                          <Moment format="LL" globallocale="sv">
                            {article.publishedDate}
                          </Moment>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </PaperBox>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} md={4}>
            {articleResult.status === 1 && <SkeletonCard />}
            {articleResult.status === 2 && articleResult.data.attachments.length > 0 && (
              <PaperBox>
                <Typography variant="h4">Bifogade filer</Typography>

                {articleResult.data.attachments !== null &&
                  articleResult.data.attachments.map((item, key) => {
                    return (
                      <>
                        <ListItem key={key} divider disableGutters>
                          <Grid container alignItems="center" className={classes.flexColumn}>
                            <Grid item xs={6}>
                              {item.name}
                            </Grid>
                            <Grid item xs={6}>
                              <Box display="flex" justifyContent="flex-end">
                                <Button
                                  onClick={() => {
                                    downloadAttachment(item.id, item.name)
                                  }}
                                  variant="link">
                                  <Typography variant="caption">Ladda ner</Typography>
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </>
                    )
                  })}
              </PaperBox>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default NewsRead
