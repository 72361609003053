import { Box, Button, Container, Grid, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"

import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"

import globalUseStyles from "css/theme/globalUseStyles.js"
import { allRoutes } from "generated/routes"
import { NavLink } from "react-router-dom"

import moment from "moment"

const ListContract = () => {
  const globalClasses = globalUseStyles()

  const loadingContext = useContext(GlobalLoaderContext)

  const [contractResult, contractFuncs] = useApi()
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [pageSize, setPageSizeManual] = useState(25)
  const [sortState, setSortState] = useState({
    sortby: "regNumber",
    direction: "asc"
  })
  useEffect(() => {
    contractFuncs.get(
      apiEndpoints.vehicleregistryadmin.listpagedcontractrows
        .replace("{page}", page)
        .replace("{pagesize}", pageSize)
        .replace("{sortby}", sortState.sortby + " " + sortState.direction)
    )
  }, [contractFuncs, page, pageSize, sortState.direction, sortState.sortby])
  useEffect(() => {
    if (contractResult.status === 2) {
      setRows(contractResult.data.results)
      setPageCount(contractResult.data.pageCount)
    }
  }, [contractResult])
  useEffect(() => {
    loadingContext.setLoading(() => contractResult.status === 1)
  })

  const columns = [
    {
      accessor: "regnr",
      Header: "Regnr"
    },
    {
      accessor: "internNr",
      Header: "Internnr"
    },
    {
      accessor: "name",
      Header: "Kund"
    },
    {
      accessor: "assignmentNr",
      Header: "Avtalsnr"
    },
    {
      accessor: "nrOfHours",
      Header: "Timmar"
    },
    {
      accessor: "dateFrom",
      Header: "Startdatum",
      Cell: ({ cell }) => {
        return moment(cell.row.original.dateFrom).format("YYYY-MM-DD HH:mm")
      }
    },
    {
      accessor: "dateTimeTo",
      Header: "Slutdatum",
      Cell: ({ cell }) => {
        return moment(cell.row.original.dateTimeTo).format("YYYY-MM-DD HH:mm")
      }
    },
    {
      accessor: "subContract",
      Header: "Underavtal"
    }
  ]
  return (
    <div>
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Avtal</Typography>

            <Button
              component={NavLink}
              to={allRoutes.ReportsVehiclereportsContractImport()}
              color="primary"
              variant="contained">
              Importera avtal
            </Button>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          pageCount={pageCount}
          pageSize={pageSize}
          currentpage={page}
          totalPage={pageCount}
          manualPagination={true}
          manualSortBy={true}
          setPage={setPage}
          setPageSizeManual={setPageSizeManual}
          manualPageSize={pageSize}
          hideSearch={true}
          hideFilter={true}
          manualSetSortState={setSortState}
          manualSortState={sortState}
          rows={contractResult.status === 2 && rows}
          status={contractResult.status}
        />
      </Box>
    </div>
  )
}
export default ListContract
