import { Box, Container, Tooltip, Typography } from '@mui/material'
import { useApi } from "auth/useApi3"
import ClpTable from 'components/ClpTable'
import globalUseStyles from 'css/theme/globalUseStyles'
import { apiEndpoints } from "generated/apiEndpoints"
import { useContext, useEffect } from 'react'
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { GlobalLoaderContext } from 'components/GlobalLoadingProvider'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import theme from 'css/theme/light'
import moment from 'moment'

const Bkychecks = () => {

    const { t } = useTranslation("translation", { keyPrefix: "global.features" })
    const globalClasses = globalUseStyles()
    const [bkychecksTemplatesResult, bkychecksTemplatesApi] = useApi() as [IApiResult, IApiFunc]
    const loadingContext = useContext(GlobalLoaderContext)

    useEffect(() => {
        bkychecksTemplatesApi.get(apiEndpoints.reporting.getuserbkyreport)
    }, [])

    useEffect(() => {
        loadingContext.setLoading(() => bkychecksTemplatesResult.status === 1)
    }, [])

    const columns2: any[] = [
        {
            accessor: (row: any) => {
                return row.invalidLicences?.length > 0 &&
                    <Tooltip title="Det finns en avvikelse">
                        <FontAwesomeIcon icon={faTriangleExclamation} color={theme.palette.error.main} />
                    </Tooltip>
            },
            Header: "",
            id: "iconColumn"
        },
        {
            accessor: (row: any) => {
                return row.name
            },
            Header: "Namn"
        },
        {
            accessor: (row: any) => {
                return row.socialSecurityNumber
            },
            Header: "Födelsedatum"
        },
        {
            accessor: (row: any) => {
                return moment.utc(row.updatedTime).format("YYYY-MM-DD HH:mm")
            },
            Header: "Datum"
        },
        {
            accessor: (row: any) => {
                return row.driverType
            },
            Header: "Förartyp"
        },
        {
            accessor: (row: any) => {
                return row.validLicences?.map((item: string) => item).join(", ")
            },
            Header: "Körkortsbehörighet "
        },
        {
            accessor: (row: any) => {
                return row.invalidLicences?.map((item: string) => item).join(", ")
            },
            Header: "Avvikelser"
        }
    ]

    return (
        <div>
            <Container>
                <Box mb={4}>
                    <Typography variant="h1">{t("title.bkychecks", "BKY-kontroller")}</Typography>
                </Box>
            </Container>

            <Box className={globalClasses.tableContainer}>
                <ClpTable columns={columns2}
                    rows={bkychecksTemplatesResult.status === 2 && bkychecksTemplatesResult.data}
                    status={bkychecksTemplatesResult.status}
                />
            </Box>

        </div>
    )
}

export default Bkychecks