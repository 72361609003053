import React, { useContext } from "react"
import { Container, Grid, Typography } from "@mui/material"
import { faCarBus, faUser } from "@fortawesome/pro-solid-svg-icons"
import { allRoutes } from "generated/routes"
import { allRoles } from "generated/roles"
import { DetailCard } from "components/Cards/DetailCard"
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"

const Reports = () => {
  const appContext = useContext(AppContext)

  return (
    <Container>
      <Typography variant="h1" mb={4}>
        Rapporter
      </Typography>
      <Grid container direction="row" spacing={2}>
        {hasRole(appContext.appUser, allRoles.ReadVehicleReports) && (
          <DetailCard
            resultStatus={2}
            icon={faCarBus}
            title="Fordonsrapporter"
            route={allRoutes.ReportsVehiclereports()}
          />
        )}
        {hasRole(appContext.appUser, allRoles.ReadUserReports) && (
          <DetailCard
            resultStatus={2}
            icon={faUser}
            title="Användarrapporter"
            route={allRoutes.ReportsUserreports()}
          />
        )}
      </Grid>
    </Container>
  )
}

export default Reports
