import { faArrowRight, faCheck, faCircle, faExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ExamFormContext } from "./ExamFormStateProvider"
import { useStyles } from "./useStyles"

const StepIcon = ({ muiProps, showError, showCompleted, showNext }) => {
  const classes = useStyles()
  const { active } = muiProps
  return active ? (
    <FontAwesomeIcon className={classes.stepIconActive} icon={faArrowRight} size="xs" />
  ) : showCompleted ? (
    <FontAwesomeIcon className={classes.stepIconCompleted} icon={faCheck} size="xs" />
  ) : showError ? (
    <FontAwesomeIcon className={classes.stepIconWarning} icon={faExclamation} size="xs" />
  ) : showNext ? (
    <FontAwesomeIcon className={classes.stepIconComing} icon={faCircle} size="xs" />
  ) : (
    <FontAwesomeIcon className={classes.stepIconComing} icon={faCircle} size="xs" />
  )
}

export const StepperEl = (props) => {
  const { t } = useTranslation()
  const { areas, activeStep, setActiveStep, handleSubmitForm, examQuestionCount, currentTranslatedDetails } =
    props
  const answerContext = useContext(ExamFormContext)
  const [answers, setAnswers] = useState([])

  useEffect(() => {
    setAnswers(answerContext.values.current)
  }, [answerContext.values.current])

  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  const currentAreas = currentTranslatedDetails?.examinationDefinition?.areas

  const classes = useStyles()
  return (
    <Box>
      <Typography color="textPrimary" marginBottom={3}>
        {t("learning.exams.titles.chapter")}
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper} orientation="vertical" connector={false}>
        {areas.map((data, index) =>
          index <= activeStep ? (
            <Step key={index} sx={{ marginBottom: 3 }}>
              <StepLabel
                StepIconComponent={(muiProps) =>
                  StepIcon({
                    muiProps: muiProps,
                    showCompleted:
                      answers.filter(
                        (answers) =>
                          answers?.answer?.length > 0 &&
                          data?.questions.find((x) => x.id === answers.questionId)
                      )?.length === data.questions.length,

                    showError:
                      answers.filter(
                        (answers) =>
                          answers?.answer?.length > 0 &&
                          data?.questions.find((x) => x.id !== answers.questionId)
                      )?.length !== data.questions.length || index < activeStep,
                    showNext: answers.filter(
                      (answers) => answers?.answer?.length === 0 && data?.questions.length === 0
                    )
                  })
                }
                onClick={handleStep(index)}
                className={classes.stepLabel}>
                <Typography className={classes.stepTitle}>
                  {currentAreas && currentAreas[index] ? currentAreas[index].title : data.title}
                </Typography>
                {data.questions.length > 0 && (
                  <Typography className={classes.stepText}>
                    {` ${
                      answers?.filter(
                        (answers) =>
                          answers?.answer?.length > 0 &&
                          data?.questions.find((x) => x.id === answers.questionId)
                      )?.length
                    } / ${data.questions.length} ${t("learning.exams.stepper.questions_left")}`}
                  </Typography>
                )}
              </StepLabel>
            </Step>
          ) : (
            <Step key={index} sx={{ marginBottom: 3 }}>
              <StepLabel
                StepIconComponent={(muiProps) =>
                  StepIcon({
                    muiProps: muiProps,
                    showCompleted:
                      answers.filter(
                        (answers) =>
                          answers?.answer?.length > 0 &&
                          data?.questions.find((x) => x.id === answers.questionId)
                      )?.length === data.questions.length
                  })
                }
                onClick={handleStep(index)}
                className={classes.stepLabel}>
                <Typography className={classes.stepTitle}>
                  {currentAreas && currentAreas[index] ? currentAreas[index].title : data.title}
                </Typography>

                {data.questions.length > 0 && (
                  <Typography className={classes.stepText}>
                    {
                      answers?.filter(
                        (answers) =>
                          answers?.answer?.length > 0 &&
                          data?.questions.find((x) => x.id === answers.questionId)
                      )?.length
                    }
                    {`/ ${data.questions.length} ${t("learning.exams.stepper.questions_left")}`}
                  </Typography>
                )}
              </StepLabel>
            </Step>
          )
        )}
      </Stepper>
    </Box>
  )
}
