import React, { useContext, useEffect } from "react"
import { Box, Grid } from "@mui/material"
import { AddTemplateFormProps } from "../../interfaces/reports.interfaces"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { useApi } from "auth/useApi3"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { apiEndpoints } from "generated/apiEndpoints"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { customFieldsByCategory } from "../reports.helpers"
import { ReportTemplateContext } from "../ReportTemplateContextProvider"
import TemplateForm from "../TemplateForm/TemplateForm"
import { AddTemplateFormikValues, CheckedFilter } from "./AddTemplateForm.interfaces"

const skeletonRows = [1, 2, 3, 4]

const AddTemplateForm = ({ reportType }: AddTemplateFormProps) => {
  const loadingContext = useContext(GlobalLoaderContext)
  const { selectedFields, setFieldsToChoose, fieldsToChoose, contextTemplateAuths, setContextTemplateAuths } =
    useContext(ReportTemplateContext)

  const [emptyTemplateResult, emptyTemplateApi] = useApi() as [IApiResult, IApiFunc] // for getting the "base template" for the form (for edit this is the edit object)
  const [createTemplateResult, createTemplateFuncs] = useApi() as [IApiResult, IApiFunc] // for saving a new template based on the form

  useEffect(() => {
    emptyTemplateApi.get(`${apiEndpoints.reporting.getemptyreporttemplatetemplate}?reportType=${reportType}`)
    return () => {
      emptyTemplateApi.leave()
    }
  }, [emptyTemplateApi])

  useEffect(() => {
    if (emptyTemplateResult.status !== 2) return
    const reportTemplateFields = customFieldsByCategory(emptyTemplateResult)
    setFieldsToChoose(reportTemplateFields)
  }, [emptyTemplateResult])

  useEffect(() => {
    loadingContext.setLoading(() => emptyTemplateResult.status === 1)
  }, [])

  const createTemplate = (values: AddTemplateFormikValues) => {
    const sortedSelectedFields: CheckedFilter[] = []
    selectedFields.forEach((selectedField: any, index: number) => {
      selectedField.order = index
      sortedSelectedFields.push(selectedField)
    })

    const templateData = {
      reportType: reportType,
      name: values.name,
      description: values.description,
      fields: sortedSelectedFields,
      userReportTemplateAuths: contextTemplateAuths
    }

    createTemplateFuncs.post(apiEndpoints.reporting.addreporttemplate, templateData)
  }

  return (
    <>
      <Box mb={4}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center"></Grid>
      </Box>
      {emptyTemplateResult.status === 2 && fieldsToChoose.fieldGroups.length > 0 ? (
        <TemplateForm
          editMode={false}
          onSubmit={createTemplate}
          addFormTemplate={fieldsToChoose}
          submitFeedbackResult={createTemplateResult}
          reportType={reportType}
        />
      ) : (
        <Grid container direction="row" spacing={5}>
          <Grid item xs={12} md={5}>
            <Grid container direction="column" spacing={1}>
              {skeletonRows.map((index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <SkeletonCard />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <SkeletonCard padding="" />
              </Grid>
              <Grid item xs={12}>
                <SkeletonCard padding="13px 15px" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default AddTemplateForm