import { faUser } from "@fortawesome/pro-solid-svg-icons"
import { Box, ListItemText } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { IFormFieldProps } from "components/Form/FormField/formfield.interface"
import { getValidation } from "components/Form/FormTemplate/validation"
import { Sidebar } from "components/sidedrawer/Sidebar"
import globalUseStyles from "css/theme/globalUseStyles"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import { removeBrackets, removeDataField } from "helpers/CustomField/file"
import moment from "moment/moment"
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import { allRoles } from "generated/roles"
import { FieldsByHeader, ReportDataGrid, ReportResultsProps, ReportTypeEnum } from "../../interfaces/reports.interfaces"
import { convertBeStructureToDataGridStructure } from "../reports.helpers"
import { LoadingTable } from "./LoadingTable"
import { NoReportSelectedTable } from "./NoReportSelectedTable"
import { UserReportSidebarProps } from "./ReportResult.interfaces"

const DisplayData = ({ label, data }: { label: string, data: string }) => {
  return (
    <Box mb={1.5}>
      <ListItemText primary={label} secondary={data} />
    </Box>
  )
}

const normalizeHeader = (header: string) => {
  return header.toLowerCase().replace(/[^a-zåäö0-9]/g, '');
}

const getFieldsByHeader = (data: FieldsByHeader, id: number | undefined) => {
  const result: { [key: string]: string }[] = []
  const { columns, rows } = data;

  rows.forEach(row => {
    if (row?.id !== id) return
    columns.forEach(column => {
      const normalizedHeader: string = normalizeHeader(column.Header)
      const columnKey: string = row?.items[normalizedHeader?.toLowerCase()] || " - "
      const basicFieldExists = columnKey !== undefined && column.tooltip !== "CF" && column?.Header !== ""
      if (!basicFieldExists) return
      result.push({ [column?.Header]: columnKey })
    })
  })

  return result
}

const UserReportSidebar = ({
  showSidebar,
  setShowSidebar,
  userCustomFieldValuesResult,
  currentReportData,
  clickedUserId,
  handleSubmit,
  userCustomFieldUpsertResult
}: UserReportSidebarProps) => {
  const [userResult, userApi] = useApi() as [IApiResult, IApiFunc]
  const [initialFormikValues, setInitialFormikValues] = useState<{[x: number | string]: string | number }>({})
  const [validationSchema, setValidationSchema] = useState<any>()
  const [fields, setFields] = useState<{[x: number | string]: IFormFieldProps & { userCustomFieldValueFieldId: number}}>({})
  const [basicFields, setBasicFields] = useState<any[]>([])

  useEffect(() => {
    if (clickedUserId === undefined || clickedUserId === 0 || !showSidebar) return
    const url = apiEndpoints.customeradmin.getuserbyid.replace("{id}", clickedUserId.toString())
    userApi.get(url)
  }, [clickedUserId])

  useEffect(() => {
    setInitialFormikValues({})
    if (userCustomFieldValuesResult.status !== 2) return
    const validationShape: { [p: number | string]: Yup.Schema } = {}
    const initFormikValues: { [p: number | string]: string } = {}
    const initFields: { [p: number | string]: IFormFieldProps & { userCustomFieldValueFieldId: number} } = {}
    const fieldsByHeader = getFieldsByHeader(currentReportData, clickedUserId)
    setBasicFields(fieldsByHeader)

    userCustomFieldValuesResult.data.forEach((customField: any, parentIndex: number) => {
      customField.valueFields.forEach((valueField: any, index: number) => {
        const isCustomField = currentReportData.columns.find((column: any) => {
          const customValueField = column.Header.split(" - ").slice(1).join(" - ") === valueField.name
          return customValueField && column.tooltip === "CF"
        })
        if (!isCustomField) return
        const key = valueField.userCustomFieldValueId > 0 ? valueField.userCustomFieldValueId : `userCustomFieldValueId_${parentIndex}_${index}`
        const editMode = true
        const isFile = valueField.component === "Upload" && valueField.value !== null && valueField.value !== ""
        const field = {
          component: valueField.component,
          dataType: valueField.dataType,
          formFieldKey: "",
          id: key,
          label: "",
          labelDefault: valueField.name,
          optional: !valueField.isRequired,
          labelKey: key.toString(),
          value: (isFile && !editMode) ? `[${valueField.value}]` : valueField.value,
          key: key.toString(),
          userCustomFieldValueFieldId: valueField.userCustomFieldValueFieldId
        } as IFormFieldProps & { userCustomFieldValueFieldId: number }
        validationShape[key] = getValidation(valueField.dataType, !valueField.isRequired, valueField.name)
        initFormikValues[key] = valueField.value === null ? "" : valueField.value
        initFields[key] = field
      })
    })

    setValidationSchema(Yup.object().shape(validationShape))
    setInitialFormikValues(initFormikValues)
    setFields(initFields)
  }, [userCustomFieldValuesResult])

  return (
    <Sidebar
      showSidebar={showSidebar}
      setShowSidebar={setShowSidebar}
      initialFormikValues={initialFormikValues}
      setInitialFormikValues={setInitialFormikValues}
      handleSubmit={(formikValue: any) => {
        handleSubmit(formikValue, fields)
      }}
      formData={{
        formId: "UserCustomFieldReportForm",
        icon: faUser,
        titleNew: "Skapa eget fält",
        subTitleNew: "Skapa eget fält",
        titleEdit: userResult.data?.name ? `Redigera ${userResult.data?.name}` : "Redigera eget fält",
        subTitleEdit: "Redigera egna fält",
        submitText: "Spara",
        submitEditText: "Spara",
      }}
      validationSchema={validationSchema}
      fields={fields}
      formResult={userCustomFieldUpsertResult}
    >
      <Box my={2}>
        {basicFields.map((field: {[key: string]: string}, index) => {
          return (
            <Box key={index}>
              <DisplayData label={Object.keys(field)[0]} data={Object.values(field)[0]} />
            </Box>
          )
        })}
      </Box>
    </Sidebar>
  )
}

const ReportResult = ({ parameters, reportType }: ReportResultsProps) => {
  const history = useHistory()
  const appContext = useContext(AppContext)
  const [reportDataResult, reportDataApi] = useApi() as [IApiResult, IApiFunc]
  const [showUserCustomFieldSidebar, setShowUserCustomFieldSidebar] = useState(false)
  const [userCustomFieldValuesResult, userCustomFieldValuesApi] = useApi() as [IApiResult, IApiFunc]
  const [clickedUserId, setClickedUserId] = useState<number>()
  const [userCustomFieldUpsertResult, userCustomFieldValuesUpsertApi] = useApi() as [IApiResult, IApiFunc]
  const globalClasses = globalUseStyles()
  const [currentReportData, setCurrentReportData] = useState<ReportDataGrid | null>(null)

  useEffect(() => {
    if (reportDataResult.status === 2) {
      const reportData = convertBeStructureToDataGridStructure(reportDataResult.data)
      setCurrentReportData(reportData)
    }
  }, [reportDataResult])

  useEffect(() => {
    if (userCustomFieldUpsertResult.status !== 2) return
    fetchReportData()
  }, [userCustomFieldUpsertResult])

  useEffect(() => {
    fetchReportData()
  }, [reportDataApi, parameters])

  const fetchReportData = () => {
    if (parameters.selectedTemplate && parameters.selectedTemplate.requireDate) {
      let searchParams = new URLSearchParams(undefined)

      if (parameters.filter?.name !== null && parameters.filter?.name !== undefined) {
        searchParams.append("name", parameters.filter?.name)
      }

      if (parameters.filter?.subcontract !== null && parameters.filter?.subcontract !== undefined) {
        searchParams.append("subcontract", parameters.filter?.subcontract)
      }

      const values = {
        templateId: parameters.selectedTemplate.id,
        dateFrom: moment(parameters.dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(parameters.dateTo).format("YYYY-MM-DD"),
        queryParam: searchParams.toString()
      }

      parameters.dateFrom &&
      parameters.dateTo &&
      reportDataApi.post(apiEndpoints.reporting.getreportresultbytemplateidandinterval, values)
    } else if (parameters.selectedTemplate) {
      const templateUrl = apiEndpoints.reporting.getreportresultbytemplateid.replace(
        "{templateId}",
        parameters.selectedTemplate.id.toString()
      )
      reportDataApi.get(templateUrl)
    } else {
      setCurrentReportData(null)
    }
  }

  const fetchUserCustomFieldValues = (userId: number) => {
    const url = apiEndpoints.usercustomfieldsadmin.getusercustomfieldformvalues.replace("{userId}", userId.toString())
    userCustomFieldValuesApi.get(url)
    setClickedUserId(userId)
  }

  const handleFormSubmit = (formikValues: any, fields: any) => {
    const formData = Object.entries(formikValues).map(([key, value]) => {
      const original: any = fields[key]
      const isFile = original.component === "Upload"
      let formikValue
      if (value === null || value === "") {
        formikValue = null
      } else {
        const stringValue = value as string
        formikValue = typeof value === "number" ? value.toString() : value
        if (isFile) {
          formikValue = removeBrackets(stringValue)
          formikValue = JSON.stringify(removeDataField(JSON.parse(formikValue)))
        }
      }
      const id: number = isNaN(parseInt(key)) ? 0 : parseInt(key)
      return {
        id: id,
        userCustomFieldValueFieldId: original.userCustomFieldValueFieldId,
        userId: clickedUserId,
        value: formikValue
      }
    })

    const url = apiEndpoints.usercustomfieldsadmin.upsertusercustomfieldvalues
    userCustomFieldValuesUpsertApi.put(url, formData)
    setShowUserCustomFieldSidebar(false)
  }

  return reportDataResult.status === 1 ? (
    <LoadingTable />
  ) : reportDataResult.status === 2 && currentReportData ? (
    <Box className={globalClasses.tableContainer}>
      <UserReportSidebar
        showSidebar={showUserCustomFieldSidebar}
        setShowSidebar={setShowUserCustomFieldSidebar}
        userCustomFieldValuesResult={userCustomFieldValuesResult}
        currentReportData={currentReportData}
        clickedUserId={clickedUserId}
        handleSubmit={handleFormSubmit}
        userCustomFieldUpsertResult={userCustomFieldUpsertResult}
      />
      <ClpTable
        rows={reportDataResult.data.rows}
        subColumns={currentReportData.subColumns}
        columns={currentReportData.columns}
        footer={reportDataResult.data.totalSum}
        cellProps={(row: any) => ({
          onClick: () => {
            const isAdmin = hasRole(appContext.appUser, allRoles.CustomerAdmin)
            if (reportType === ReportTypeEnum.User && isAdmin) {
              fetchUserCustomFieldValues(row.original.id)
              setShowUserCustomFieldSidebar(true)
            } else {
              history.push(allRoutes.VehicleregistryVehiclesDetails(row.original.id))
            }
          }
        })}
      />
    </Box>
  ) : (
    <NoReportSelectedTable />
  )
}

export default ReportResult