import { Box, Divider, Grid, Typography } from "@mui/material"
import React from "react"
import Moment from "react-moment"
import { TextCell } from "./components/TextCell"
import IoTInfo from "shared/vehicle/IotInfo"
import { Service } from "./TechnicalData"
import moment from "moment"

const BasicInfo = ({ data, vehicleId }) => {
  return (
    <>
      <Grid container direction="row" spacing={5}>
        <Grid item md={8}>
          <Box pb={3}>
            <BasicInfoData data={data} />
          </Box>
          <Divider light />
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Kontrollbesiktning
            </Typography>
            <Service data={data} />
          </Box>
          <Divider light />
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Status
            </Typography>
            <Status data={data} />
          </Box>
          <Divider light />
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Registrering
            </Typography>
            <Registration data={data} />
          </Box>
          <Divider light />
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Brukare
            </Typography>
            <Users data={data} />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <IoTInfo vehicleId={vehicleId} />
          <Box bgcolor="background.light" padding={3} borderRadius="10px">
            <Grid container direction="column">
              <Grid item>
                <TextCell label="Fordonsår" text={data.fordonsAr} />
              </Grid>
              <Grid item>
                <TextCell label="Årsmodell" text={data.arsmodell} />
              </Grid>
              <Grid item>
                <TextCell
                  label="Sittande passagerare"
                  text={data.antalSittande && data.antalSittande + " st"}
                />
              </Grid>
              <Grid item>
                <TextCell
                  label="Totalt antal passagerare"
                  text={data.antalPassagerare && data.antalPassagerare + " st"}
                />
              </Grid>
            </Grid>
          </Box>
          <Box my={3} bgcolor="background.light" padding={3} borderRadius="10px">
            <Grid container direction="column">
              <Grid item>
                <Typography color="primary" variant="h3" gutterBottom>
                  Senaste besiktning
                </Typography>
              </Grid>
              <Grid item>
                <TextCell
                  label="Besiktad"
                  text={
                    data.besiktningsdatum && (
                      <Typography variant="bodyMedium" fontWeight={600}>
                        {moment.utc(data.besiktningsdatum).local().format("YYYY-MM-DD")}
                      </Typography>
                    )
                  }
                />
              </Grid>
              <Grid item>
                <TextCell label="Besiktningsstation" text={data.besiktningsstation} />
              </Grid>
              <Grid item>
                <TextCell label="Besiktningsprogramkod" text={data.besiktningsprogramkod} />
              </Grid>
              <Grid item>
                <TextCell label="Mätarställning" text={data.matarstallning} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

const BasicInfoData = ({ data }) => {
  return (
    <>
      <Grid container direction="row" spacing={5} paddingTop={3}>
        <Grid item>
          <TextCell label="Regnr" text={data.regNumber} />
        </Grid>
        <Grid item>
          <TextCell label="Internnr" text={data.internnumber} />
        </Grid>
        <Grid item>
          <TextCell label="Chassinummer" text={data.chassinummer} />
        </Grid>
        <Grid item>
          <TextCell label="Färg" text={data.farg} />
        </Grid>
        <Grid item>
          <TextCell label="Fordonstyp" text={data.fordonstyp} />
        </Grid>
        <Grid item>
          <TextCell label="Miljöklass" text={data.miljoklass} />
        </Grid>
        {data.fordonstyp === "Buss" && (
          <Grid item>
            <TextCell label="Bussklass" text={data.bussklass} />
          </Grid>
        )}

        <Grid item>
          <TextCell
            label="Kaross"
            text={
              <>
                <Typography variant="bodyMedium" fontWeight={600}>
                  {data.karosserikoDriv1}
                </Typography>
                <Typography variant="bodyMedium" fontWeight={600}>
                  {data.karosserikoDriv2}
                </Typography>
              </>
            }
          />
        </Grid>
        <Grid item>
          <TextCell label="Fabrikat/Modell" text={data.fabrikat} />
        </Grid>
        <Grid item>
          <TextCell label="Fordonsbenämning" text={data.fordonsbenamning} />
        </Grid>
        <Grid item>
          <TextCell label="Handelsbeteckning" text={data.handelsbeteckning} />
        </Grid>
        <Grid item>
          <TextCell label="Försäkringsform" text={data.forsakringsform} />
        </Grid>
        <Grid item>
          <TextCell label="Ursprungskod" text={data.ursprungskod} />
        </Grid>
      </Grid>
    </>
  )
}

const Status = ({ data }) => {
  return (
    <Grid container direction="row" spacing={5}>
      <Grid item>
        <TextCell label="Fordonsstatus" text={data.fordonsstatusKod} />
      </Grid>
      <Grid item>
        <TextCell
          label="Datum för status"
          text={
            data.fordonsstatusDatum && (
              <Moment date={data.fordonsstatusDatum} format="YYYY-MM-DD" globallocale="sv" />
            )
          }
        />
      </Grid>
      <Grid item>
        <TextCell label="Körförbud" text={data.korforbud} />
      </Grid>

      <Grid item>
        <TextCell
          label="Yrkesmässig trafik"
          text={
            <>
              <Typography variant="bodyMedium" fontWeight={600}>
                {data.anvandningssatt1}
              </Typography>
              <Typography variant="bodyMedium" fontWeight={600}>
                {data.anvandningssatt2}
              </Typography>
              <Typography variant="bodyMedium" fontWeight={600}>
                {data.anvandningssatt3}
              </Typography>
              <Typography variant="bodyMedium" fontWeight={600}>
                {data.anvandningssatt4}
              </Typography>
              <Typography variant="bodyMedium" fontWeight={600}>
                {data.anvandningssatt5}
              </Typography>
            </>
          }
        />
      </Grid>
    </Grid>
  )
}

const Registration = ({ data }) => {
  return (
    <Grid container direction="row" spacing={5}>
      <Grid item>
        <TextCell
          label="Regbesiktning (1:a)"
          text={
            data.registreringsdatum && (
              <Moment date={data.registreringsdatum} format="YYYY-MM-DD" globallocale="sv" />
            )
          }
        />
      </Grid>
      <Grid item>
        <TextCell label="Registreringstyp" text={data.registreringstyp} />
      </Grid>
      <Grid item>
        <TextCell label="Regbesiktning (ombes)" text={data.korforbud} />
      </Grid>
      <Grid item>
        <TextCell
          label="I trafik första gång i SE"
          text={
            data.registreringsdatum && (
              <Moment date={data.registreringsdatum} format="YYYY-MM-DD" globallocale="sv" />
            )
          }
        />
      </Grid>

      <Grid item>
        <TextCell label="Senaste EU-regbevis del 2" text={data.yrkesmassigTrafik} />
      </Grid>
    </Grid>
  )
}

const Users = ({ data }) => {
  return (
    <Grid container direction="row" spacing={5}>
      <Grid item>
        <TextCell
          label="Org.nr"
          text={data.agare && data.agare.substring(0, 6) + "-" + data.agare.substring(6, data.agare.length)}
        />
      </Grid>
      <Grid item>
        <TextCell
          label="Förvärvsdatum"
          text={
            data.forvarvsdatum && <Moment date={data.forvarvsdatum} format="YYYY-MM-DD" globallocale="sv" />
          }
        />
      </Grid>
      <Grid item>
        <TextCell
          label="Brukare fr.o.m"
          text={
            data.forvarvsdatum && <Moment date={data.forvarvsdatum} format="YYYY-MM-DD" globallocale="sv" />
          }
        />
      </Grid>
      <Grid item>
        <TextCell label="Adress" text={data.adress} />
      </Grid>
      <Grid item>
        <TextCell label="Postnummer" text={data.postnummer} />
      </Grid>
      <Grid item>
        <TextCell label="Postort" text={data.postort} />
      </Grid>
    </Grid>
  )
}

export default BasicInfo
