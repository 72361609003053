import React from "react"
import { Box, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import { SkeletonCardAvatar } from "../skeleton/SkeletonCardAvatar"
import { Link } from "react-router-dom"
import { CircleIcon } from "../icons/CircleIcon"
import { DetailCardProps } from "./DetailCard.interfaces"
import { PaperBox } from "../PaperBox"
import { StyledTypographyGrey } from "../Text/Typography.styled"

export const DetailCard = ({ resultStatus, icon, title, subTitle, quantityType, route }: DetailCardProps) => {
  return (
    <Grid item xs={12} md={10} lg={5} xl={3}>
      {resultStatus === 1 && <SkeletonCardAvatar />}
      {resultStatus === 2 && (
        <Box component={route ? Link : Box} sx={{ textDecoration: "none" }} to={route}>
          <PaperBox paddingX={3} paddingY={3} marginY={0}>
            <ListItem disableGutters sx={{
              padding: "0"
            }}>
              {icon && (
                <ListItemAvatar>
                  <CircleIcon icon={icon} color="primary" size="lg" sx={{ marginRight: "15px" }} />
                </ListItemAvatar>
              )}
              <ListItemText>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    {title && (
                      <StyledTypographyGrey color="secondary" variant="bodyMedium">
                        {title}
                      </StyledTypographyGrey>
                    )}
                    {subTitle && (
                      <Typography variant="h1" sx={{
                        color: "#000000",
                        fontWeight: "500"
                      }}>
                        {subTitle} {quantityType || ""}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>
          </PaperBox>
        </Box>
      )}
    </Grid>
  )
}