import styled from "@emotion/styled";
import { Alert } from "@mui/material";

export const StyledAlert = styled(Alert)(({ theme }: any) => ({
    backgroundColor: theme.palette.background.paper,
    "& .MuiAlert-message": {
        padding: "0px"
    },
    "& .MuiAlert-icon": {
        padding: "0px",
        marginRight: "16px"
    },
    "&.MuiAlert-root": {
        padding: "16px",
        boxShadow: "0px 0px 65px 0px rgb(0 0 0 / 6%)",
        borderRadius: "8px"
    },
    "& .MuiAlert-action": {
        paddingTop: "0px"
    }
}))