import {Box, Button, Divider, Grid, List, ListItemText, Typography} from "@mui/material"
import ListItem from "@mui/material/ListItem"
import { Formik } from "formik"
import { t } from "i18next"
import React from "react"
import DefaultButton from "../Button"
import FormComponents from "../Form/FormComponents/FormComponents"
import { CircleIcon } from "../icons/CircleIcon"
import CloseSidebarButton from "../navigation/CloseSidebarButton"
import { RouterPrompt } from "../RouterPrompt"
import { SidebarProps } from "./Sidebar.interfaces"
import { StyledSidebarDrawer } from "./Sidebar.styled"

export const Sidebar = ({
  showSidebar,
  setShowSidebar,
  initialFormikValues,
  setInitialFormikValues,
  formResult,
  formData,
  handleSubmit,
  validationSchema,
  fields,
  children
  }: SidebarProps) => (
    <React.Fragment key="right">
      <StyledSidebarDrawer
        variant="persistent"
        anchor="right"
        open={showSidebar}
      >
        <Box mx={2} sx={{ height: "100%" }}>
          <Formik
            initialValues={initialFormikValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit({ ...values })
              setInitialFormikValues(initialFormikValues)
              resetForm()
            }}
            enableReinitialize={true}>
            {(formikProps) => {
              return (
                <form style={{ height: "100%" }} id={formData.formId} onSubmit={formikProps.handleSubmit} noValidate>
                  <RouterPrompt />
                  <List sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <Box style={{ position: "absolute", left: "-20px" }}>
                      <CloseSidebarButton
                        toggleMenu={() => {
                          setShowSidebar(!showSidebar)
                        }}
                      />
                    </Box>
                    <ListItem sx={{display: "flex", direction: "column", alignItems: "flex-end", flexBasis: "120px", minHeight: "70px !important", flexShrink: 1}}>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <CircleIcon
                            icon={formData.icon}
                            color="primary"
                            size="lg"
                            sx={{ marginRight: "15px" }}
                          />
                          <Box justifyContent="center">
                            <ListItemText
                              primary={
                                <Typography color="textPrimary" variant="h2">
                                  {formikProps.values.id < 1 ? formData.titleNew : formData.titleEdit}
                                </Typography>
                              }
                              secondary={
                                <Typography color="textSecondary" variant="bodySmall">
                                  {formikProps.values.id < 1 ? formData.subTitleNew : formData.subTitleEdit}
                                </Typography>
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </ListItem>
                    <Divider />
                    <Box sx={{ flexGrow: 1, overflow: "auto"}}>
                      {children}
                      <Grid display="flex" gap={3} direction="column">
                        {fields && Object.keys(fields).map((key) => {
                          const field = fields[key]
                          return <FormComponents
                            field={field}
                            initialValues={initialFormikValues}
                            handleNext={() => {}}
                            formikProps={formikProps}
                          />
                        })}
                      </Grid>
                    </Box>
                    <Box sx={{ marginTop: "Auto", width: "100%", flexShrink: 0 }}>
                      <ListItem sx={{ flexDirection: "column", gap: "14px" }}>
                        <DefaultButton sx={{ width: "100%" }} result={formResult} type="submit">
                          {formikProps.values.id > 0 ? formData.submitEditText : formData.submitText}
                        </DefaultButton>
                        <Button
                          sx={{ width: "100%", margin: "0 10px" }}
                          variant={"outlined"}
                          onClick={() => {
                            setShowSidebar(false)
                          }}>
                          {t("global.buttons.cancel")}
                        </Button>
                    </ListItem>
                    </Box>
                  </List>
                </form>
              )
            }}
          </Formik>
        </Box>
      </StyledSidebarDrawer>
    </React.Fragment>
  )
