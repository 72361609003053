import React from "react"
import { Accordion, styled, Typography } from "@mui/material"

export const StyledAccordion = styled(Accordion)<{ customCss?: React.CSSProperties }>(({ customCss }) => {
  return { ...customCss }
})

export const StyledStatNameTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.grey[600]
}))